import React from "react";
import { Layout as AntLayout } from "antd";
import { Header } from "../../components/Header";
import { Outlet } from "react-router-dom";

const { Content, Footer } = AntLayout;

const Layout: React.FC = () => {
  return (
    <AntLayout
      className="layout"
      style={{
        minHeight: "100vh",
      }}
    >
      <Header />
      <Content
        style={{
          marginTop: "48px",
          width: "100%",
        }}
      >
        <div
          style={{
            width: "calc(100% - 48px)",
            margin: "0 auto",
          }}
        >
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: "center", marginTop: "4px" }}>
        Arcke: Ordem de Serviço e PMOC ©2023 |{" "}
        <strong>
          <a href="https://arcke.com.br/" target={"_blank"} rel="noreferrer">
            Zukt Labs
          </a>
        </strong>{" "}
        | {process.env.REACT_APP_VERSION}
      </Footer>
    </AntLayout>
  );
};

export { Layout };
