import { FC } from "react";
import { Form, FormInstance, Input } from "antd";
import { formatters } from "../../../../utils";


interface PJProps {
  form: FormInstance;
}

const PJ: FC<PJProps> = ({ form }) => {
  return (
    <>
      <Form.Item
        label="Nome fantasia:"
        name="fantasy_name"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o nome fantasia!",
          },
        ]}
      >
        <Input placeholder="Insira o nome fantasia" />
      </Form.Item>
      <Form.Item
        label="Razão social:"
        name="corporate_name"
        rules={[
          {
            required: true,
            message: "Por favor, selecione a razão social!",
          },
        ]}
      >
        <Input placeholder="Insira a razão social" />
      </Form.Item>
      <Form.Item
        label="CNPJ:"
        name="document"
        normalize={formatters.identifier}
        rules={[
          {
            required: true,
            message: "Por favor, insira o CNPJ!",
          },
          {
            min: 13,
            message: "Por favor, insira um CNPJ valido!",
          },
          {
            max: 18,
            message: "Por favor, insira um CNPJ valido!",
          },
        ]}
      >
        <Input placeholder="Insira o CNPJ" />
      </Form.Item>
      <Form.Item label="Inscrição estadual:" name="state_registration"
        rules={[
          {
            required: true,
            message: "Por favor, insira a inscrição estadual!",
          },
        ]}
      >
        <Input placeholder="Insira a inscrição estadual" />
      </Form.Item>
      <Form.Item label="Inscrição municipal:" name="municipal_registration"
        rules={[
          {
            required: true,
            message: "Por favor, insira a inscrição municipal!",
          },
        ]}
      >
        <Input placeholder="Insira a inscrição municipal" />
      </Form.Item>
    </>
  );
};

export { PJ };
