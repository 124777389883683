import { FC, useEffect, useState } from "react";
import "dayjs/locale/pt-br";

import {
  Button,
  Form,
  Input,
  message as Message,
  Modal,
  Select,
  Typography,
} from "antd";

import { api } from "../../../services/api";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { formatters } from "../../../utils/formatters";
import { ISupplier, ModalProps } from "../../../types";
import { PJ } from "./form/PJ";
import { PF } from "./form/PF";
import { parsers } from "../../../utils";

const { Option } = Select;

const CreateSupplierModal: FC<ModalProps<ISupplier>> = ({
  onClose,
  data: supplier,
  isModalOpen,
}) => {
  const [message] = Message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (supplier) {
        form.setFieldsValue(supplier);

        await api
          .get<ISupplier>(`/suppliers/${supplier.id}`)
          .then(({ data }) => {
            const phones = data.phones.map((phone) => {
              return { phone: phone.phone };
            });

            const emails = data.emails.map((email) => {
              return { email: email.email };
            });

            form.setFieldValue("phones", phones);
            form.setFieldValue("emails", emails);
          });
      }
    };
    if (isModalOpen) {
      getData().finally(() => setLoading(false));
    }
  }, [form, supplier, isModalOpen]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          document: parsers.identifier(values.document),
          phones: values.phones?.map((phone: { phone: string }) => {
            return { phone: parsers.phone(phone.phone) };
          }),
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Fornecedor ${
            data.config.method === "put" ? "atualizado" : "cadastrado"
          } com sucesso!`
        );
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    if (supplier) {
      return await api.put(`/suppliers/${supplier.id}`, values);
    }
    return await api.post(`/suppliers`, values);
  };

  return (
    <Modal
      title={`${supplier ? "Editar" : "Cadastrar"} fornecedor`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          disabled={loading}
          danger
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onSubmit}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="supplier_form_modal">
        <Form.Item
          name={"type"}
          label={"Tipo"}
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Select placeholder="Selecione o tipo" allowClear>
            <Option value="PJ">Pessoa Jurídica</Option>
            <Option value="PF">Pessoa Fisíca</Option>
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            const clientType = form.getFieldValue("type");
            if (clientType === "PJ") {
              return <PJ form={form} />;
            }
            return <PF form={form} />;
          }}
        </Form.Item>
        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 8 }}
        >
          <Form.Item label="Telefone" style={{ width: "100%" }}>
            <Form.List
              name="phones"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("Insira ao menos um telefone")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: 4,
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "phone"]}
                        rules={[
                          { required: true, message: "Insira o Telefone" },
                        ]}
                        style={{
                          width: "100%",
                        }}
                        normalize={formatters.phone}
                      >
                        <Input placeholder="Insira o telefone" />
                      </Form.Item>
                      <MinusCircleOutlined
                        style={{
                          marginBottom: 24,
                        }}
                        onClick={() => remove(name)}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Adicionar telefone
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
          <Form.Item label="Email" style={{ width: "100%" }}>
            <Form.List
              name="emails"
              rules={[
                {
                  validator: async (_, emails) => {
                    if (!emails || emails.length < 1) {
                      return Promise.reject(
                        new Error("Insira ao menos um email")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: 4,
                      }}
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "email"]}
                        rules={[
                          { required: true, message: "Insira o email" },
                          { type: "email", message: "Insira email valido" },
                        ]}
                        style={{
                          width: "100%",
                        }}
                      >
                        <Input placeholder="Insira o email" />
                      </Form.Item>
                      <MinusCircleOutlined
                        style={{
                          marginBottom: 24,
                        }}
                        onClick={() => remove(name)}
                      />
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Adicionar email
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Form.Item>
        </div>
        <Typography.Text strong>Endereço</Typography.Text>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 8,
            marginTop: 16,
          }}
        >
          <Form.Item
            label="CEP:"
            name="cep"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input placeholder="Insira o nome" />
          </Form.Item>
          <Form.Item
            label="Estado:"
            name="state"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input placeholder="Insira o nome" />
          </Form.Item>
        </div>
        <Form.Item
          label="Cidade:"
          name="city"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input placeholder="Insira o nome" />
        </Form.Item>
        <Form.Item
          label="Bairro:"
          name="district"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input placeholder="Insira o nome" />
        </Form.Item>
        <Form.Item
          label="Rua:"
          name="street"
          rules={[
            {
              required: true,
              message: "Campo obrigatório!",
            },
          ]}
        >
          <Input placeholder="Insira a rua" />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 8,
            marginTop: 16,
          }}
        >
          <Form.Item
            label="Número:"
            name="number"
            rules={[
              {
                required: true,
                message: "Campo obrigatório!",
              },
            ]}
          >
            <Input type="number" placeholder="Insira o nome" />
          </Form.Item>
          <Form.Item label="Complemento:" name="complement">
            <Input placeholder="Insira o nome" />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export { CreateSupplierModal };
