import { FC, useEffect, useState } from "react";

import { App, Button, Form, Input, Modal, Select as ASelect, Spin } from "antd";

import { api } from "../../../services/api";
import { IUser, ModalProps } from "../../../types";
import { Select } from "../../../components/Select";
import { getUsers } from "../list";
import { useParams } from "react-router-dom";

interface UserModalProps extends ModalProps<IUser> {
  clientId?: string;
  assistant?: boolean;
  handleRefreshData: () => void
}

const options = [
  { value: "ADMINISTRATOR", label: "ADMINISTRADOR" },
  { value: "ATTENDANT", label: "ATENTENTE" },
  { value: "TECHNICIAN", label: "TECNICO" },
];

const clientOptions = [
  { value: "CUSTOMER", label: "CLIENTE" },
  { value: "MANAGER", label: "FISCAL" },
];

const UserModal: FC<UserModalProps> = ({
  onClose,
  handleRefreshData,
  assistant,
  isModalOpen,
  clientId,
  data: user,
}) => {
  const { id } = useParams();
  const { message } = App.useApp();

  const [form] = Form.useForm<IUser>();
  const [loading, setLoading] = useState(false);
  const [technical, setTechnical] = useState<IUser[]>([]);

  const handleLoadUsers = async () => {
    await getUsers(1, 1000, {
      profile: "TECHNICIAN",
    }).then(({ data }) => {
      setTechnical(data.data);
    });
  };

  useEffect(() => {
    if (user) {
      form.setFieldsValue({ ...user, email: user.account?.email });
    }
    if (id) {
      handleLoadUsers();
    }
  }, [id, form, user]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then(() => {
        message.success(`Email enviado com sucesso!`);
        handleCloseModal();
        handleRefreshData();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IUser) => {
    if (user) {
      return await api.put<IUser>(`/users/${user.id}`, values, {
        params: {
          id: user.id,
        },
      });
    }
    return await api.post<IUser>(`/users`, { ...values, clientId });
  };

  return (
    <Modal
      title={`${user ? "Editar" : "Adicionar"} usuário`}
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {user ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="user_form_modal"
          initialValues={{
            profile: assistant ? "ASSISTANT" : "",
            userId: id,
          }}
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome do Usuário!",
              },
            ]}
          >
            <Input disabled={!!user} placeholder="Insira o nome" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: "Por favor insira o email do Usuário!",
              },
              {
                type: "email",
                message: "Por favor insira o email valido",
              },
            ]}
          >
            <Input disabled={!!user} placeholder="Insira o email" />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              const type: IUser["profile"] = form.getFieldValue("profile");
              if (type === "ASSISTANT") {
                return (
                  <Form.Item
                    label="Técnico"
                    name="userId"
                    rules={[
                      {
                        required: true,
                        message: "Por favor selecione o perfil de acesso!",
                      },
                    ]}
                  >
                    <Select
                      disabled={!!id}
                      options={technical}
                      handleLoadData={handleLoadUsers}
                      placeholder="Selecione o técnico"
                    />
                  </Form.Item>
                );
              }
            }}
          </Form.Item>
          <Form.Item
            label="Perfil de Acesso"
            name="profile"
            rules={[
              {
                required: true,
                message: "Por favor selecione o perfil de acesso!",
              },
            ]}
          >
            <ASelect
              disabled={assistant}
              optionFilterProp="children"
              showSearch
              allowClear
              options={clientId ? clientOptions : options}
              placeholder="Selecione o perfil de acesso"
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { UserModal };
