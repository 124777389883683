import { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import { Link, useMatches } from "react-router-dom";

type Crumbs = {
  title: string;
  path: string;
};

const Breadcrumbs = () => {
  const matches = useMatches();
  const [crumbs, setCrumbs] = useState<Crumbs[]>([]);

  useEffect(() => {
    let crumbs = matches
      .filter((match: any) => Boolean(match.handle?.crumb))
      .map((match: any) => ({
        title: match.handle?.crumb(match.data),
        path: match.pathname,
      }));
    setCrumbs(crumbs);
  }, [matches]);

  function itemRender(route: any, params: any, items: any[], paths: any) {
    const last = items.indexOf(route) === items.length - 1;
    return last ? (
      <span>{route.title}</span>
    ) : (
      <Link
        to={route.path}
        style={{
          maxWidth: 200,
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {route.title}
      </Link>
    );
  }

  return (
    <Breadcrumb
      style={{ margin: "0", fontSize: 12, maxHeight: 20 }}
      itemRender={itemRender}
      items={crumbs}
    />
  );
};

export { Breadcrumbs };
