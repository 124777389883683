import { FC } from "react";
import { IServiceOrder } from "../../../types";
import { Tag } from "antd";

interface OrderTypeProps {
  type: IServiceOrder["type"];
}

const OrderType: FC<OrderTypeProps> = ({ type }) => {
  switch (type) {
    case "BUDGET":
      return <Tag>ORÇAMENTO</Tag>;

    case "CONTRACT":
      return <Tag>CONTRATO</Tag>;

    case "SINGLE":
      return <Tag>AVULSA</Tag>;

    case "PMOC":
      return <Tag>PMOC</Tag>;

    default:
      return <Tag>--</Tag>;
  }
};

export { OrderType };
