import { FC, useEffect, useState } from "react";
import { Col, DatePicker, DatePickerProps, Form } from "antd";
import dayjs from "dayjs";

import { useSearchParams } from "react-router-dom";

import { IClient, IContract, IEquipment, ILocal, IPmoc } from "../../../types";
import { getAllContracts } from "../../contracts/list";

import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { getClients } from "../../../services/repositories";
import { Select } from "../../../components/Select";
import { getPmocs } from "../../pmocs/list";
import { getPmocLocals } from "../../pmocs/pmoc";
import { getPmocEquipments } from "../../pmocs/pmoc/PmocEquipments";

interface OrderFilterForm {
  id: string;
  clientId: string;
  contractId: string;
  date: DatePickerProps["value"];
  status: string[];
}

interface FilterOrderListProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const FilterOrderList: FC<FilterOrderListProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm<OrderFilterForm>();

  const [, setSearchParams] = useSearchParams();
  const [clients, setClients] = useState<IClient[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [pmocs, setPmocs] = useState<IPmoc[]>([]);
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [equipments, setEquipments] = useState<IEquipment[]>([]);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        ...filter,
        date: filter.date ? dayjs(filter.date) : undefined,
        status: filter.status ? filter.status?.split(",") : undefined,
      });
    }
  }, [form, filter]);

  useEffect(() => {
    if (clients.length === 0) {
      getClients(1, 1000).then(({ data }) => setClients(data));
    }
    if (filter?.clientId) {
      getAllContracts(1, 1000, { clientId: filter.clientId }).then(
        ({ data }) => {
          setContracts(data.data);
        }
      );
    }
  }, [filter, clients.length]);

  const onFinish = (values: OrderFilterForm) => {
    const params = JSON.parse(
      JSON.stringify({
        ...values,
        date: values?.date?.toISOString().slice(0, 10),
      })
    );
    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const handleChangeClient = async () => {
    setContracts([]);
    form.resetFields(["contractId", "localId", "pmocId", "equipmentId"]);
  };

  const loadContracts = async () => {
    await getAllContracts(1, 1000, {
      clientId: form.getFieldValue("clientId"),
    }).then(({ data }) => {
      setContracts(data.data);
    });
  };

  const loadLocals = async () => {
    let contractId = form.getFieldValue("pmocId");
    if (contractId) {
      await getPmocLocals(contractId, 1, 1000).then(({ data }) => {
        setLocals(data.data);
      });
    }
  };

  const loadPmocs = async () => {
    let contractId = form.getFieldValue("contractId");
    if (contractId) {
      await getPmocs(1, 100, { contractId }).then(({ data }) => {
        setPmocs(data.data);
      });
    }
  };

  const loadEquipments = async () => {
    let localId = form.getFieldValue("localId");
    let pmocId = form.getFieldValue("pmocId");
    if (pmocId) {
      await getPmocEquipments(pmocId, 1, 1000, { localId }).then(({ data }) => {
        setEquipments(data.data);
      });
    }
  };

  const fields = [
    <Col span={6} key={1}>
      <Form.Item
        name={"date"}
        label={"Período"}
        rules={[
          {
            required: true,
            message: "Por favor selecione um Período!",
          },
        ]}
      >
        <DatePicker
          format="MMMM/YYYY"
          picker="month"
          style={{ width: "100%" }}
          allowClear
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item
        name={"clientId"}
        label={"Cliente"}
        rules={[
          {
            required: true,
            message: "Selecione ao menos um cliente",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={clients}
          onChange={handleChangeClient}
          placeholder="Selecione um cliente"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item
        label="Contrato"
        name="contractId"
        rules={[
          {
            required: true,
            message: "Selecione ao menos um contrato",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={contracts}
          handleLoadData={loadContracts}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={4}>
      <Form.Item label="PMOC" name="pmocId">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={pmocs}
          handleLoadData={loadPmocs}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={5}>
      <Form.Item label="Local" name="localId">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={locals}
          handleLoadData={loadLocals}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={6}>
      <Form.Item label="Equipamento" name="equipmentId">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={equipments}
          handleLoadData={loadEquipments}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={7}>
      <Form.Item label="Atividades" name="activity">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          defaultValue={"all"}
          showSearch
          allowClear
          options={[
            { id: "all", name: "Todas" },
            { id: "isDone", name: "Realizadas" },
            { id: "notDone", name: "Não Realizadas" },
          ]}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterOrderList };
