import { FC } from "react";
import { Form, FormInstance, Input } from "antd";
import { formatters } from "../../../utils";

interface ClientLegalFormProps {
  form: FormInstance;
}

const ClientLegalForm: FC<ClientLegalFormProps> = ({ form }) => {
  return (
    <>
      <Form.Item
        label="Nome fantasia:"
        name="name"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o nome fantasia!",
          },
        ]}
      >
        <Input placeholder="Insira o nome fantasia" />
      </Form.Item>
      <Form.Item
        label="Razão social:"
        name="corporateName"
        rules={[
          {
            required: true,
            message: "Por favor, selecione a razão social!",
          },
        ]}
      >
        <Input placeholder="Insira a razão social" />
      </Form.Item>
      <Form.Item
        label="CNPJ:"
        name="identifier"
        normalize={formatters.identifier}
        rules={[
          {
            required: true,
            message: "Por favor, insira o CNPJ!",
          },
          {
            min: 13,
            message: "Por favor, insira um CNPJ valido!",
          },
          {
            max: 18,
            message: "Por favor, insira um CNPJ valido!",
          },
        ]}
      >
        <Input placeholder="Insira o CNPJ" />
      </Form.Item>
      <Form.Item label="Inscrição estadual:" name="ie">
        <Input placeholder="Insira a inscrição estadual" />
      </Form.Item>
      <Form.Item label="Inscrição municipal:" name="im">
        <Input placeholder="Insira a inscrição municipal" />
      </Form.Item>
    </>
  );
};

export { ClientLegalForm };
