import { useEffect, useState } from "react";

import { Button, Form, Input, message, Modal, Spin } from "antd";

import { api } from "../../../services/api";
import { IUnit } from "../../../types";

interface UnitModalProps {
  onClose: () => void;
  onRefreshPage?: () => void;
  localId?: number | string;
  isModalOpen: boolean;
  unit?: IUnit;
}

const UnitModal = ({
  onClose,
  onRefreshPage,
  isModalOpen,
  localId,
  unit,
}: UnitModalProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (unit) {
      form.setFieldsValue(unit);
    }
  }, [form, unit]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return values;
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
    if (unit) {
      await api
        .put<IUnit>(`/units/${unit.id}`, values)
        .then(() => {
          message.success("Unidade atualizada com sucesso!");
          handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
          if (onRefreshPage) {
            onRefreshPage();
          }
        });
    } else {
      await api
        .post<IUnit>(`/units/${localId}`, values)
        .then(() => {
          message.success("Unidade criada com sucesso!");
          handleCloseModal();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
          if (onRefreshPage) {
            onRefreshPage();
          }
        });
    }
  };

  return (
    <Modal
      title="Editar Item"
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="unit_form_modal"
        >
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome da Unidade!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="description">
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { UnitModal };
