import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useCallback,
} from "react";
import { Outlet } from "react-router-dom";
import { IContract } from "../types";

type ContractContextData = {
  contract: IContract | undefined;
  handleSetContract: (data: IContract) => void;
};

type ContractProviderProps = {
  children: ReactNode;
};

const ContractContext = createContext({} as ContractContextData);

const ContractProvider = ({ children }: ContractProviderProps) => {
  const [contract, setContract] = useState<IContract>();

  const handleSetContract = useCallback((data: IContract) => {
    setContract(data);
  }, []);

  return (
    <ContractContext.Provider value={{ contract, handleSetContract }}>
      {children}
    </ContractContext.Provider>
  );
};

const useContract = () => {
  return useContext(ContractContext);
};

const ContractProviderLayout = () => (
  <ContractProvider>
    <Outlet />
  </ContractProvider>
);

export {
  ContractContext,
  ContractProvider,
  useContract,
  ContractProviderLayout,
};
