import { FC } from "react";

import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

import { ILocal } from "../../../types";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { ContractLocalModal } from "../components/ContractLocalModal";
import { getContractLocals } from "../../../services/repositories";
import { ActionTable } from "../../../components/ActionTable";
import { useDelete, useModal, usePagination } from "../../../hooks";

const ContractLocals: FC = () => {
  const {
    data: locals,
    isLoading,
    pagination,
    handleRefreshData,
  } = usePagination(getContractLocals);

  const { isOpen, handleCloseModal, handleOpenModal } =
    useModal(handleRefreshData);

  const { handleDeleteData } = useDelete(handleRefreshData);

  const actions = (record: ILocal) => {
    return [
      {
        key: "1",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () =>
          handleDeleteData(
            `/contracts/${record.meta?.pivot_contract_id}/locals/${record.id}`,
            "Deseja deletar o local?"
          ),
      },
    ];
  };

  const columns: ColumnsType<ILocal> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 300,
    },
    {
      title: "Endereço",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
  ];

  return (
    <Content>
      <ContractLocalModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        onRefreshData={handleRefreshData}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0, marginBottom: "16px" }}
        width="100%"
        backButton={false}
        title={"Locais do Contrato"}
        actions={[
          <Button
            key={"add_local_contract"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleOpenModal()}
          >
            Adicionar Local
          </Button>,
        ]}
      />
      <ActionTable
        actions={actions}
        pagination={pagination}
        loading={isLoading}
        columns={columns}
        dataSource={locals}
      />
    </Content>
  );
};

export { ContractLocals };
