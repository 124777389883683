import { FC, useEffect, useState } from "react";

import { Button, Skeleton } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { ActionFunction } from "../../../components/ActionColumn";
import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";

import { api } from "../../../services/api";

import { IServiceType, IMeta, IPagination } from "../../../types";
import { ServiceTypeModal } from "../components/ServiceTypeModal";
import { ServiceTypeList } from "../components/FilterServiceTypeList";
import { ActionTable } from "../../../components/ActionTable";

export const getServiceTypes = async (page = 1, perPage = 10, q?: any) => {
  return await api.get<IPagination<IServiceType>>("/service-types", {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const deleteServiceType = async (serviceTypeId: string) => {
  return await api.delete<void>(`service-types/${serviceTypeId}`);
};

const ServiceTypes: FC = () => {
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [filter, setFilter] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceType, setServiceTypeId] = useState<IServiceType | undefined>();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getServiceTypes(meta.current_page, meta.per_page, filter)
        .then(({ data }) => {
          setServiceTypes(data.data);
          setMeta(data.meta);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getData();
  }, [load, meta.current_page, meta.per_page, filter]);

  const actions: ActionFunction<IServiceType> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => {
          handleShowModal("update", record);
        },
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => {
          onDeleteItem(record);
        },
      },
    ];
  };

  const columns: ColumnsType<IServiceType> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
  ];

  const handleFilterData = async (values: any) => {
    setFilter(values);
  };

  const handleChangePage = async (page: number, pageSize: number) => {
    setLoading(true);
    await getServiceTypes(page, pageSize, filter)
      .then(({ data }) => {
        setServiceTypes(data?.data);
        setMeta(data?.meta);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const onDeleteItem = async (record: IServiceType) => {
    setLoading(true);
    await deleteServiceType(record.id)
      .then(() => handleRefreshData())
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShowModal = (mode?: string, record?: IServiceType) => {
    if (mode === "update" && record) {
      setServiceTypeId(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setServiceTypeId(undefined);
  };

  return (
    <Skeleton loading={false}>
      <PageHeader
        title="Grupo de Serviço"
        backButton={false}
        actions={[
          <Button
            key={"create_item"}
            onClick={() => handleShowModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Grupo de serviço
          </Button>,
        ]}
        crumb
      />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 24px 0" }}
      >
        <ServiceTypeList onSubmit={handleFilterData} />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          actions={actions}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record: IServiceType) => record.id}
          columns={columns}
          dataSource={serviceTypes}
        />
      </Content>
      <ServiceTypeModal
        onRefreshPage={handleRefreshData}
        onClose={handleCloseModal}
        isModalOpen={isModalOpen}
        serviceType={serviceType}
      />
    </Skeleton>
  );
};

export { ServiceTypes };
