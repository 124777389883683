import { FC, useState } from "react";

import { App, Button, Form, Modal, Spin, Upload, UploadProps } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { TOKEN_API, api } from "../../../services/api";

import { usePmoc } from "../../../contexts/PmocContext";

import { IContractFile, IPmocFile, ModalProps } from "../../../types";

const PmocFileModal: FC<ModalProps<IPmocFile>> = ({
  onClose,
  onRefreshData,
  isModalOpen,
  data: contractFile,
}) => {
  const { message } = App.useApp();
  const { pmoc } = usePmoc();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any>();

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);

    if (!files) {
      return;
    }

    setLoading(true);
    handleSubmitForm({ files: files })
      .then(() => {
        message.success("Arquivo adicionado com sucesso!");
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        }
      })
      .finally(() => {
        setLoading(false);
        if (onRefreshData) {
          onRefreshData();
        }
      });
  };

  const handleSubmitForm = async (values: any) => {
    return await api.post<IContractFile>(`/pmocs/${pmoc?.id}/files`, values);
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_API + `/upload`,
    data: {
      folder: "pmoc_files",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setFiles(info.fileList.map((file) => file.response));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept: "image/*,.pdf",
    listType: "picture",
    maxCount: 3,
  };

  return (
    <Modal
      title={`${contractFile ? "Editar" : "Adicionar"} Arquivo`}
      open={isModalOpen}
      centered
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="contract_file_form_modal">
          <Form.Item label="Arquivo">
            <Form.Item
              name="file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Por favor, insira um arquivo.",
                },
              ]}
              noStyle
            >
              <Upload.Dragger name="file" {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click ou arraste o arquivo para essa area de upload
                </p>
                <p className="ant-upload-hint">
                  Suporte para upload único ou em massa.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { PmocFileModal };
