import { FC } from "react";
import { ConfigProvider, theme, App, FloatButton } from "antd";

import ptBR from "antd/locale/pt_BR";

import { Routes } from "./routes";
import { useColorMode } from "./contexts/ColorModeContext";

const MyApp: FC = () => {
  const { selectedTheme } = useColorMode();

  return (
    <ConfigProvider
        theme={{
          token: {
            colorPrimary: selectedTheme === "light" ? "#3030DA" : "#1677FF",
            colorInfo: selectedTheme === "light" ? "#3030da" : "#1677FF",
            colorBgBase: selectedTheme === "light" ? "#ffffff" : "#141414",
            colorTextBase: selectedTheme === "light" ? "#141414" : "#ffffff",
            borderRadius: 1,
            wireframe: false,
            fontFamily: "Poppins",
          },
          components: {
            Timeline: {
              itemPaddingBottom: 50,
            },
            Menu: {
              colorText: "#8c8c8c",
            },
          },
          algorithm:
            selectedTheme === "light"
              ? theme.defaultAlgorithm
              : theme.darkAlgorithm,
        }}
        locale={ptBR}
      >
        <App>
          <FloatButton.BackTop visibilityHeight={50} tooltip="Voltar ao Topo" />
          <Routes />
        </App>
    </ConfigProvider>
  );
};

export { MyApp };
