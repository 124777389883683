import { FC, useEffect, useState } from "react";

import { App, Button, Form, InputNumber, Modal, Spin } from "antd";

import { api } from "../../../services/api";
import { IUserConfig, ModalProps } from "../../../types";
import { useParams } from "react-router-dom";

const UserConfigModal: FC<ModalProps<IUserConfig>> = ({
  onClose,
  isModalOpen,
  data: config,
}) => {
  const { id } = useParams();
  const { message } = App.useApp();

  const [form] = Form.useForm<IUserConfig>();
  const [loading, setLoading] = useState(false);

  const handleCloseModal = (refresh = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  useEffect(() => {
    if (config) {
      form.setFieldsValue({ ...config });
    }
  }, [config, form]);

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then(() => handleCloseModal(true))
      .then(() => message.success(`Email enviado com sucesso!`))
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitForm = async (values: IUserConfig) => {
    return await api.put<IUserConfig>(`/users/${id}/configs`, values);
  };

  return (
    <Modal
      title={"Editar usuário"}
      open={isModalOpen}
      onCancel={() => handleCloseModal}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="user_config_form_modal">
          <Form.Item label="Porcentagem" name="percentage">
            <InputNumber
              style={{ width: "100%" }}
              type="number"
              min={0}
              max={100}
              placeholder="Insira o nome"
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { UserConfigModal };
