
import { useSortable } from '@dnd-kit/sortable';
import { Button, Tooltip } from 'antd';

type ChecklistItem = {
  name: string;
  icon: JSX.Element;
  label: string;
  description: string;
};

type DraggableButtonProps = {
  item: ChecklistItem;
};

export const DraggableButton: React.FC<DraggableButtonProps> = ({ item }:DraggableButtonProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.name,
  });

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={{
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition,
      }}
    >
      <Tooltip title="Arraste" color="#3030da">
      <Button
        type="text"
        size="large"
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
          cursor: 'grab',
          width: '100%'
        }}
      >
        {item.icon}
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            wordWrap: "break-word",
            overflowWrap: "break-word",
            wordBreak: "break-word",
          }}
        >
          <p style={{ fontSize: 13, fontWeight: 600, margin: 0 }}>
            {item.label}
          </p>
          <p
            style={{
              fontSize: 11,
              color: "#8c8c8c",
              margin: 0,
              maxWidth: 220,
              whiteSpace: "normal",
              textAlign: "left",
            }}
          >
            {item.description}
          </p>
        </span>
      </Button>
      </Tooltip>
    </div>
  );
};
