import { CSSProperties, createElement } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  Select,
  Slider,
  Switch,
  Typography,
  theme,
} from "antd";
import { HolderOutlined } from "@ant-design/icons";

import { useColorMode } from "../../../contexts/ColorModeContext";
import * as Icons from "../../../assets/icons";

export const Fields = {
  Input: Input,
  Switch: Switch,
  Select: Select,
  TextArea: Input.TextArea,
  Title: Typography.Title,
  Text: Typography.Text,
  Divider: Divider,
  Slider: Slider,
  Checkbox: Checkbox,
};

export interface FormField {
  element: keyof typeof Fields;
  name: string;
  options: any[];
  defaultValue: any;
  id: number | string;
  value: any;
}

interface SortableItemProps {
  Element: FormField;
  onDeleteItem: (id: FormField["id"]) => void;
  handleShowEditModal: (id: string, name: FormField["name"]) => void;
}

const SortableItem = ({
  Element: { element, name, id, options },
  onDeleteItem,
  handleShowEditModal,
}: SortableItemProps): any => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const {
    token: { borderRadius, boxShadowTertiary, colorBgBase, colorBorder },
  } = theme.useToken();
  const { selectedTheme } = useColorMode();


  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: colorBgBase,
    borderRadius,
    marginBottom: 6,
    boxShadow: boxShadowTertiary,
    border: "1px solid" + colorBorder,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    opacity: 1,
  };

  let field: any = Fields[element];

  const render = () => {
    let _field: any;
    switch (element) {
      case "Title":
        _field = createElement(field, {
          key: id,
          children: name,
          level: 2,
          style: { marginBottom: 0 },
        });
        break;

      case "Text":
        _field = createElement(field, {
          key: id,
          children: name,
        });
        break;

      case "Divider":
        _field = createElement(field, {
          key: id,
          style: { borderWidth: 10, minWidth: "initial" },
        });
        break;

      case "Slider":
        _field = (
          <Form.Item label={name + ":"} style={{ width: "100%" }}>
            {createElement(field, {
              defaultValue: 30,
            })}
          </Form.Item>
        );
        break;

      case "Select":
        _field = (
          <Form.Item label={name + ":"} style={{ width: "100%" }}>
            {createElement(field, {
              options: options.map((item) => {
                return { key: item, label: item };
              }),
            })}
          </Form.Item>
        );
        break;

      case "Checkbox":
        _field = (
          <Form.Item label={name + ":"} style={{ width: "100%" }}>
            {options.map((item) => {
              return (
                <div style={{ marginBottom: 10 }}>
                  <Checkbox key={item}>{item}</Checkbox>
                </div>
              );
            })}
          </Form.Item>
        );
        break;

      default:
        _field = (
          <Form.Item
            label={name + ":"}
            style={{ width: "100%", pointerEvents: "none" }}
          >
            {createElement(field)}
          </Form.Item>
        );
        break;
    }

    return _field;
  };

  return (
    <div id={String(id)} ref={setNodeRef} style={style} {...attributes}>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginBottom: -10,
          marginRight: 16,
          marginTop: 16
        }}
      >
        {element !== 'Divider' && (
          <Button
            type="text"
            onClick={() => handleShowEditModal(id.toString(), element)}
            icon={
              <Icons.PencilIcon
                fill={selectedTheme === "light" ? "#434343" : "#f0f0f0"}
              />
            }
          />
        )}
        <Button
          type="text"
          icon={<Icons.TrashIcon />}
          onClick={() => onDeleteItem(id)}
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: 16,
          padding: "16px",
        }}
      >
        <HolderOutlined
          style={{ touchAction: "none", cursor: "move" }}
          {...listeners}
        />
        {render()}
      </div>
    </div>
  );
};

export { SortableItem };
