import { message } from "antd";
import axios, { AxiosError } from "axios";

export const TOKEN_API = "@arcke:token";
export const STORAGE_USER = "@arcke:user";

const api = axios.create({
  baseURL: process.env.REACT_APP_API,
  paramsSerializer: {
    indexes: true,
  },
});

api.interceptors.response.use(undefined, (data: AxiosError<any>) => {
  if (data.response) {
    if (data.response.status === 401 && data.config?.url !== "/login") {
      window.location.replace("/login");
      localStorage.removeItem(STORAGE_USER);
      localStorage.removeItem(TOKEN_API);
      message.error("Rota não encontrada!");
    }
    if (
      data.response.status === 404 &&
      data.response.data?.code !== "E_ROW_NOT_FOUND"
    ) {
      message.error("Rota não encontrada!");
    }
  }
  return Promise.reject(data);
});

export { api };
