import { useState, useCallback, FC, useEffect } from "react";
import { useLoaderData } from "react-router-dom";

import { App, Button, Table } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { IServiceOrder } from "../../../types";
import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { FilterOrderList } from "./FilterOrderList";
import { reports } from "../../../services/reports";
import { api } from "../../../services/api";

export const getOrdersStatus = async (q?: any) => {
  return await api.get<any>(`/reports/orders-status`, {
    params: {
      ...q,
    },
  });
};

const OrdersStatus: FC = () => {
  const { message } = App.useApp();
  const params = useLoaderData();

  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<any>(params);

  const handleChangePage = useCallback(async () => {
    if (filter) {
      setLoading(true);
      await getOrdersStatus(filter)
        .then(({ data }) => {
          setData(data?.data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filter]);

  useEffect(() => {
    handleChangePage();
  }, [handleChangePage]);

  const columns: ColumnsType<IServiceOrder> = [
    {
      title: "Técnico",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: "30%",
    },
    {
      title: "Em Aberto",
      dataIndex: "orders",
      key: "OPEN",
      render: (text) => text?.OPEN.length,
    },
    {
      title: "Em Execução",
      dataIndex: "orders",
      key: "EXECUTION",
      render: (text) => text?.EXECUTION.length,
    },
    {
      title: "Em Atraso",
      dataIndex: "orders",
      key: "DELAYED",
      render: (text) => text?.DELAYED.length,
    },
    {
      title: "Em Finalizado",
      dataIndex: "orders",
      key: "FINALIZED",
      render: (text) => text?.FINALIZED.length,
    },
    {
      title: "Em Reaberto",
      dataIndex: "orders",
      key: "REOPENED",
      render: (text) => text?.REOPENED.length,
    },
    {
      title: "Em Validado",
      dataIndex: "orders",
      key: "VALIDATED",
      render: (text) => text?.VALIDATED.length,
    },
  ];

  const onPrint = () => {
    if (filter && data.length > 0) {
      reports("orders-status", filter);
      return;
    }
    if (filter && data.length === 0) {
      message.info("Não há registros para impressão.");
      return;
    }
    message.error("Você precisa filtar as informações!");
  };

  return (
    <>
      <PageHeader
        title="Relatórios de Ordens de Serviço por Status"
        backButton={false}
        actions={[
          <Button
            onClick={onPrint}
            key={"print_table"}
            icon={<PrinterOutlined />}
            type="primary"
          >
            Imprimir
          </Button>,
        ]}
        crumb
      />
      <Content margin>
        <FilterOrderList
          onSubmit={(values) => setFilter(values)}
          filter={filter}
        />
      </Content>
      <Content margin>
        <Table
          pagination={{
            position: ["bottomCenter"],
          }}
          size="small"
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={data}
        />
      </Content>
    </>
  );
};

export { OrdersStatus };
