import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export function ReportsIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={() => (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 656 1024"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M555.16 130.327H529.772V113.402C529.772 74.4727 499.306 44.0066 462.069 44.0066H365.593V38.9289C365.593 18.6182 348.668 0 326.664 0C306.354 0 287.736 16.9256 287.736 38.9289V44.0066H191.26C154.023 44.0066 123.557 74.4727 123.557 113.402V130.327H98.1686C44.0066 130.327 0 176.026 0 230.188V924.139C0 979.993 44.0066 1024 98.1686 1024H556.853C611.015 1024 655.021 978.301 655.021 924.139V230.188C653.329 174.334 609.322 130.327 555.16 130.327ZM199.722 120.172H453.607V167.564V214.955H199.722V120.172ZM577.164 924.139C577.164 937.679 567.008 947.835 555.16 947.835H98.1686C86.3207 947.835 76.1653 937.679 76.1653 924.139V230.188C76.1653 216.648 86.3207 206.493 98.1686 206.493H123.557V223.418C123.557 262.347 154.023 292.813 191.26 292.813H462.069C499.306 292.813 529.772 262.347 529.772 223.418V206.493H556.853C568.701 206.493 578.856 216.648 578.856 230.188V924.139H577.164Z" />
        </svg>
      )}
      {...props}
    />
  );
}
