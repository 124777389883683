import { FC } from "react";

import { Tabs } from "antd";

import { Content } from "../../components/Content";
import { PageHeader } from "../../components/PageHeader";
import { items } from "./tabs";

const Permissions: FC = () => {
  return (
    <>
      <PageHeader title="Organização" backButton={false} />
      <Content width="calc(100% - 48px)" margin>
        <Tabs defaultActiveKey="2" items={items} />
      </Content>
    </>
  );
};

export { Permissions };
