import { FC, useEffect, useState } from "react";
import { DatePicker, Form, FormInstance, Select } from "antd";

import { getAllContracts } from "../../contracts/list";
import { getLocalUnits } from "../../clients/client/ClientLocalUnits";
import { getClients, getContractLocals } from "../../../services/repositories";
import { getPmocs } from "../../pmocs/list";
import { getPmocLocals } from "../../pmocs/pmoc";

import {
  IClient,
  IContract,
  ILocal,
  IPmoc,
  IServiceOrder,
  IUnit,
} from "../../../types";

interface PmocFormProps {
  form: FormInstance;
  data?: IServiceOrder | null;
}

const PmocOrderForm: FC<PmocFormProps> = ({ form, data }) => {
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [pmocs, setPmocs] = useState<IPmoc[]>([]);
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getClients(1, 1000).then(({ data }) => {
        setClients(data);
      });

      if (data) {
        if (data.contractId) {
          await getContractLocals({
            id: data.contractId,
            page: 1,
            perPage: 1000,
          }).then(({ data }) => {
            setLocals(data);
          });
        }
        await getAllContracts(1, 1000, { clientId: data.clientId }).then(
          ({ data }) => {
            setContracts(data.data);
          }
        );
        await getLocalUnits(data.localId, 1, 1000).then(({ data }) => {
          setUnits(data.data);
        });
      }
    };
    getData();
  }, [form, data]);

  const handleChangeClient = async (clientId: string) => {
    setContracts([]);
    await loadContracts(clientId);
  };

  const handleChangeContract = async (contractId: string) => {
    form.setFieldsValue({ localId: null, unitId: null, pmocId: null });
    await getPmocs(1, 1000, { contractId }).then(({ data }) => {
      setPmocs(data.data);
    });
  };

  const handleChangePmoc = async (pmocId: string) => {
    form.setFieldsValue({ localId: null, unitId: null });
    await getPmocLocals(pmocId, 1, 1000).then(({ data }) => {
      setLocals(data.data);
    });
  };

  const loadContracts = async (clientId?: string) => {
    form.setFieldValue("contractId", null);
    if (clientId) {
      await getAllContracts(1, 1000, { clientId }).then(({ data }) => {
        setContracts(data.data);
      });
    }
  };

  const loadUnits = async (localId?: string) => {
    form.setFieldValue("unitId", null);
    if (localId) {
      await getLocalUnits(localId, 1, 1000).then(({ data }) => {
        setUnits(data.data);
      });
    }
  };

  const DISABLED = !!data;

  return (
    <>
      <Form.Item
        label="Cliente:"
        name="clientId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o cliente!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          disabled={DISABLED}
          options={clients.map((client) => {
            return {
              label: client.name,
              value: client.id,
            };
          })}
          onChange={handleChangeClient}
          placeholder="Selecione um cliente"
        />
      </Form.Item>
      <Form.Item
        label="Contrato:"
        name="contractId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o contrato!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          disabled={DISABLED}
          options={contracts.map((contract) => {
            return {
              label: contract.name,
              value: contract.id,
            };
          })}
          onChange={handleChangeContract}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
      <Form.Item
        label="PMOC:"
        name="pmocId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o pmoc!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          disabled={DISABLED}
          options={pmocs.map((pmoc) => {
            return {
              label: pmoc.name,
              value: pmoc.id,
            };
          })}
          onChange={handleChangePmoc}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
      <Form.Item
        label="Local:"
        name="localId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o local!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          disabled={DISABLED}
          options={locals.map((local) => {
            return {
              label: local.name,
              value: local.id,
            };
          })}
          onChange={loadUnits}
          placeholder="Selecione um local"
        />
      </Form.Item>
      <Form.Item
        label="Unidade:"
        name="unitId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione a unidade!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={units.map((unit) => {
            return {
              label: unit.name,
              value: unit.id,
            };
          })}
          placeholder="Selecione uma unidade"
        />
      </Form.Item>
      <Form.Item
        label="Mês do PMOC:"
        name="month"
        rules={[
          {
            required: true,
            message: "Por favor insira o mês de referência!",
          },
        ]}
      >
        <DatePicker
          format="MMMM/YYYY"
          picker="month"
          style={{ width: "100%" }}
        />
      </Form.Item>
    </>
  );
};

export { PmocOrderForm };
