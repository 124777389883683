import { RouteObject } from "react-router-dom";
import { ContractProviderLayout } from "../../contexts/ContractContext";
import { Contract } from "./contract";
import { ContractItems } from "./contract/ContractItems";
import { Contracts } from "./list";
import { ContractAdditives } from "./contract/ContractAdditives";
import { ContractLocals } from "./contract/ContractLocals";
import { ContractFiles } from "./contract/ContractFiles";
import { IContract } from "../../types";
import { api } from "../../services/api";
import { ContractProducts } from "./contract/ContractProducts";

export const ContractRouter: RouteObject = {
  path: "/contracts",
  element: <ContractProviderLayout />,
  handle: { crumb: () => "Contratos" },
  children: [
    {
      index: true,
      element: <Contracts />,
    },
    {
      path: ":id",
      element: <Contract />,
      loader: async ({ params }) => {
        const { data } = await api.get<IContract>(`/contracts/${params.id}`);
        return data;
      },
      handle: {
        crumb: (data?: IContract) => data?.name,
      },
      children: [
        {
          path: "additives",
          element: <ContractAdditives />,
          handle: { crumb: () => "Aditivos" },
        },
        {
          path: "items",
          element: <ContractItems />,
          handle: { crumb: () => "Items" },
        },
        {
          path: "products",
          element: <ContractProducts />,
          handle: { crumb: () => "Produtos" },
        },
        {
          path: "locals",
          element: <ContractLocals />,
          handle: { crumb: () => "Locais" },
        },
        {
          path: "files",
          element: <ContractFiles />,
          handle: { crumb: () => "Arquivos" },
        },
      ],
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
