import { useColorMode } from "../../../contexts/ColorModeContext";

export const IconArea = ({ children }: React.ReactNode | any) => {
  const { selectedTheme } = useColorMode();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: 30,
        height: 30,
        backgroundColor: selectedTheme === "light" ? "#f0f0f0" : "#141414",
        borderRadius: 2,
      }}
    >
      {children}
   </div>
  )
};