import { FC } from "react";

import { Button } from "antd";
import { ColumnsType } from "antd/es/table";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { FilterActivitiesList } from "../components/FilterActivitiesList";
import { IActivity } from "../../../types";
import { ActivityModal, FREQUENCIES } from "../components/ActivityModal";
import { ActionTable } from "../../../components/ActionTable";
import { getActivities } from "../../../services/repositories";
import { useDelete, useModal, usePagination } from "../../../hooks";

const Activities: FC = () => {
  const {
    data: activities,
    isLoading,
    setFilter,
    handleRefreshData,
    pagination,
  } = usePagination(getActivities);

  const {
    data: activity,
    isOpen,
    handleCloseModal,
    handleOpenModal,
  } = useModal<IActivity>(handleRefreshData);

  const { handleDeleteData } = useDelete(handleRefreshData);

  const actions: ActionFunction<IActivity> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => {
          handleOpenModal(record);
        },
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () =>
          handleDeleteData(
            `/service-orders/${record.id}`,
            "Deseja deletar a atividade?"
          ),
      },
    ];
  };

  const columns: ColumnsType<IActivity> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Frequência",
      dataIndex: "frequency",
      key: "frequency",
      render: (frequency) =>
        FREQUENCIES.find(
          ({ value }) => value === frequency
        )?.label.toUpperCase(),
    },
  ];

  return (
    <>
      <PageHeader
        title="Atividades"
        backButton={false}
        actions={[
          <Button
            key={"create_activity"}
            onClick={() => handleOpenModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar atividade
          </Button>,
        ]}
        crumb
      />
      <ActivityModal
        onClose={handleCloseModal}
        isModalOpen={isOpen}
        data={activity}
      />
      <Content margin>
        <FilterActivitiesList onSubmit={setFilter} />
      </Content>
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          loading={isLoading}
          columns={columns}
          dataSource={activities}
        />
      </Content>
    </>
  );
};

export { Activities };
