import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "dayjs/locale/pt-br";

import {
  DatePicker,
  Button,
  Form,
  App,
  Modal,
  Select,
  Spin,
  Input,
} from "antd";

import { api } from "../../../services/api";
import { formatters } from "../../../utils";
import { IServiceOrder, IUser, ModalProps } from "../../../types";

import { getUsers } from "../../users/list";
import { getChecklists } from "../../checklist/list";

import { ContractOrderForm } from "../forms/ContractOrderForm";
import { SimpleOrderForm } from "../forms/SimpleOrderForm";
import { BudgetOrderForm } from "../forms/BudgetOrderForm";
import { PmocOrderForm } from "../forms/PmocOrderForm";
import dayjs from "dayjs";

const { TextArea } = Input;

const OrderModal: FC<ModalProps<IServiceOrder>> = ({
  onClose,
  isModalOpen,
  data: serviceOrder,
}) => {
  const { message } = App.useApp();
  const { pathname } = useLocation();

  const PATH = pathname.split("/")[1];

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [technical, setTechnical] = useState<IUser[]>([]);
  const [checklists, setChecklists] = useState<any[]>([]);

  useEffect(() => {
    const getData = async () => {
      getUsers(1, 1000, { profile: "TECHNICIAN" }).then(({ data }) => {
        const activeTechnical = data.data.filter((tech) => tech.isActive);
        setTechnical(activeTechnical);
      });
      getChecklists(1, 1000).then(({ data }) => {
        setChecklists(data.data);
      });
    };
    if (isModalOpen) {
      getData();
      if (serviceOrder) {
        form.setFieldsValue({
          ...serviceOrder,
          startTo: dayjs(
            formatters.simpleDate(serviceOrder.startTo),
            "DD/MM/YYYY"
          ),
          endTo: dayjs(formatters.simpleDate(serviceOrder.endTo), "DD/MM/YYYY"),
          serviceTypes: serviceOrder.serviceTypes?.map((type) => type.id),
          equipmentTypes: serviceOrder.equipmentTypes?.map((type) => type.id),
          technicianId: serviceOrder.users?.find(
            (user) => user.profile === "TECHNICIAN"
          )?.id,
        });
      }
    }
  }, [form, serviceOrder, isModalOpen]);

  const handleCloseModal = (refresh = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const handleChangeOrderType = async (type: string) => {
    if (!serviceOrder) {
      form.resetFields();
    }
    form.setFieldValue("type", type);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `${
            pathname === "orders"
              ? `Ordem de Serviço ${
                  data.config.method === "put" ? "atualizada" : "criada"
                }`
              : `Orçamento ${
                  data.config.method === "put" ? "atualizado" : "criado"
                }`
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitForm = async (values: any) => {
    return api.post(`/service-orders`, values);
  };

  const orderTypesOptions = [
    { label: "ORÇAMENTO", value: "BUDGET" },
    { label: "CONTRATO", value: "CONTRACT" },
    { label: "AVULSA", value: "SINGLE" },
    { label: "PMOC", value: "PMOC" },
  ];

  return (
    <Modal
      title={
        PATH === "orders"
          ? `${serviceOrder ? "Editar OS" : "Adicionar OS"}`
          : `${serviceOrder ? "Editar orçamento" : "Adicionar orçamento"}`
      }
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      centered
      width={700}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {serviceOrder ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="service_order_form_modal"
          initialValues={{
            type: PATH === "budgets" ? "BUDGET" : undefined,
          }}
          style={{
            maxHeight: 740,
            overflowY: "auto",
            paddingRight: 10,
          }}
        >
          <Form.Item
            label="Tipo de Atendimento:"
            name="type"
            rules={[
              {
                required: true,
                message: "Por favor selecione o tipo de Atendimento!",
              },
            ]}
          >
            <Select
              disabled={PATH === "budgets" || !!serviceOrder}
              allowClear
              options={orderTypesOptions}
              onChange={handleChangeOrderType}
              placeholder="Selecione um tipo de atendimento"
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              const type: IServiceOrder["type"] = form.getFieldValue("type");
              if (type === "CONTRACT") {
                return <ContractOrderForm form={form} data={serviceOrder} />;
              }
              if (type === "SINGLE") {
                return <SimpleOrderForm form={form} />;
              }
              if (type === "BUDGET") {
                return <BudgetOrderForm form={form} />;
              }
              if (type === "PMOC") {
                return <PmocOrderForm form={form} />;
              }
            }}
          </Form.Item>
          <Form.Item
            label="Descrição:"
            name="description"
            rules={[
              {
                required: true,
                message: "Por favor insire uma descrição para o atendimento!",
              },
            ]}
          >
            <TextArea
              style={{ resize: "none" }}
              rows={5}
              showCount
              maxLength={255}
              placeholder="Insira uma descrição"
            />
          </Form.Item>
          <Form.Item label="Instruções:" name="instructions">
            <TextArea
              style={{ resize: "none" }}
              rows={3}
              showCount
              maxLength={255}
              placeholder="Insira as instruções"
            />
          </Form.Item>
          <Form.Item label="Checklist:" name="checklistId">
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              options={checklists.map((checklist) => {
                return {
                  label: checklist.name,
                  value: checklist.id,
                };
              })}
              placeholder="Selecione um checklist"
            />
          </Form.Item>
          <Form.Item
            label="Técnico:"
            name="technicianId"
            rules={[
              {
                required: true,
                message: "Por favor selecione um Técnico para o atendimento!",
              },
            ]}
          >
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              options={technical.map((technician) => {
                return {
                  label: technician.name,
                  value: technician.id,
                };
              })}
              placeholder="Selecione um Técnico"
            />
          </Form.Item>
          <Form.Item
            label="Previsão de Atendimento:"
            name="startTo"
            rules={[
              {
                required: true,
                message: "Por favor insira a previsão de atendimento!",
              },
            ]}
          >
            <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Previsão de Conclusão:"
            name="endTo"
            rules={[
              {
                required: true,
                message: "Por favor insira a previsão de conlusão!",
              },
            ]}
          >
            <DatePicker
              picker="date"
              format={"DD/MM/YYYY"}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { OrderModal };
