import { RouteObject } from "react-router-dom";
import { Products } from "./list";

export const ProductRouter: RouteObject = {
  path: "/products",
  handle: { crumb: () => "Produtos" },
  children: [
    {
      index: true,
      element: <Products />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
