import { FC, useEffect, useState } from "react";

import {
  Button,
  Form,
  Input,
  InputNumber,
  message as Message,
  Modal,
  Select,
  Spin,
  Upload,
  UploadProps,
} from "antd";

import { TOKEN_API, api } from "../../../services/api";
import { IProduct, ModalProps } from "../../../types";
import { formatters, normFile, parsers } from "../../../utils";
import { InboxOutlined } from "@ant-design/icons";

const { TextArea } = Input;

export const unitTypes = [
  { label: "Unidade", value: "UN" },
  { label: "Caixa", value: "CX" },
  { label: "Kit", value: "KIT" },
  { label: "Barra", value: "BR" },
  { label: "Metro", value: "M1" },
  { label: "Metro Quadrados", value: "M2" },
  { label: "Metro Cubicos", value: "M3" },
  { label: "Galão", value: "GL" },
  { label: "Hora", value: "HRS" },
  { label: "Kilo", value: "KG" },
  { label: "Litro", value: "LT" },
  { label: "Serviço", value: "SERV" },
  { label: "Tonelada", value: "TON" },
  { label: "Rolo", value: "ROLO" },
  { label: "Produto", value: "PC" },
];

const ProductModal: FC<ModalProps<IProduct>> = ({
  onClose,
  onRefreshData,
  isModalOpen,
  data: product,
}) => {
  const [message, context] = Message.useMessage();

  const [form] = Form.useForm();
  const [image, setImage] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isModalOpen) {
      if (product) {
        form.setFieldsValue({
          ...product,
        });
        setImage(
          product.image
            ? [
                {
                  uid: "-1",
                  status: "done",
                  name: product.name,
                  url: product.image,
                  thumbUrl: process.env.REACT_APP_ENDPOINT + product.image,
                },
              ]
            : []
        );
      }
    }
  }, [form, isModalOpen, product]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm({ ...values, image: image[0]?.url })
      .then((data) => {
        message.success(
          `Item ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
        if (onRefreshData) {
          onRefreshData();
        }
      });
  };

  const handleSubmitForm = async (values: IProduct) => {
    if (product) {
      return await api.put<IProduct>(`/products/${product.id}`, values);
    }
    return await api.post<IProduct>(`/products`, values);
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_API + `/upload`,
    data: {
      folder: "products",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    fileList: image,
    onChange(info) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      setImage(newFileList);

      if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept: "image/*",
    maxCount: 1,
  };

  return (
    <Modal
      title={`${product ? "Editar" : "Criar"} Produto`}
      open={isModalOpen}
      centered
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      {context}
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="product_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome do Produto.",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="description">
            <TextArea
              style={{ resize: "none" }}
              rows={5}
              showCount
              maxLength={255}
            />
          </Form.Item>
          <Form.Item
            label="Unidade"
            name="unit"
            rules={[
              {
                required: true,
                message: "Por favor selecione o a unidade do Produto.",
              },
            ]}
          >
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              options={unitTypes.map((unitType) => {
                return {
                  value: unitType.value,
                  label: `${unitType.label} | ${unitType.value}`,
                };
              })}
            />
          </Form.Item>
          <Form.Item
            name="value"
            label="Preço"
            rules={[
              {
                required: true,
                message: "Por favor, insira o valor do Produto.",
              },
            ]}
          >
            <InputNumber
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="cost"
            label="Custo"
            rules={[
              {
                required: true,
                message:
                  "Por favor, insira o valor de custo do Produto Produto.",
              },
            ]}
          >
            <InputNumber
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="stock"
            label="Estoque"
            rules={[
              {
                required: true,
                message: "Por favor, insira o estoque do Produto.",
              },
            ]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item name="minimumStock" label="Estoque Mínimo">
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="dragger"
            valuePropName="fileList"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger name="file" {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click ou arraste o arquivo para essa area de upload
              </p>
              <p className="ant-upload-hint">
                Suporte para upload único ou em massa.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ProductModal };
