import { FC, useState } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Form, FormInstance, Row, Space, theme } from "antd";

interface ExpandableFilterProps {
  fields: JSX.Element[];
  form: FormInstance<any>;
  onFinish?: (values: any) => void;
  onClear?: () => void;
}

const ExpandableFilter: FC<ExpandableFilterProps> = ({
  fields,
  form,
  onFinish,
  onClear,
}) => {
  const {
    token: { colorLink },
  } = theme.useToken();
  const [expand, setExpand] = useState(false);

  const getFields = () => {
    const count = expand ? fields.length : 4;
    const children = [];
    for (let i = 0; i < count; i++) {
      children.push(fields[i]);
    }
    return children;
  };

  return (
    <Form form={form} name="expandable_filter_search" onFinish={onFinish}>
      <Row gutter={24}>{getFields()}</Row>
      <div style={{ textAlign: "right" }}>
        <Space size="small" style={{ paddingBottom: 8 }}>
          <Button
            onClick={() => {
              form.resetFields();
              if (onClear) {
                onClear();
              }
            }}
          >
            Limpar
          </Button>
          <Button type="primary" htmlType="submit">
            Pesquisar
          </Button>
          {fields.length > 4 ? (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a
              onClick={() => {
                setExpand(!expand);
              }}
              style={{ padding: 0, color: colorLink, marginLeft: "8px" }}
            >
              {expand ? "Voltar" : "Expandir"}{" "}
              {expand ? <UpOutlined /> : <DownOutlined />}
            </a>
          ) : null}
        </Space>
      </div>
    </Form>
  );
};

export { ExpandableFilter };
