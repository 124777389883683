import { useCallback, useEffect, useState } from "react";
import { useLoaderData, useParams } from "react-router-dom";
import { App, TablePaginationConfig } from "antd";

import { IMeta, IPagination } from "../types";

export function usePagination<T = unknown>(
  getData: (params: any, filter?: any) => Promise<IPagination<T>>
) {
  const { message } = App.useApp();
  const loader = useLoaderData();
  const params = useParams();

  const [filter, setFilter] = useState<any>(loader);
  const [data, setData] = useState<T[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleRefreshData = () => handlePagination();

  const handlePagination = useCallback(
    async (page = 1, perPage = 10) => {
      setIsLoading(true);
      getData(
        {
          page,
          perPage,
          ...params,
        },
        filter
      )
        .then(({ data, meta }) => {
          if (meta.last_page < meta.current_page) {
            handlePagination(meta.last_page, meta.per_page);
          }
          setData(data);
          setMeta(meta);
        })
        .catch((error) => {
          if (error?.response?.status === 403) {
            message.error(
              "Desculpe! no momento você não tem permissão para isso."
            );
          } else {
            console.log(error);
          }
        })
        .finally(() => setIsLoading(false));
    },
    [filter, params, message, getData]
  );

  useEffect(() => {
    handlePagination();
  }, [handlePagination]);

  const pagination: TablePaginationConfig = {
    position: ["bottomCenter"],
    showSizeChanger: true,
    pageSizeOptions: [5, 10, 20, 50],
    current: meta.current_page,
    pageSize: meta.per_page,
    total: meta.total,
    showTotal: (total, range) =>
      `${range[0]}-${range[1]} de ${total} registros`,
    onChange: handlePagination,
  };

  return {
    data,
    isLoading,
    meta,
    pagination,
    filter,
    setFilter,
    handlePagination,
    handleRefreshData,
  };
}
