import React, { useState } from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import { Descriptions, Typography } from "antd";
import {
  ReconciliationOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";

import { IClient } from "../../../types";
import { formatters } from "../../../utils/formatters";
import { MenuItem, PageHeader } from "../../../components/PageHeader";

const { Text } = Typography;

const Client: React.FC = () => {
  const data = useLoaderData() as IClient;

  const [client] = useState<IClient>(data);

  const items: MenuItem[] = [
    {
      label: "Equipamentos",
      key: "equipments",
      icon: <ReconciliationOutlined />,
    },
    {
      label: "Locais",
      key: "locals",
      icon: <ReconciliationOutlined />,
    },
    {
      label: "Central do Cliente",
      key: "users",
      icon: <UsergroupAddOutlined />,
    },
  ];

  return (
    <>
      <PageHeader title="Cliente" menuItems={items} crumb>
        <Descriptions size="small">
          <Descriptions.Item label="Nome" span={3}>
            <Text strong>{client?.name?.toUpperCase()}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="ID">
            <Text strong>{String(client?.sid).padStart(4, "0")}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Razão Social">
            <Text strong>{client?.corporateName?.toUpperCase()}</Text>
          </Descriptions.Item>
          <Descriptions.Item
            label={client?.clientType === "LEGAL" ? "CNPJ" : "CPF"}
          >
            <Text strong>{formatters.identifier(client?.identifier)}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Tipo">
            <Text strong>
              {client?.clientType === "LEGAL" ? "JURIDICA" : "FISICA"}
            </Text>
          </Descriptions.Item>
          <Descriptions.Item label="Criado">
            <Text strong>{formatters.simpleDate(client?.createdAt)}</Text>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <div style={{ marginTop: "16px" }}>
        <Outlet />
      </div>
    </>
  );
};

export { Client };
