import { FC, useEffect, useState } from "react";

import { App, Button, Form, Input, Modal, Select, Spin } from "antd";

import { api } from "../../../services/api";
import {
  IEquipment,
  ISector,
  IService,
  IUnit,
  ModalProps,
} from "../../../types";
import { getLocalUnits } from "../../clients/client/ClientLocalUnits";
import { useServiceOrder } from "../../../contexts/OrderContext";
import { getUnitSectors } from "../../clients/client/ClientUnitSectors";
import { getEquipments } from "../../../services/repositories";

const { TextArea } = Input;

const ServiceModal: FC<ModalProps<IService>> = ({
  onClose,
  isModalOpen,
  data: service,
}) => {
  const { message } = App.useApp();
  const { serviceOrder } = useServiceOrder();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [units, setUnits] = useState<IUnit[]>([]);
  const [sectors, setSectors] = useState<ISector[]>([]);
  const [equipments, setEquipments] = useState<IEquipment[]>([]);

  const [selectLoading, setSelectLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await getLocalUnits(serviceOrder?.localId, 1, 1000).then(({ data }) => {
        setUnits(data.data);
      });
      if (service) {
        await getUnitSectors(service.unitId, 1, 1000).then(({ data }) => {
          setSectors(data.data);
        });
        await getEquipments(
          { page: 1, perPage: 1000 },
          {
            clinetId: serviceOrder?.clientId,
            unitId: service?.unitId,
            sectorId: service?.sectorId,
          }
        ).then(({ data }) => {
          setEquipments(data);
        });
      }
    };
    if (isModalOpen) {
      setLoading(true);
      getData().finally(() => setLoading(false));
      form.setFieldsValue(service);
    }
  }, [form, serviceOrder, isModalOpen, service]);

  const handleCloseModal = (refresh = false) => {
    if (loading) {
      return;
    }
    setEquipments([]);
    setUnits([]);
    setSectors([]);
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Serviço ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IService) => {
    if (service) {
      return await api.put<IService>(
        `/service-orders/${serviceOrder?.id}/services/${service.id}`,
        values
      );
    }
    return await api.post<IService>(
      `/service-orders/${serviceOrder?.id}/services`,
      values
    );
  };

  const handleGetEquipments = async () => {
    setSelectLoading(true);
    setEquipments([]);
    await getEquipments(
      { page: 1, perPage: 1000 },
      {
        unitId: form.getFieldValue("unitId"),
        sectorId: form.getFieldValue("sectorId"),
      }
    )
      .then(({ data }) => {
        setEquipments(data);
      })
      .finally(() => setSelectLoading(false));
  };

  const handleChangeUnit = async (unitId: string) => {
    setSectors([]);
    form.setFieldValue("sectorId", null);
    form.setFieldValue("equipmentId", null);
    await handleGetEquipments();
    if (unitId) {
      setSelectLoading(true);
      await getUnitSectors(unitId, 1, 1000)
        .then(({ data }) => setSectors(data.data))
        .finally(() => setSelectLoading(false));
    }
  };

  const handleChangeSector = async () => {
    form.setFieldValue("equipmentId", null);
    await handleGetEquipments();
  };

  return (
    <Modal
      title={`${service ? "Editar" : "Criar"} Serviço`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="service_form_modal">
          <Form.Item
            label="Unidade"
            name="unitId"
            rules={[
              {
                required: serviceOrder?.type === "CONTRACT",
                message: "Por favor selecione o a unidade do Item!",
              },
            ]}
          >
            <Select
              loading={selectLoading}
              optionLabelProp="label"
              optionFilterProp="label"
              showSearch
              allowClear
              onChange={handleChangeUnit}
              options={units.map((unit) => {
                return {
                  value: unit.id,
                  label: unit.name,
                };
              })}
            />
          </Form.Item>
          <Form.Item label="Setor" name="sectorId">
            <Select
              loading={selectLoading}
              onChange={handleChangeSector}
              optionLabelProp="label"
              optionFilterProp="label"
              showSearch
              allowClear
              options={sectors.map((sector) => {
                return { value: sector.id, label: sector.name };
              })}
            />
          </Form.Item>
          {serviceOrder?.isRequiredEquipment && (
            <Form.Item
              label="Equipamento"
              name="equipmentId"
              rules={[
                {
                  required: true,
                  message: "Por favor selecione ao menos um Equipamento!",
                },
              ]}
            >
              <Select
                loading={selectLoading}
                optionLabelProp="label"
                optionFilterProp="label"
                showSearch
                allowClear
                options={equipments.map((equipment) => {
                  return {
                    value: equipment.id,
                    label: equipment.name,
                  };
                })}
              />
            </Form.Item>
          )}
          <Form.Item label="Descrição" name="description">
            <TextArea
              style={{ resize: "none" }}
              rows={5}
              showCount
              maxLength={255}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ServiceModal };
