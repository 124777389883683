import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export function ContractsIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={() => (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 1024 1024"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M917.347 558.556H851.339V132.04C851.339 60.9544 795.486 3.40861 727.785 0.023564H192.948C86.3187 -1.66896 0 88.0348 0 198.049V270.827C0 304.678 28.7729 333.451 62.6234 333.451H172.637V785.354C172.637 844.593 221.721 893.676 282.651 893.676H345.275V979.994C345.275 996.92 353.737 1010.46 368.97 1018.92C375.74 1022.31 382.51 1024 389.28 1024C397.743 1024 406.206 1022.31 412.976 1017.23L490.832 966.454L566.995 1015.54L570.38 1017.23C585.613 1024 600.846 1024 614.386 1015.54C627.926 1007.07 634.696 993.535 634.696 978.302V893.676H917.347C974.893 893.676 1023.98 846.285 1023.98 787.047V663.493C1023.98 605.947 974.893 558.556 917.347 558.556ZM76.1635 198.049C76.1635 130.348 128.632 74.4946 192.948 74.4946H577.15C566.995 94.8048 560.225 118.5 560.225 143.888V257.287H76.1635V198.049ZM489.139 722.731C426.516 722.731 377.433 673.648 377.433 612.717C377.433 551.786 428.208 502.703 489.139 502.703C550.07 502.703 600.846 551.786 600.846 612.717C600.846 673.648 551.763 722.731 489.139 722.731ZM517.912 893.676C500.987 881.828 478.984 881.828 460.366 893.676L419.746 920.756V785.354C441.749 793.817 465.444 798.894 489.139 798.894C514.527 798.894 538.222 793.817 558.533 785.354V920.756L517.912 893.676ZM634.696 817.512V729.501C661.776 697.343 677.009 656.722 677.009 612.717C677.009 509.473 592.383 426.539 489.139 426.539C385.895 426.539 301.269 509.473 301.269 612.717C301.269 656.722 316.502 699.036 343.582 731.194V817.512H280.959C262.341 817.512 247.108 803.972 247.108 785.354V333.451H572.073C605.923 333.451 634.696 304.678 634.696 270.827V145.581C634.696 108.345 660.084 79.5721 693.934 76.1871H719.322C749.788 76.1871 773.483 101.575 773.483 132.04V817.512H634.696ZM947.813 787.047C947.813 803.972 934.273 817.512 917.347 817.512H851.339V634.72H917.347C934.273 634.72 947.813 648.26 947.813 665.185V787.047Z"
          />
        </svg>
      )}
      {...props}
    />
  );
}
