import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  App,
  Button,
  Divider,
  Empty,
  Flex,
  Form,
  Skeleton,
  Typography,
} from "antd";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { api } from "../../../services/api";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { IBrand } from "../../../types";
import { FormField, SortableItem } from "../components/SortableItem";
import { InsertFieldModal } from "../components/InsertFieldModal";
import { ChecklistModal } from "../components/ChecklistModal";
import { ChecklistPreviewModal } from "../components/ChecklistPreviewModal";
import { EditFieldModal } from "../components/EditFieldModal";
import * as Icons from "../../../assets/icons";

import { IconArea } from "./IconArea";
import { DraggableButton } from "./DraggableButton";

const Checklist: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { message } = App.useApp();

  const { Text } = Typography;

  const [formBuilder, setFormBuilder] = useState<Array<FormField>>([]);
  const [checklist, setChecklist] = useState<IBrand>();
  const [editingField, setEditingField] = useState<FormField | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [fieldType, setFieldType] = useState("");
  const [fieldId, setFieldId] = useState("")

  useEffect(() => {
    if (id) {
      const getCheckList = async (): Promise<void> => {
        await api
          .get(`/checklists/${id}`)
          .then(({ data }) => {
            setFormBuilder(data.form.fields);
          })
          .catch(({ response }) => {
            if (response?.data?.message === "E_ROW_NOT_FOUND: Row not found") {
              message.error("CheckList não encotrado.");
            } else {
              message.error("Algo inesperado ocorreu!");
            }
            navigate("/checklists", { replace: true });
          });
      };
      getCheckList();
    }
  }, [id, message, navigate]);

  const onAddField = useCallback((value: any) => {
    const field = {
      id: new Date().getTime().toString(),
      defaultValue: null,
      value: null,
      ...value,
    };
  
    setFormBuilder((values: any) => [...values, field]);
  
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }, [setFormBuilder]);
  
  const onEditField = useCallback((value: any) => {
    const fieldId = new Date().getTime().toString();
  
    if (editingField) {
      setFormBuilder((values) =>
        values.map((field) =>
          field.id === editingField.id ? { ...field, ...value } : field
        )
      );
      setEditingField(null);
    }
  
    const newField = {
      id: fieldId,
      defaultValue: null,
      value: null,
      ...value,
    };
  
    setFormBuilder((values) => [...values, newField]);
  
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: "smooth",
    });
  }, [editingField, setEditingField, setFormBuilder]);
  
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDeleteItem = (id: FormField["id"]) => {
    setFormBuilder((items) => {
      return items.filter((item) => item.id !== id);
    });
  };

  const handleShowModal = (type: string) => {
    setFieldType(type);
    setIsModalOpen(true);
  };

  const handleShowEditModal = useCallback((id: string, type: string) => {
    setFieldId(id);
    setFieldType(type);
    setIsEditModalOpen(true);
  
    const existingField = formBuilder.find((field) => field.id === id);
  
    setEditingField(existingField || null);
  }, [setFieldId, setFieldType, setIsEditModalOpen, setEditingField, formBuilder]);
  

  const handleCloseModal = (refresh?: boolean) => {
    setIsModalOpen(false);
    setIsEditModalOpen(false)
    setFieldType("");
    if (refresh) {
      return navigate(-1);
    }
  };

  const handleOpenPreviewModal = (record: any) => {
    setChecklist(record);
    setIsPreviewModalOpen(true);
  };

  const handleClosePreviewModal = () => {
    setChecklist(undefined);
    setIsPreviewModalOpen(false);
  };
  
  const checklistFields = [
    {
      name: "Title",
      icon: (
        <IconArea>
          <Icons.ChecklistTitleIcon />
        </IconArea>
      ),
      label: "Titulo",
      description: "Insira títulos no formulário.",
    },
    {
      name: "Text",
      icon: (
        <IconArea>
          <Icons.ChecklistDescriptionIcon />
        </IconArea>
      ),
      label: "Descrição",
      description: "Insira descrição no formulário.",
    },
    {
      name: "Input",
      icon: (
        <IconArea>
          <Icons.ChecklistInputIcon />
        </IconArea>
      ),
      label: "Entrada de texto simples",
      description: "Campo para digitar texto curto.",
    },
    {
      name: "TextArea",
      icon: (
        <IconArea>
          <Icons.ChecklistTextareaIcon />
        </IconArea>
      ),
      label: "Entrada de texto multilinha",
      description: "Campo para digitar texto longo.",
    },
    {
      name: "Checkbox",
      icon: (
        <IconArea>
          <Icons.ChecklistCheckboxIcon />
        </IconArea>
      ),
      label: "Checkbox",
      description: "Campo para marcar uma ou mais opções.",
    },
    {
      name: "Switch",
      icon: (
        <IconArea>
          <Icons.ChecklistSwitchIcon />
        </IconArea>
      ),
      label: "Sim / Não",
      description: "Switch de seleção para Sim ou Não.",
    },
    {
      name: "Select",
      icon: (
        <IconArea>
          <Icons.ChecklistSelectIcon />
        </IconArea>
      ),
      label: "Menu de seleção",
      description: "Campo para selecionar opção em lista suspensa.",
    },
    {
      name: "Divider",
      icon: (
        <IconArea>
          <Icons.ChecklistDividerIcon />
        </IconArea>
      ),
      label: "Divisão",
      description: "Adicionar uma linha de divisória.",
    },
    {
      name: "Slider",
      icon: (
        <IconArea>
          <Icons.ChecklistSliderIcon />
        </IconArea>
      ),
      label: "Controle deslizante",
      description: "Campo para selecionar uma faixa de nivel de controle.",
    },
  ];

  const handleChecklistDragEnd = (event: DragEndEvent) => {
    const { active, delta } = event;

    if (active && (delta.x < 0 || delta.y < 0)) {
      const { id: draggedItemId } = active;

      handleShowModal(draggedItemId.toString());
    }
  };

  return (
    <Skeleton loading={false}>
      <ChecklistPreviewModal
        isModalOpen={isPreviewModalOpen}
        data={checklist}
        onClose={handleClosePreviewModal}
      />
      <ChecklistModal
        isModalOpen={isCreateModalOpen}
        onClose={(refresh?: boolean) => {
          setIsCreateModalOpen(false);
          if (refresh) navigate(-1);
        }}
        data={formBuilder}
      />
      <InsertFieldModal
        onClose={handleCloseModal}
        isModalOpen={isModalOpen}
        insert={onAddField}
        data={fieldType}
      />
     <EditFieldModal
        onClose={handleCloseModal}
        isModalOpen={isEditModalOpen}
        insert={onEditField}
        data={fieldType}
        checklistData={formBuilder}
        id={fieldId}
      />
      <PageHeader
        title={id ? "Editar checklist" : "Adicionar checklist"}
        actions={[
            <Button
              type="primary"
              ghost
              onClick={() => handleOpenPreviewModal(formBuilder)}
            >
              Pré-visualizar formulário
            </Button>,
            <Button
              style={{ width: "100%", margin: '0 16px' }}
              type="primary"
              key={"create_item"}
              onClick={() => setIsCreateModalOpen(true)}
            >
              {id ? "Salvar" : "Adicionar"}
            </Button>,
              <Button style={{ width: "100% " }} danger>
              Cancelar
            </Button>
        ]}
        crumb
      />
      <Flex
        style={{
          width: "calc(100% - 48px)",
          gap: 24,
          margin: "24px auto 0",
        }}
      >
        <Content
          width="75%"
          style={{
            backgroundColor: "transparent",
            boxShadow: "none",
            padding: 0,
          }}
        >
          {formBuilder.length === 0 && (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          <Form layout="vertical">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                strategy={verticalListSortingStrategy}
                items={formBuilder.map((item) => item.id)}
              >
                {formBuilder.map((element: FormField) => {
                  return (
                    <SortableItem
                      key={element.id}
                      Element={element}
                      onDeleteItem={handleDeleteItem}
                      handleShowEditModal={handleShowEditModal}
                    />
                  );
                })}
              </SortableContext>
            </DndContext>
          </Form>
        </Content>
        <Content
          width="25%"
          style={{
            height: "100%",
            position: "sticky",
            top: "64px",
            padding: 24,
          }}
        >
          <Text style={{ fontSize: 16, fontWeight: 700, marginLeft: 14 }}>
            Ferramentas do checklist
          </Text>
          <Divider
            style={{
              borderWidth: 2,
              margin: "10px -10px 24px -10px",
              width: "calc(100% + 20px)",
            }}
          />
          <Flex
            style={{
              gap: 24,
              flexDirection: "column",
              marginBottom: 24
            }}
          >
             <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleChecklistDragEnd}>
                <SortableContext
                  strategy={verticalListSortingStrategy} 
                  items={checklistFields.map((item) => item.name)}
                >
                  {checklistFields.map((item) => (
                    <DraggableButton key={item.name} item={item} />
                  ))}
                </SortableContext>
              </DndContext>
          </Flex>
        </Content>
      </Flex>
    </Skeleton>
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setFormBuilder((items) => {
        const oldIndex = items.findIndex((element) => element.id === active.id);
        const newIndex = items.findIndex((element) => element.id === over?.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
};

export { Checklist };
