import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export function TrashIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="16"
          viewBox="0 0 15 16"
          fill="none"
        >
          <path
            d="M13.2929 1.86534H10.8581V1.41871C10.8581 0.630539 10.0991 0 9.15049 0H6.71562C5.76696 0 5.00804 0.630539 5.00804 1.41871V1.86534H2.57317C1.65614 1.86534 0.897217 2.49588 0.897217 3.25778V4.04596C0.897217 4.62395 1.3083 5.09685 1.90911 5.30703L2.41506 14.3973C2.4783 15.2643 3.30047 15.9211 4.34399 15.9211H11.4589C12.5024 15.9211 13.3562 15.238 13.3878 14.3973L13.957 5.28076C14.5578 5.07058 14.9689 4.57141 14.9689 4.01968V3.23151C14.9689 2.49588 14.21 1.86534 13.2929 1.86534ZM6.46264 1.41871C6.46264 1.28735 6.58913 1.18226 6.74724 1.18226H9.18211C9.34022 1.18226 9.46671 1.28735 9.46671 1.41871V1.86534H6.49426V1.41871H6.46264ZM2.35182 3.25778C2.35182 3.15269 2.44668 3.0476 2.60479 3.0476H13.2929C13.4194 3.0476 13.5459 3.12642 13.5459 3.25778V4.04596C13.5459 4.15105 13.451 4.25614 13.2929 4.25614H2.60479C2.4783 4.25614 2.35182 4.17732 2.35182 4.04596V3.25778ZM11.4905 14.7388H4.40723C4.12263 14.7388 3.90128 14.5549 3.90128 14.3448L3.39533 5.4384H12.534L12.0281 14.3448C11.9964 14.5549 11.7751 14.7388 11.4905 14.7388Z"
            fill="#F5222D"
          />
          <path
            d="M7.9488 8.06445C7.56934 8.06445 7.2215 8.32718 7.2215 8.66872V12.0053C7.2215 12.3206 7.53771 12.6096 7.9488 12.6096C8.32826 12.6096 8.6761 12.3469 8.6761 12.0053V8.66872C8.6761 8.32718 8.32826 8.06445 7.9488 8.06445Z"
            fill="#F5222D"
          />
          <path
            d="M10.4786 8.59183C10.0675 8.56556 9.75127 8.80201 9.71965 9.14356L9.52992 11.4555C9.4983 11.7708 9.7829 12.0598 10.194 12.0861C10.2256 12.0861 10.2256 12.0861 10.2572 12.0861C10.6367 12.0861 10.9529 11.8496 10.9529 11.5343L11.1426 9.22237C11.1426 8.88083 10.858 8.61811 10.4786 8.59183Z"
            fill="#F5222D"
          />
          <path
            d="M5.38743 8.59183C5.00797 8.61811 4.69175 8.9071 4.72337 9.22237L4.94473 11.5343C4.97635 11.8496 5.29257 12.0861 5.64041 12.0861C5.67203 12.0861 5.67203 12.0861 5.70365 12.0861C6.08311 12.0598 6.39933 11.7708 6.36771 11.4555L6.14635 9.14356C6.14635 8.80201 5.79851 8.56556 5.38743 8.59183Z"
            fill="#F5222D"
          />
        </svg>
      )}
      {...props}
    />
  );
}
