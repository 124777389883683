import { FC, useEffect } from "react";
import { Col, Form, Input, Select } from "antd";
import { useSearchParams } from "react-router-dom";
import { InputMask } from "../../../components/InputMask";

import { ExpandableFilter } from "../../../components/ExpandableFilter";



interface ClientSeachForm {
  id?: number;
  name?: string;
  identifier: string;
  clientType?: "LEGAL" | "NATURAL";
  ie?: string;
  im?: string;
  isActive?: true;
  createdAt?: string;
}

interface FilterItemListProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const SupplierFilter: FC<FilterItemListProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm<ClientSeachForm>();

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (filter) {
      form.setFieldsValue(filter);
    }
  }, [filter, form]);

  const onFinish = (values: any) => {
    const a = JSON.parse(
      JSON.stringify({
        ...values,
        identifier: values.identifier?.replace(/\D/g, ""),
      })
    );
    setSearchParams(a);
    onSubmit(a);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const fields = [
    <Col span={6} key={1}>
      <Form.Item name={"name"} label={"Nome"}>
        <Input placeholder="Insira o Nome" />
      </Form.Item>
    </Col>,
        <Col span={6} key={2}>
      <Form.Item name={"type"} label={"Tipo"}
      >
        <Select placeholder="Selecione o tipo"
         allowClear
         >
          <Select.Option value="PJ">Pessoa Jurídica</Select.Option>
          <Select.Option value="PF">Pessoa Fisíca</Select.Option>
        </Select>
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item name={"document"} label={"CNPJ/CPF"}>
        <InputMask placeholder="Insira o CNPJ/CPF" />
      </Form.Item>
    </Col>
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { SupplierFilter };
