interface DarkThemeProps {
  color: string
}

export function DarkTheme({ color }: DarkThemeProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
    <circle cx="7.5" cy="7.5" r="7" stroke={color} />
    <path d="M8.04416 3.03359C7.78018 2.9622 7.5162 2.90509 7.23669 2.86226C7.03483 2.83371 6.81743 2.93365 6.73979 3.10498C6.64662 3.27631 6.69321 3.49047 6.84849 3.60469C7.95099 4.547 8.35473 5.93191 7.90441 7.23116C7.40751 8.67318 5.94785 9.68688 4.25527 9.72971C4.03788 9.72971 3.86706 9.85821 3.80495 10.0438C3.74284 10.2294 3.83601 10.4293 4.00682 10.5292C4.39503 10.7434 4.78323 10.9147 5.17144 11.029C5.62176 11.1575 6.0876 11.2146 6.55345 11.2146C7.34539 11.2146 8.12181 11.029 8.82058 10.672C9.92308 10.1152 10.6995 9.21572 11.0411 8.13064C11.7088 5.98902 10.3889 3.74746 8.04416 3.03359ZM10.3734 7.94503C10.0939 8.87306 9.41065 9.62977 8.49448 10.1009C7.53173 10.5864 6.42923 10.6863 5.38883 10.4008C5.28014 10.3722 5.15591 10.3294 5.04721 10.2865C6.69321 9.9867 8.05969 8.90162 8.57212 7.41677C9.02244 6.10324 8.74294 4.71833 7.82677 3.63324C9.81438 4.24717 10.9324 6.14607 10.3734 7.94503Z" fill={color} />
  </svg>
  )
}