import { FC, useState } from "react";

import { App, Button, Form, Input, Modal, Spin } from "antd";

import { api } from "../../../services/api";
import { IServiceOrderHistories, ModalProps } from "../../../types";
import { useServiceOrder } from "../../../contexts/OrderContext";

const { TextArea } = Input;

const HistoryModal: FC<ModalProps<string>> = ({
  onClose,
  isModalOpen,
  data: status,
}) => {
  const { message } = App.useApp();
  const { serviceOrder } = useServiceOrder();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleCloseModal = (refresh = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);

    const values = await form
      .validateFields()
      .then((values) => ({ ...values, status }))
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then(() => {
        message.success(`Histórico criado com sucesso!`);
        handleCloseModal(true);
      })
      .catch(() => message.error("Algo inesperado ocorreu!"))
      .finally(() => setLoading(false));
  };

  const handleSubmitForm = async (values: IServiceOrderHistories) => {
    return await api.post(
      `/service-orders/${serviceOrder?.id}/history`,
      values
    );
  };

  return (
    <Modal
      title={`Adicionar Histórico`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="service_form_modal">
          <Form.Item label="Motivo" name="title">
            <Input />
          </Form.Item>
          <Form.Item label="Descrição/Comentario" name="description">
            <TextArea
              style={{ resize: "none" }}
              rows={5}
              showCount
              maxLength={255}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { HistoryModal };
