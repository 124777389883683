import { RouteObject } from "react-router-dom";
import { Pmocs } from "./list";
import { Pmoc } from "./pmoc";
import { PmocEquipments } from "./pmoc/PmocEquipments";
import { PmocProviderLayout } from "../../contexts/PmocContext";
import { EquipmentActivities } from "./pmoc/EquipmentActivities";
import { EquipmentActivity } from "./pmoc/EquipmentActivity";
import { PmocFiles } from "./pmoc/PmocFiles";
import { api } from "../../services/api";
import { IPmoc } from "../../types";

export const PmocRouter: RouteObject = {
  path: "/pmoc",
  element: <PmocProviderLayout />,
  handle: { crumb: () => "Planejamento" },
  children: [
    {
      index: true,
      element: <Pmocs />,
    },
    {
      path: ":id",
      loader: async ({ params }) => {
        return api.get<IPmoc>(`/pmocs/${params.id}`).then(({ data }) => data);
      },
      handle: { crumb: (data?: IPmoc) => data?.name },
      element: <Pmoc />,
      children: [
        {
          path: "equipments",
          element: <PmocEquipments />,
          handle: { crumb: () => "Equipamentos" },
          children: [
            {
              path: ":equipmentId",
              loader: async ({ params }) => {
                return api
                  .get(`/equipments/${params.equipmentId}`)
                  .then(({ data }) => data);
              },
              handle: { crumb: (data: any) => data?.name },
              element: <EquipmentActivities />,
              children: [
                {
                  path: "activity",
                  handle: { crumb: () => "Atividades" },
                  children: [
                    {
                      path: ":pmocEquipmentId",
                      element: <EquipmentActivity />,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "files",
          handle: { crumb: () => "Arquivos" },
          element: <PmocFiles />,
        },
      ],
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
