export function ChecklistCheckboxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="2" fill="#F0F0F0" />
      <path
        d="M14.6515 8C10.9733 8 8 10.9733 8 14.6515C8 18.3296 10.9733 21.325 14.6515 21.325C18.3296 21.325 21.325 18.3296 21.325 14.6515C21.325 10.9733 18.3296 8 14.6515 8ZM14.6515 20.3339C11.524 20.3339 8.99112 17.779 8.99112 14.6515C8.99112 11.524 11.524 8.99112 14.6515 8.99112C17.779 8.99112 20.3339 11.546 20.3339 14.6735C20.3339 17.779 17.779 20.3339 14.6515 20.3339Z"
        fill="#434343"
      />
      <path
        d="M16.5456 12.6041L13.9247 15.159L12.7353 13.9917C12.5371 13.7935 12.2288 13.8155 12.0305 13.9917C11.8323 14.1899 11.8543 14.4983 12.0305 14.6965L13.4401 16.062C13.5723 16.1942 13.7485 16.2602 13.9247 16.2602C14.1009 16.2602 14.2771 16.1942 14.4092 16.062L17.2504 13.3309C17.4486 13.1327 17.4486 12.8244 17.2504 12.6262C17.0522 12.4279 16.7438 12.4279 16.5456 12.6041Z"
        fill="#434343"
      />
    </svg>
  );
}
