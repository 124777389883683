import { useState, useEffect, useRef, useCallback, FC } from "react";
import { useLocation, useParams } from "react-router-dom";

import { Table, Descriptions, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  CheckCircleTwoTone,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";

import { api } from "../../../services/api";
import { formatters } from "../../../utils";

import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { PreviewGroup } from "../../../components/PreviewGroup";

import { IMeta, IPagination, IPmocEquipmentActivity } from "../../../types";
import { PmocActivityModal } from "../components/PmocActivityModal";
import { FREQUENCIES } from "../../activities/components/ActivityModal";

const { Text } = Typography;

const getEquipmentActivity = async (
  pmocId: string,
  equipmentId: string,
  pmocEquipmentId: string,
  page = 1,
  perPage = 10
) => {
  return await api.get<IPagination<IPmocEquipmentActivity>>(
    `/pmocs/${pmocId}/equipments/${equipmentId}/activities/${pmocEquipmentId}`,
    {
      params: {
        page,
        perPage,
      },
    }
  );
};

const EquipmentActivity: FC = () => {
  const table = useRef<any>();
  const { state } = useLocation();
  const { id, equipmentId, pmocEquipmentId } = useParams();

  const [activities, setActivities] = useState<IPmocEquipmentActivity[]>([]);
  const [activity, setActivity] = useState<IPmocEquipmentActivity>();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowModal = (activity?: IPmocEquipmentActivity) => {
    if (activity) {
      setActivity(activity);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = (refresh?: boolean) => {
    if (refresh) {
      handleRefreshData();
    }
    setIsModalOpen(false);
    setActivity(undefined);
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      getEquipmentActivity(
        String(id),
        String(equipmentId),
        String(pmocEquipmentId),
        page,
        pageSize
      )
        .then(({ data }) => {
          setActivities(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id, equipmentId, pmocEquipmentId]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, id, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IPmocEquipmentActivity> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => {
          handleShowModal(record);
        },
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => {},
      },
    ];
  };

  const columns: ColumnsType<IPmocEquipmentActivity> = [
    {
      title: "Técnico",
      dataIndex: "user",
      key: "user",
      render: (text) => (text ? text.name : "--"),
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (text) => formatters.customDate(text, "MMMM/yyyy").toUpperCase(),
    },
    {
      title: "Imagens",
      dataIndex: "images",
      key: "images",
      render: (_, record) => <PreviewGroup images={record.images} />,
    },
    {
      title: "Status",
      dataIndex: "isDone",
      key: "isDone",
      width: 120,
      render: (isDone) =>
        isDone ? (
          <CheckCircleTwoTone
            style={{
              fontSize: 32,
            }}
            twoToneColor="#52C41A"
          />
        ) : (
          <ExclamationCircleTwoTone
            style={{
              fontSize: 32,
            }}
            twoToneColor="#FF603B"
          />
        ),
    },
    ActionColumn(actions),
  ];

  return (
    <>
      <PmocActivityModal
        isModalOpen={isModalOpen}
        data={activity}
        onClose={handleCloseModal}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Atividade"}
      >
        <Descriptions size="small" column={1}>
          <Descriptions.Item label="Atividade">
            <Text strong>{state?.activity?.name}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Frequencia">
            <Text strong>
              {
                FREQUENCIES.find(
                  (frequency) => frequency.value === state?.frequency
                )?.label
              }
            </Text>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Table
        ref={table}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 12, 24],
          pageSize: meta.per_page,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size="small"
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={activities}
      />
    </>
  );
};

export { EquipmentActivity };
