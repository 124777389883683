import { FC } from "react";
import { Col, Form, Input } from "antd";
import { ExpandableFilter } from "../../../components/ExpandableFilter";

interface FilterBrandListProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

interface FilterBrandFormInstance {
  name?: string;
  units?: string[];
  isConsumible?: boolean;
}

const FilterBrandList: FC<FilterBrandListProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<FilterBrandFormInstance>();

  const fields = [
    <Col span={8} key={1}>
      <Form.Item name={"name"} label={"Nome"}>
        <Input allowClear placeholder="Insira o nome" />
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterBrandFormInstance) => {
    if (onSubmit) {
      onSubmit({ ...values, units: values.units?.toString() });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterBrandList };
