import { useState, useEffect, useCallback, FC } from "react";
import { useParams } from "react-router-dom";

import { Button, Card, Empty, Image, Typography } from "antd";

import { api } from "../../../services/api";
import { IService, IServicePhoto } from "../../../types";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { PlusOutlined } from "@ant-design/icons";
import { PhotoModal } from "../components/PhotoModal";

const getServicePhotos = async (
  orderId: string,
  serviceId: string,
  serviceItemId?: string
) => {
  return await api.get<IService>(
    `/service-orders/${orderId}/services/${serviceId}/photos`,
    {
      params: {
        serviceItemId,
      },
    }
  );
};

const OrderServicePhotos: FC = () => {
  const { id, serviceId, serviceItemId } = useParams();

  const [servicePhotos, setServicePhotos] = useState<IServicePhoto[]>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(false);

  const handleChangePage = useCallback(async () => {
    getServicePhotos(String(id), String(serviceId), serviceItemId)
      .then(({ data }) => {
        setServicePhotos(data.servicePhotos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, serviceId, serviceItemId]);

  useEffect(() => {
    handleChangePage();
  }, [handleChangePage, load]);

  const handleShowModal = (record?: IService) => {
    setIsModalOpen(true);
  };

  const handleCloseModal = (refresh?: boolean) => {
    if (refresh) {
      setLoad(!load);
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Fotos"}
        actions={[
          <Button
            key={"add_photo"}
            type="primary"
            onClick={() => handleShowModal()}
            icon={<PlusOutlined />}
          >
            Adicionar Foto
          </Button>,
        ]}
      />
      <PhotoModal isModalOpen={isModalOpen} onClose={handleCloseModal} />
      <Content
        style={{
          padding: 0,
        }}
      >
        {servicePhotos?.length === 0 && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
        <Image.PreviewGroup
          preview={{
            maxScale: 4,
          }}
        >
          <div style={{ display: "flex", gap: 16 }}>
            {servicePhotos?.map((servicePhoto) => {
              return (
                <Card
                  key={servicePhoto.id}
                  hoverable
                  style={{ width: 300 }}
                  cover={
                    <Image
                      height={250}
                      style={{ objectFit: "cover" }}
                      src={process.env.REACT_APP_ENDPOINT + servicePhoto.photo}
                    />
                  }
                >
                  <Typography.Paragraph
                    strong
                    ellipsis={{ rows: 2, expandable: true, symbol: "mais" }}
                  >
                    {servicePhoto.description}
                  </Typography.Paragraph>
                </Card>
              );
            })}
          </div>
        </Image.PreviewGroup>
      </Content>
    </>
  );
};

export { OrderServicePhotos };
