import {
  IActivity,
  IBrand,
  IClient,
  IContract,
  IEquipment,
  IItem,
  ILocal,
  IPagination,
  IProduct,
  IService,
  IServiceOrder,
  ISupplier,
} from "../types";
import { api } from "./api";

interface IParams {
  page?: number;
  perPage?: number;
  [key: string]: any;
}

export async function getOrders(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IServiceOrder>>(
    `/service-orders`,
    {
      params: {
        page: params.page,
        perPage: params.perPage,
        ...filter,
      },
    }
  );

  return { data: data.data, meta: data.meta };
}

export async function getServices(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IService>>(
    `/service-orders/${params.id}/services`,
    {
      params: {
        page: params.page,
        perPage: params.perPage,
        ...filter,
      },
    }
  );
  return { data: data.data, meta: data.meta };
}

export async function getServiceActivities(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IItem>>(
    `/service-orders/${params.orderId}/services/${params.serviceId}/activities`,
    {
      params: {
        page: params.page,
        perPage: params.perPage,
        ...filter,
      },
    }
  );

  return { data: data.data, meta: data.meta };
}

export async function getServiceItems(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IItem>>(
    `/service-orders/${params.orderId}/services/${params.serviceId}/items`,
    {
      params: {
        page: params.page,
        perPage: params.perPage,
        ...filter,
      },
    }
  );

  return { data: data.data, meta: data.meta };
}

export async function getBrands(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IBrand>>("brands", {
    params: {
      page: params.page,
      perPage: params.perPage,
      ...filter,
    },
  });

  return { data: data.data, meta: data.meta };
}

export async function getClients(page = 1, perPage = 10, q?: any) {
  const { data } = await api.get<IPagination<IClient>>(`/clients`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });

  return { data: data.data, meta: data.meta };
}

export async function getSuppliers(page = 1, perPage = 10, q?: any) {
  const { data } = await api.get<IPagination<ISupplier>>(`/suppliers`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });

  return { data: data.data, meta: data.meta };
}

export async function getFinancial(page = 1, perPage = 10, q?: any) {
  const { data } = await api.get(`/financials`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });

  return { data: data.data, meta: data.meta };
}

export async function getItems(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IItem>>(`/items`, {
    params: {
      page: params.page,
      perPage: params.perPage,
      ...filter,
    },
  });

  return { data: data.data, meta: data.meta };
}

export async function getProducts(page = 1, perPage = 10, q?: any) {
  const { data } = await api.get<IPagination<IProduct>>(`/products`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });

  return { data: data.data, meta: data.meta };
}

export async function getContracts(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IContract>>(`/contracts`, {
    params: {
      page: params.page,
      perPage: params.perPage,
      ...filter,
    },
  });
  return { data: data.data, meta: data.meta };
}

export async function getContractItems(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IItem>>(
    `/contracts/${params.id}/items`,
    {
      params: {
        page: params.page,
        perPage: params.perPage,
        ...filter,
      },
    }
  );
  return { data: data.data, meta: data.meta };
}

export async function getContractProducts(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IProduct>>(
    `/contracts/${params.id}/products`,
    {
      params: {
        page: params.page,
        perPage: params.perPage,
        ...filter,
      },
    }
  );
  return { data: data.data, meta: data.meta };
}

export async function getContractLocals(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<ILocal>>(
    `/contracts/${params.id}/locals`,
    {
      params: {
        page: params.page,
        perPage: params.perPage,
        ...filter,
      },
    }
  );
  return { data: data.data, meta: data.meta };
}

export async function getActivities(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IActivity>>(`/activities`, {
    params: {
      page: params.page,
      perPage: params.perPage,
      ...filter,
    },
  });

  return { data: data.data, meta: data.meta };
}

export async function getEquipments(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<IEquipment>>(`/equipments`, {
    params: {
      page: params.page,
      perPage: params.perPage,
      clientId: params.id,
      ...filter,
    },
  });

  return { data: data.data, meta: data.meta };
}

export async function getLocals(params: IParams, filter?: any) {
  const { data } = await api.get<IPagination<ILocal>>(`/locals`, {
    params: {
      page: params.page,
      perPage: params.perPage,
      clientId: params?.id || filter?.clientId,
      ...filter,
    },
  });

  return { data: data.data, meta: data.meta };
}
