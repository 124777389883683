import { FC } from "react";
import { Col, Form, Input, Radio } from "antd";
import { ExpandableFilter } from "../../../components/ExpandableFilter";

interface FilterItemListProps {
  onSubmit?: any;
}

interface FilterItemFormInstance {
  name?: string;
  units?: string[];
  isConsumible?: boolean;
}

const FilterItemList: FC<FilterItemListProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<FilterItemFormInstance>();

  const fields = [
    <Col span={9} key={1}>
      <Form.Item name={"name"} label={"Nome"}>
        <Input allowClear placeholder="Insira o nome" />
      </Form.Item>
    </Col>,
    <Col span={9} key={3}>
      <Form.Item name="isConsumible" label="Consumível">
        <Radio.Group>
          <Radio value={true}>Sim</Radio>
          <Radio value={false}>Não</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterItemFormInstance) => {
    if (onSubmit) {
      onSubmit({ ...values });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterItemList };
