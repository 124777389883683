import { Input } from "antd";
import ReactInputMask from "react-input-mask";
import PropTypes from "prop-types";
import { useCallback, useState } from "react";

const InputMask = (props: any) => {
  const [mask, setMask] = useState<string>("999.999.999-99");

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (props.onChange) {
        props.onChange(event);
      }

      const { value } = event.target;

      if (value.length <= 14) {
        setMask("999.999.999-999");
      } else {
        setMask("99.999.999/9999-99");
      }
    },
    [props]
  );

  return (
    <ReactInputMask
      {...props}
      mask={mask}
      onChange={handleChange}
      maskChar={false}
    >
      {(inputProps: any) => (
        <Input
          {...inputProps}
          disabled={props.disabled ? props.disabled : null}
          placeholder={props.placeholder}
        />
      )}
    </ReactInputMask>
  );
};

InputMask.propTypes = {
  value: PropTypes.string,
  mask: PropTypes.string,
  inputRef: PropTypes.func,
  placeholder: PropTypes.string,
};

export { InputMask };
