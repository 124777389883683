export function ChecklistSwitchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="2" fill="#F0F0F0" />
      <path
        d="M18.6786 15.4016C18.9003 15.4016 19.1128 15.3067 19.2695 15.138C19.4263 14.9692 19.5143 14.7403 19.5143 14.5016C19.5143 14.2629 19.4263 14.0339 19.2695 13.8652C19.1128 13.6964 18.9003 13.6016 18.6786 13.6016C18.457 13.6016 18.2444 13.6964 18.0877 13.8652C17.9309 14.0339 17.8429 14.2629 17.8429 14.5016C17.8429 14.7403 17.9309 14.9692 18.0877 15.138C18.2444 15.3067 18.457 15.4016 18.6786 15.4016Z"
        fill="#434343"
        stroke="#434343"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6786 19H10.3215C9.21323 19 8.15039 18.5259 7.36676 17.682C6.58312 16.8381 6.14288 15.6935 6.14288 14.5C6.14288 13.3065 6.58312 12.1619 7.36676 11.318C8.15039 10.4741 9.21323 10 10.3215 10H18.6786C19.7868 10 20.8497 10.4741 21.6333 11.318C22.4169 12.1619 22.8572 13.3065 22.8572 14.5C22.8572 15.6935 22.4169 16.8381 21.6333 17.682C20.8497 18.5259 19.7868 19 18.6786 19Z"
        stroke="#434343"
      />
    </svg>
  );
}
