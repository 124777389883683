import { useState, useEffect, FC } from "react";
import { Outlet, useParams } from "react-router-dom";

import { Descriptions, Typography } from "antd";

import { api } from "../../../services/api";
import { IService } from "../../../types";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { AppstoreOutlined, UnorderedListOutlined } from "@ant-design/icons";

const { Text } = Typography;

const getServiceById = async (orderId: string, serviceId: string) => {
  return await api.get<IService>(
    `/service-orders/${orderId}/services/${serviceId}`
  );
};

const OrderService: FC = () => {
  const { id, serviceId } = useParams();

  const [service, setService] = useState<IService>();

  useEffect(() => {
    getServiceById(id!, serviceId!)
      .then(({ data }) => {
        setService(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, serviceId]);

  return (
    <Content width="calc(100% - 48px)">
      <PageHeader
        backButton
        style={{ boxShadow: "none", padding: 0 }}
        title={"Serviço"}
        menuItems={[
          {
            label: "Itens",
            key: "items",
            icon: <UnorderedListOutlined />,
          },
          {
            label: "Produtos",
            key: "products",
            icon: <AppstoreOutlined />,
          },
          {
            label: "Atividades",
            key: "activities",
            icon: <AppstoreOutlined />,
          },
        ]}
        crumb
      >
        <Descriptions size="small" style={{ marginBottom: "16px" }} column={1}>
          <Descriptions.Item label="ID">
            <Text strong>{service?.id}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Descrição do serviço">
            <Text strong>{service?.description}</Text>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <div style={{ marginTop: "16px" }}>
        <Outlet />
      </div>
    </Content>
  );
};

export { OrderService };
