import React, { useState } from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import { Descriptions, Typography } from "antd";
import {
  ReconciliationOutlined,
} from "@ant-design/icons";


import { ISupplier } from "../../../types";
import { formatters } from "../../../utils/formatters";
import { MenuItem, PageHeader } from "../../../components/PageHeader";

const { Text } = Typography;

const Supplier: React.FC = () => {

  const data = useLoaderData() as ISupplier;

  const [supplier] = useState<ISupplier>(data);

  console.log(supplier)

  const items: MenuItem[] = [
    {
      label: "Contas a pagar",
      key: "financial",
      icon: <ReconciliationOutlined />,
    },
  ];

  return (
    <>
      <PageHeader title={supplier?.name?.toUpperCase()} menuItems={items} crumb>
        {supplier.type === 'PJ' &&
        <Descriptions size="small">

          <Descriptions.Item label="Nome fantasia" span={3}>
            <Text strong>{supplier?.fantasy_name}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Razão Social">
            <Text strong>{supplier?.corporate_name}</Text>
          </Descriptions.Item>

          <Descriptions.Item label="Email">
            <Text strong>{supplier.emails[0].email}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Inscrição municipal">
            <Text strong>{supplier.municipal_registration}</Text>
          </Descriptions.Item>

          <Descriptions.Item
            label={supplier?.type === "PJ" ? "CNPJ" : "CPF"}
          >
            <Text strong>{formatters.identifier(supplier?.document)}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Telefone">
            <Text strong>{supplier.phones[0].phone}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Inscrição estadual">
            <Text strong>{supplier.state_registration}</Text>
          </Descriptions.Item>

        </Descriptions>
        }
       {supplier.type === 'PF' && 
        <Descriptions size="small" column={2}>
           <Descriptions.Item label="Nome">
            <Text strong>{supplier?.name}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Email">
            <Text strong>{supplier?.emails[0]?.email}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="CPF">
            <Text strong>{supplier?.document}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Telefone">
            <Text strong>{supplier?.phones[0]?.phone}</Text>
          </Descriptions.Item>
        </Descriptions>
       }
      </PageHeader>
      <div style={{ marginTop: "16px" }}>
        <Outlet />
      </div>
    </>
  );
};

export { Supplier };
