import { ReactNode, FC, CSSProperties } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button, Menu, MenuProps, Space, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { Content } from "../Content";
import { Breadcrumbs } from "../Breadcrumbs";

const { Title } = Typography;

export type MenuItem = Required<MenuProps>["items"][number];

interface PageHeaderProps {
  title?: string;
  backButton?: boolean;
  children?: ReactNode;
  actions?: ReactNode[];
  footer?: ReactNode[];
  menuItems?: MenuItem[];
  style?: CSSProperties;
  crumb?: boolean;
  width?: string;
}

const PageHeader: FC<PageHeaderProps> = ({
  title,
  backButton = true,
  children,
  footer,
  actions,
  menuItems,
  style,
  width,
  crumb = false,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const activeItems = () => {
    let keys: string[] = [];
    for (let i of pathname.slice(1).split("/")) {
      if (
        !i.match(
          "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
        )
      )
        keys.push(i);
    }

    return keys;
  };

  const onClick: MenuProps["onClick"] = ({ key }) => {
    navigate(key, {
      replace: true,
    });
  };

  return (
    <Content
      width={width}
      style={{
        padding: footer || menuItems ? "10px 10px 0" : "10px 10px",
        ...style,
      }}
      margin
    >
      <Space
        align="center"
        direction="horizontal"
        style={{
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Space
          align="center"
          style={{
            marginBottom: crumb ? "8px" : "",
          }}
        >
          {backButton ? (
            <Button
              type="text"
              onClick={() => {
                navigate(-1);
              }}
              size="large"
              icon={<ArrowLeftOutlined />}
            />
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 0,
            }}
          >
            {crumb && <Breadcrumbs />}
            <Title level={5} style={{ margin: 0 }}>
              {title}
            </Title>
          </div>
        </Space>
        <div style={{ display: "flex" }}>{actions?.map((item) => item)}</div>
      </Space>
      {children}
      {(footer || menuItems) && (
        <Space
          direction="horizontal"
          style={{ width: "100%", justifyContent: "space-between", padding: 0 }}
        >
          <Menu
            key={"menu"}
            mode="horizontal"
            style={{ minWidth: "35rem" }}
            onClick={onClick}
            items={menuItems}
            selectedKeys={activeItems()}
          />
          {footer?.map((item) => item)}
        </Space>
      )}
    </Content>
  );
};

export { PageHeader };
