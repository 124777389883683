import { FC } from "react";
import { Col, Form, Input, Radio, Select } from "antd";
import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { unitTypes } from "./EquipmentModal";

const { Option } = Select;

interface FilterItemListProps {
  onSubmit?: any;
}

interface FilterItemFormInstance {
  name?: string;
  units?: string[];
  isConsumible?: boolean;
}

const FilterItemList: FC<FilterItemListProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<FilterItemFormInstance>();

  const fields = [
    <Col span={6} key={1}>
      <Form.Item name={"name"} label={"Nome"}>
        <Input allowClear placeholder="Insira um nome" />
      </Form.Item>
    </Col>,
    <Col span={7} key={2}>
      <Form.Item name={"units"} label={"Unidade"}>
        <Select
          placeholder="Selecione uma unidade"
          allowClear
          showSearch
          optionFilterProp="children"
          mode="multiple"
        >
          {unitTypes.map((unitType) => {
            return (
              <Option key={unitType.value} value={unitType.value}>
                {unitType.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>,
    <Col span={5} key={3}>
      <Form.Item name="isConsumible" label="Consumível">
        <Radio.Group>
          <Radio value={true}>Sim</Radio>
          <Radio value={false}>Não</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterItemFormInstance) => {
    if (onSubmit) {
      onSubmit({ ...values, units: values.units?.toString() });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterItemList };
