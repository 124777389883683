import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { App, Button } from "antd";
import { ColumnsType } from "antd/es/table";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { getSuppliers } from "../../../services/repositories";
import { ISupplier } from "../../../types";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { formatters } from "../../../utils/formatters";
import { usePagination } from "../../../hooks/usePaginations";
import { useModal } from "../../../hooks/useModal";
import { ActionTable } from "../../../components/ActionTable";
import { SupplierFilter } from "../components/SupplierFilter";
import { CreateSupplierModal } from "../components/CreateSupplierModal";
import { api } from "../../../services/api";

const Suppliers: FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const navigate = useNavigate();
  const {
    data: suppliers,
    isLoading,
    filter,
    setFilter,
    handleRefreshData,
    pagination,
  } = usePagination(getSuppliers);

  const {
    data: supplier,
    isOpen,
    handleCloseModal,
    handleOpenModal,
  } = useModal<ISupplier>(handleRefreshData);

  const { modal, message } = App.useApp();

  const onDeleteSupplier = async (record: ISupplier) => {
    return modal.confirm({
      title: "Deseja deletar esse fornecedor?",
      content: (
        <>
        O fornecedor será excluído permanentemente!
        </>
      ),
      okText: "Deletar",
      okButtonProps: { 
        type: "primary",
        danger: true
      },
      cancelText: "Cancelar",
      cancelButtonProps: { 
        type: "default",
         danger: true
      },
      onOk: async () =>
      {
        await api.delete(`/suppliers/${record.id}`)
        .catch(() => message.error('Ops! Algo deu errado!')).then((handleRefreshData))
      },
    });
  };

  const actions: ActionFunction<ISupplier> = (record) => {
    return [
      {
        key: "1",
        icon: <SearchOutlined />,
        label: "Detalhar",
        // onClick: () => navigate(`${record.id}`),
      },
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteSupplier(record),
      },
    ];
  };

  const supplierType = (type: string) => {
    if (type === 'PJ') {
      return 'Pessoa jurídica';
    }
    return 'Pessoa física';
}
  const columns: ColumnsType<ISupplier> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
      width: 80,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (_, record) => {
        const actualName = record.type === 'PJ' ? record.fantasy_name : record.name;
        return <span>{actualName}</span>;
      },
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (type: string) => supplierType(type),
    },
    {
      title: "CPF | CNPJ",
      dataIndex: "document",
      key: "document",
      render: (text: string) => formatters.identifier(text),
    },
  ];

  return (
    <>
     <CreateSupplierModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        data={supplier}
      />
      <PageHeader
        title="Fornecedores"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            key={"create_item"}
            onClick={() => handleOpenModal()}
            type="primary"
          >
             Fornecedor
          </Button>,
        ]}
        crumb
      />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
      <SupplierFilter onSubmit={(values: any) => {
            setFilter(values);
          }}
        filter={filter} 
      />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          loading={isLoading}
          columns={columns}
          dataSource={suppliers}
        />
      </Content>
    </>
  );
};

export { Suppliers };