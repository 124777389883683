import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export function PmocIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={() => (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 766 1024"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M660.7 0H106.455C49.0033 0 0 47.3135 0 106.455V917.545C0 974.997 47.3135 1024 106.455 1024H659.01C716.462 1024 765.465 976.686 765.465 917.545V104.766C765.465 47.3135 718.152 0 660.7 0ZM689.426 915.855C689.426 932.753 675.908 946.271 659.01 946.271H106.455C89.5577 946.271 76.0396 932.753 76.0396 915.855V104.766C76.0396 87.868 89.5577 74.3498 106.455 74.3498H659.01C675.908 74.3498 689.426 87.868 689.426 104.766V915.855Z" />
          <path d="M555.942 212.911H292.338V207.842C292.338 187.565 275.44 168.977 253.473 168.977C231.506 168.977 214.608 185.875 214.608 207.842V212.911H209.539C189.262 212.911 170.674 229.809 170.674 251.776C170.674 273.743 187.572 290.64 209.539 290.64H214.608V295.71C214.608 315.987 231.506 334.574 253.473 334.574C275.44 334.574 292.338 317.677 292.338 295.71V290.64H555.942C576.219 290.64 594.806 273.743 594.806 251.776C594.806 229.809 576.219 212.911 555.942 212.911Z" />
          <path d="M555.931 473.128H550.861V468.059C550.861 447.782 533.964 429.194 511.997 429.194C490.03 429.194 473.132 446.092 473.132 468.059V473.128H211.218C190.94 473.128 172.353 490.026 172.353 511.993C172.353 532.27 189.251 550.857 211.218 550.857H474.822V555.927C474.822 576.204 491.719 594.791 513.686 594.791C535.653 594.791 552.551 577.894 552.551 555.927V550.857H557.62C577.898 550.857 596.485 533.96 596.485 511.993C593.105 490.026 576.208 473.128 555.931 473.128Z" />
          <path d="M555.942 731.685H292.338V726.616C292.338 706.338 275.44 687.751 253.473 687.751C231.506 687.751 214.608 704.649 214.608 726.616V731.685H209.539C189.262 731.685 170.674 748.583 170.674 770.55C170.674 792.517 187.572 809.414 209.539 809.414H214.608V814.484C214.608 834.761 231.506 853.348 253.473 853.348C275.44 853.348 292.338 836.451 292.338 814.484V809.414H555.942C576.219 809.414 594.806 792.517 594.806 770.55C594.806 748.583 576.219 731.685 555.942 731.685Z" />
        </svg>
      )}
      {...props}
    />
  );
}
