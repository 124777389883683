import { useState, useCallback, FC, useEffect } from "react";
import { useLoaderData } from "react-router-dom";

import { App } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { IMeta, IPmoc } from "../../../types";
import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { formatters } from "../../../utils/formatters";
import { FilterOrderList } from "./FilterOrderList";
import { reports } from "../../../services/reports";
import { getPmocs } from "../../pmocs/list";
import { ActionTable } from "../../../components/ActionTable";
import { ActionFunction } from "../../../components/ActionColumn";

const PmocReport: FC = () => {
  const { message } = App.useApp();
  const params = useLoaderData();

  const [orders, setOrders] = useState<IPmoc[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState<any>(params);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getPmocs(page, pageSize, filter)
        .then(({ data }) => {
          setOrders(data.data);
          setMeta(data.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filter]
  );

  useEffect(() => {
    if (filter?.clientId) {
      handleChangePage(1, meta.per_page);
    }
  }, [filter, meta.per_page, handleChangePage]);

  const columns: ColumnsType<IPmoc> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 300,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: ({ name }) => name,
    },
    {
      title: "Técnico Resp.",
      dataIndex: "users",
      key: "profile",
      render: (users) =>
        users.find((user: any) => user.profile === "ENGINEER").name,
    },
    {
      title: "Técnico do PMOC",
      dataIndex: "users",
      key: "profilePmoc",
      render: (users) =>
        users.find((user: any) => user.profile === "TECHNICIAN").name,
    },
    {
      title: "Início",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => formatters.simpleDate(date),
    },
    {
      title: "Fim",
      dataIndex: "endDate",
      key: "endDate",
      render: (date) => formatters.simpleDate(date),
    },
  ];

  const actions: ActionFunction<IPmoc> = (record) => {
    return [
      {
        key: "1",
        icon: <PrinterOutlined />,
        label: "Imprimir PMOC",
        onClick: () => {
          onPrint(record.id);
        },
      },
    ];
  };

  const onPrint = (id: string) => {
    if (filter && orders.length > 0) {
      reports("pmocs", { ...filter, id });
      return;
    }
    if (filter && orders.length === 0) {
      message.info("Não há registros para impressão.");
      return;
    }
    message.error("Você precisa filtar as informações!");
  };

  return (
    <>
      <PageHeader title="Relatório de PMOC" backButton={false} crumb />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
        <FilterOrderList
          onSubmit={(values: any) => {
            setFilter(values);
          }}
          filter={filter}
        />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          actions={actions}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20],
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size="small"
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={orders}
        />
      </Content>
    </>
  );
};

export { PmocReport };
