import { useMemo, useRef, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  Empty,
  Select as ASelect,
  SelectProps,
  theme,
} from "antd";
import { IPagination } from "../../types";
import { debounce } from "lodash";

const defaultEmpty = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
const loadingEmpty = () => (
  <div style={{ textAlign: "center", height: "100%" }}>
    <LoadingOutlined style={{ fontSize: 20 }} />
  </div>
);

interface CustomSelectProps<T = unknown> extends Omit<SelectProps, "options"> {
  handleLoadData?: (...args: any) => Promise<IPagination<T>>;
  options?: T[];
  debounceTimeout?: number;
}

const Select = <T,>({
  options,
  handleLoadData,
  debounceTimeout = 800,
  ...props
}: CustomSelectProps<T>) => {
  const {
    token: { colorTextDescription },
  } = theme.useToken();

  const [data, setData] = useState<any[]>(options || []);
  const [isLoading, setIsLoading] = useState(false);

  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      if (handleLoadData) {
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setIsLoading(true);
        handleLoadData(1, 100, value ? { name: value } : null).then(
          ({ data }) => {
            if (fetchId !== fetchRef.current) {
              return;
            }

            setData(data);
            setIsLoading(false);
          }
        );
      }
    };
    return debounce(loadOptions, debounceTimeout);
  }, [handleLoadData, debounceTimeout]);

  async function handleOnFocus() {
    if (handleLoadData) {
      if (data.length === 0) {
        setIsLoading(true);
        handleLoadData(1, 100)
          .then(({ data }) => setData(data))
          .catch((err) => console.log(err))
          .finally(() => setIsLoading(false));
      }
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextDisabled: colorTextDescription,
          },
        },
      }}
      renderEmpty={isLoading ? loadingEmpty : defaultEmpty}
    >
      <ASelect
        {...props}
        loading={isLoading || props.loading}
        optionLabelProp="label"
        optionFilterProp="label"
        showSearch
        allowClear
        onFocus={handleOnFocus}
        onSearch={debounceFetcher}
        options={data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })}
      />
    </ConfigProvider>
  );
};

export { Select };
