import { RouteObject } from "react-router-dom";
import { Login } from "./login";
import { CreateAccount } from "./createAccount";
import { ForgortPassword } from "./forgotPassword";
import { loader } from "../../utils/loader";
import { RecoverPassword } from "./recoverPassword";

export const AuthRouter: RouteObject = {
  children: [
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/create-account",
      loader: loader,
      element: <CreateAccount />,
    },
    {
      path: "/forgot-password",
      loader: loader,
      element: <ForgortPassword />,
    },
    {
      path: "/recover-password",
      loader: loader,
      element: <RecoverPassword />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
