export function PDF() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon"
      style={{ width: "2rem", height: "2rem", verticalAlign: "middle" }}
      fill="currentColor"
      overflow="hidden"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="#EA4318"
        d="M192 384h640a42.667 42.667 0 0142.667 42.667v362.666A42.667 42.667 0 01832 832H192v106.667A21.333 21.333 0 00213.333 960h725.334A21.333 21.333 0 00960 938.667V308.82l-10.09-10.154H823.38a98.048 98.048 0 01-98.048-98.048V72.66L716.715 64H213.333A21.333 21.333 0 00192 85.333V384zm-64 448H42.667A42.667 42.667 0 010 789.333V426.667A42.667 42.667 0 0142.667 384H128V85.333A85.333 85.333 0 01213.333 0H743.36L1024 282.453v656.214A85.333 85.333 0 01938.667 1024H213.333A85.333 85.333 0 01128 938.667V832zM88.47 472.49v274.177h44.927V641.45h67.968c66.816 0 100.224-28.416 100.224-84.864 0-56.064-33.408-84.096-99.456-84.096H88.47zm44.927 38.4h65.28c19.584 0 34.176 3.457 43.392 10.753 9.216 6.912 14.208 18.432 14.208 34.944 0 16.512-4.608 28.416-13.824 35.712-9.216 6.912-23.808 10.752-43.776 10.752h-65.28v-92.16zm206.592-38.4v274.177h100.224c44.544 0 77.952-12.288 100.992-36.864 21.888-23.424 33.024-56.832 33.024-100.224 0-43.776-11.136-77.184-33.024-100.224-23.04-24.576-56.448-36.864-100.992-36.864H339.99zm44.928 38.4h46.848c34.176 0 59.136 7.68 74.88 23.425 15.36 15.36 23.04 40.704 23.04 75.264 0 33.792-7.68 58.752-23.04 74.88-15.744 15.744-40.704 23.808-74.88 23.808h-46.848V510.89zm231.552-38.4v274.177h44.928V625.323h134.016v-38.4H661.397V510.89h142.08v-38.4H616.47z"
      ></path>
    </svg>
  );
}
