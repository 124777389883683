import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { AuthProvider } from "../contexts/AuthContext";

import { Layout } from "./layout";
import { RequireAuth } from "./requireAuth";

import { ActivityRouter } from "../pages/activities/activities.routes";
import { BrandRouter } from "../pages/brands/brands.routes";
import { AuthRouter } from "../pages/auth/auth.routes";
import { ClientRouter } from "../pages/clients/client.routes";
import { SupplierRouter } from "../pages/suppliers/suppliers.routes";
import { ContractRouter } from "../pages/contracts/contracts.routes";
import { DashboardRouter } from "../pages/dashboard/dashboard.routes";
import { EquipmentRouter } from "../pages/equipments/equipments.routes";
import { EquipmentTypeRouter } from "../pages/equipmentTypes/equipmentTypes.routes";
import { ItemRouter } from "../pages/items/items.routes";
import { OrderRouter } from "../pages/orders/orders.routes";
import { ServiceTypeRouter } from "../pages/serviceTypes/serviceTypes.routes";
import { UserRouter } from "../pages/users/users.routes";
import { ProductRouter } from "../pages/products/products.routes";
import { PmocRouter } from "../pages/pmocs/pmocs.routes";

import { NoMatch } from "./noMatchRoute";
import { OrganizationRouter } from "../pages/organization/organization.routes";
import { ReportsRouter } from "../pages/reports/reports.routes";
import { ChecklistRouter } from "../pages/checklist/checklist.routes";
import { api, TOKEN_API } from "../services/api";
import { IUser } from "../types";

const router = createBrowserRouter([
  {
    element: <AuthProvider />,
    loader: async () => {
      const storageToken = localStorage.getItem(TOKEN_API);
      if (storageToken) {
        api.defaults.headers.common["Authorization"] = `Bearer ${storageToken}`;
        const { data } = await api.get<IUser>("/me");

        return data;
      }
      return null;
    },
    children: [
      AuthRouter,
      {
        element: <RequireAuth />,
        children: [
          {
            element: <Layout />,
            children: [
              ActivityRouter,
              OrderRouter,
              ChecklistRouter,
              ClientRouter,
              ContractRouter,
              DashboardRouter,
              ItemRouter,
              BrandRouter,
              ServiceTypeRouter,
              EquipmentTypeRouter,
              UserRouter,
              ProductRouter,
              PmocRouter,
              EquipmentRouter,
              OrganizationRouter,
              ReportsRouter,
              SupplierRouter
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NoMatch to="/dashboard" />,
      },
    ],
  },
]);

const Routes = () => {
  return <RouterProvider router={router} />;
};

export { Routes, router };
