export function JPG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon"
      style={{ width: "2rem", height: "2rem", verticalAlign: "middle" }}
      fill="currentColor"
      overflow="hidden"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="#3DB6FB"
        d="M192 384h640a42.667 42.667 0 0142.667 42.667v362.666A42.667 42.667 0 01832 832H192v106.667A21.333 21.333 0 00213.333 960h725.334A21.333 21.333 0 00960 938.667V308.82l-10.09-10.154H823.38a98.048 98.048 0 01-98.048-98.048V72.66L716.715 64H213.333A21.333 21.333 0 00192 85.333V384zm-64 448H42.667A42.667 42.667 0 010 789.333V426.667A42.667 42.667 0 0142.667 384H128V85.333A85.333 85.333 0 01213.333 0H743.36L1024 282.453v656.214A85.333 85.333 0 01938.667 1024H213.333A85.333 85.333 0 01128 938.667V832zm83.05-359.51v183.937c0 19.584-3.349 33.792-10.026 42.624-6.72 8.832-17.493 13.44-32 13.44-26.453 0-39.467-15.744-39.467-47.232v-8.448h-43.52v8.832c0 26.88 7.04 48 21.206 63.36 13.76 15.36 34.218 23.04 61.013 23.04 30.89 0 53.227-8.064 67.37-24.192 12.651-15.36 18.987-38.4 18.987-69.888V472.49H211.03zm95.66 0v274.177h43.52V641.45h65.877c64.768 0 97.13-28.416 97.13-84.864 0-56.064-32.362-84.096-96.384-84.096H306.71zm43.52 38.4h63.274c18.987 0 33.13 3.457 42.048 10.753 8.939 6.912 13.781 18.432 13.781 34.944 0 16.512-4.48 28.416-13.397 35.712-8.939 6.912-23.083 10.752-42.432 10.752h-63.253v-92.16zm315.583-43.775c-39.445 0-70.698 13.824-93.781 42.24-21.952 26.496-32.747 60.288-32.747 101.376 0 40.704 10.795 74.112 32.747 99.84 23.467 27.648 56.192 41.472 98.624 41.472 23.808 0 45.013-3.072 64.384-9.216 18.24-6.144 34.24-14.592 48.384-26.112V600.747H669.547v38.4h70.336v56.448c-8.939 5.376-18.24 9.6-28.672 12.672a134.144 134.144 0 01-35.712 4.608c-32.384 0-56.192-9.216-71.467-27.648-14.123-17.28-21.205-42.24-21.205-74.496 0-33.792 7.829-59.52 23.829-77.952 14.507-17.664 34.24-26.112 59.157-26.112 20.096 0 36.48 4.224 48.747 13.056 11.925 8.832 20.096 21.888 23.83 39.552h43.903c-4.821-30.72-16.746-53.376-35.712-68.736-19.733-15.744-46.506-23.424-80.768-23.424z"
      ></path>
    </svg>
  );
}
