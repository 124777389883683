import { FC, useEffect, useState } from "react";

import { Button } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { ActionFunction } from "../../../components/ActionColumn";
import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";

import { api } from "../../../services/api";

import { IEquipmentType, IMeta, IPagination } from "../../../types";
import { EquipmentTypeModal } from "../components/EquipmentTypeModal";
import { FilterEquipmentTypeList } from "../components/FilterEquipmentTypeList";
import { ActionTable } from "../../../components/ActionTable";

export const getEquipmentTypes = async (page = 1, perPage = 10, q?: any) => {
  return await api.get<IPagination<IEquipmentType>>(`/equipment-types`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const deleteEquipmentType = async (equipmentTypeId: string) => {
  return await api.delete<void>(`/equipment-types/${equipmentTypeId}`);
};

const EquipmentTypes: FC = () => {
  const [equipmentTypes, setEquipmentTypes] = useState<IEquipmentType[]>([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [filter, setFilter] = useState();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceType, setEquipmentTypeId] = useState<
    IEquipmentType | undefined
  >();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getEquipmentTypes(meta.current_page, meta.per_page, filter)
        .then(({ data }) => {
          setEquipmentTypes(data.data);
          setMeta(data.meta);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getData();
  }, [load, meta.current_page, meta.per_page, filter]);

  const actions: ActionFunction<IEquipmentType> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => {
          handleShowModal("update", record);
        },
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => {
          onDeleteItem(record);
        },
      },
    ];
  };

  const columns: ColumnsType<IEquipmentType> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
  ];

  const handleFilterData = async (values: any) => {
    setFilter(values);
  };

  const handleChangePage = async (page: number, pageSize: number) => {
    setLoading(true);
    await getEquipmentTypes(page, pageSize, filter)
      .then(({ data }) => {
        setEquipmentTypes(data?.data);
        setMeta(data?.meta);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const onDeleteItem = async (record: IEquipmentType) => {
    setLoading(true);
    await deleteEquipmentType(record.id)
      .then(() => handleRefreshData())
      .finally(() => {
        setLoading(false);
      });
  };

  const handleShowModal = (mode?: string, record?: IEquipmentType) => {
    if (mode === "update" && record) {
      setEquipmentTypeId(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEquipmentTypeId(undefined);
  };

  return (
    <>
      <PageHeader
        title="Tipos de Equipamentos"
        backButton={false}
        actions={[
          <Button
            key={"create_item"}
            onClick={() => handleShowModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar tipo de equipamento
          </Button>,
        ]}
        crumb
      />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
        <FilterEquipmentTypeList onSubmit={handleFilterData} />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          actions={actions}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record: IEquipmentType) => record.id}
          columns={columns}
          dataSource={equipmentTypes}
        />
      </Content>
      <EquipmentTypeModal
        onRefreshPage={handleRefreshData}
        onClose={handleCloseModal}
        isModalOpen={isModalOpen}
        equipmentType={serviceType}
      />
    </>
  );
};

export { EquipmentTypes };
