import { FC, useEffect, useState } from "react";

import { App, Button, Form, Input, Modal, Spin } from "antd";
import { api } from "../../../services/api";
import { IBrand, ModalProps } from "../../../types";

const BrandModal: FC<ModalProps<IBrand>> = ({
  onClose,
  isModalOpen,
  data: brand,
}) => {
  const { message } = App.useApp();

  const [form] = Form.useForm<IBrand>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (brand) {
      form.setFieldsValue(brand);
    }
  }, [form, brand]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          name: values.name.toUpperCase(),
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Marca ${
            data.config.method === "put" ? "atualizada" : "criada"
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitForm = async (values: IBrand) => {
    if (brand) {
      return await api.put<IBrand>(`/brands/${brand.id}`, values);
    }
    return await api.post<IBrand>(`/brands/`, values);
  };

  return (
    <Modal
      title={`${brand ? "Editar" : "Adicionar"} Marca`}
      open={isModalOpen}
      centered
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {brand ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="unit_form_modal">
          <Form.Item
            label="Nome:"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome da marca!",
              },
            ]}
          >
            <Input placeholder="Insira o nome" />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { BrandModal };
