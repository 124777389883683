import { FC, useEffect, useState } from "react";

import { App, Button, Form, Modal, Spin } from "antd";
import { api } from "../../../services/api";
import { IBrand, ModalProps } from "../../../types";
import { FormField } from "./SortableItem";
import { Field } from "./Field";

const ChecklistPreviewModal: FC<ModalProps<IBrand> | any> = ({
  onClose,
  isModalOpen,
  data: checklist,
}) => {
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [formBuilder, setFormBuilder] = useState<Array<FormField>>([]);

  useEffect(() => {
    if (checklist?.id) {
      const getCheckList = async (): Promise<void> => {
        setLoading(true);
        await api
          .get(`/checklists/${checklist.id}`)
          .then(({ data }) => {
            setFormBuilder(data.form.fields);
          })
          .catch(({ response }) => {
            if (response?.data?.message === "E_ROW_NOT_FOUND: Row not found") {
              message.error("CheckList não encotrado.");
            } else {
              message.error("Algo inesperado ocorreu!");
            }
          })
          .finally(() => setLoading(false));
      };
      getCheckList();
    } else {
      if (checklist !== undefined) {
        setFormBuilder(checklist);
      }
    }
  }, [checklist, message]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(true);
  };

  return (
    <Modal
      title={`Pré-visualizar Checklist`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => handleCloseModal()}
        >
          Fechar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="unit_form_modal">
          {formBuilder.map((field) => {
            if (field.element === "Checkbox") {
              return <Field key={field.id} Element={field} />;
            } else {
              return <Field key={field.id} Element={field} />;
            }
          })}
        </Form>
      </Spin>
    </Modal>
  );
};

export { ChecklistPreviewModal };
