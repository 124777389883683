import { DatePicker, Form, Switch } from "antd";

import { useContract } from "../../../contexts/ContractContext";

import { RangePickerProps } from "antd/es/date-picker";

import dayjs from "dayjs";

const TimeAdditiveForm = () => {
  const { contract } = useContract();

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current < dayjs(contract?.endDate).endOf("day");
  };

  return (
    <>
      <Form.Item
        name="currentDate"
        label="Nova Data de Finalização"
        rules={[
          {
            required: true,
            message: "Por favor, insira a Descrição do aditivo.",
          },
        ]}
      >
        <DatePicker
          picker="date"
          format={"DD/MM/YYYY"}
          style={{ width: "100%" }}
          disabledDate={disabledDate}
        />
      </Form.Item>
      <Form.Item
        label="Zerar Itens"
        name="isReseted"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </>
  );
};

export { TimeAdditiveForm };
