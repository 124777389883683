import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Button, Table, Tabs, TabsProps } from "antd";
import { ColumnsType, TableProps } from "antd/es/table";
import { BarChartOutlined, PlusOutlined } from "@ant-design/icons";

import { api } from "../../../services/api";
import { PageHeader } from "../../../components/PageHeader";
import { Content } from "../../../components/Content";
import { IContractAdditive, IMeta, IPagination } from "../../../types";
import { AdditiveStatusTag } from "../components/AdditiveStatusTag";
import { formatters } from "../../../utils/formatters";
import { ContractAdditiveModal } from "../components/ContractAdditiveModal";

const getContractAdditives = async (
  contractId: number | string,
  additiveType: string,
  page = 1,
  perPage = 5
) => {
  return await api.get<IPagination<IContractAdditive>>(
    `/contracts/${contractId}/additives/${additiveType}`,
    {
      params: {
        page: page,
        perPage: perPage,
      },
    }
  );
};

const ContractAdditives: React.FC = () => {
  const { id } = useParams();

  const [additives, setContractAdditives] = useState<IContractAdditive[]>([]);
  const [additiveType, setAdditiveType] =
    useState<IContractAdditive["type"]>("TIME");
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 5,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      getContractAdditives(id!, additiveType)
        .then(({ data }) => {
          setContractAdditives(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getData();
  }, [id, additiveType, load]);

  const onChange = (key: string) => {
    switch (key) {
      case "TIME":
        setAdditiveType("TIME");
        break;
      case "VALUE":
        setAdditiveType("VALUE");
        break;
      case "ITEM":
        setAdditiveType("ITEM");
        break;
      default:
        setAdditiveType("TIME");
    }
  };

  const handleChangePage = async (page: number, pageSize: number) => {
    setLoading(true);
    await getContractAdditives(id!, additiveType, page, pageSize)
      .then(({ data }) => {
        setContractAdditives(data?.data);
        setMeta(data?.meta);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const items: TabsProps["items"] = [
    {
      key: "TIME",
      label: (
        <span>
          <BarChartOutlined />
          Aditivos de Tempo
        </span>
      ),
      children: (
        <AdditiveTable
          additiveType={additiveType}
          data={additives}
          meta={meta}
          loading={loading}
          handleChangePage={handleChangePage}
        />
      ),
    },
    {
      key: "VALUE",
      label: (
        <span>
          <BarChartOutlined />
          Aditivos de Valores
        </span>
      ),
      children: (
        <AdditiveTable
          additiveType={additiveType}
          data={additives}
          meta={meta}
          loading={loading}
          handleChangePage={handleChangePage}
        />
      ),
    },
    {
      key: "ITEM",
      label: (
        <span>
          <BarChartOutlined />
          Aditivos de Itens
        </span>
      ),
      children: (
        <AdditiveTable
          additiveType={additiveType}
          data={additives}
          meta={meta}
          loading={loading}
          handleChangePage={handleChangePage}
        />
      ),
    },
  ];

  const handleCloseModal = (refresh?: boolean) => {
    if (refresh) {
      setLoad(!load);
    }
    setIsModalOpen(false);
  };

  return (
    <Content width="calc(100% - 48px)">
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Itens do Contrato"}
        actions={[
          <Button
            key={"add_contract_additive"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalOpen(true)}
          >
            Adicionar Aditivo
          </Button>,
        ]}
      />
      <Tabs defaultActiveKey="TIME" items={items} onChange={onChange} />
      <ContractAdditiveModal
        isModalOpen={isModalOpen}
        onClose={handleCloseModal}
        type={additiveType}
      />
    </Content>
  );
};

interface AdditiveTableProps extends TableProps<IContractAdditive> {
  data: IContractAdditive[];
  meta: IMeta;
  additiveType: string;
  handleChangePage: (page: number, perPage: number) => Promise<void>;
}

const AdditiveTable = ({
  data,
  meta,
  additiveType,
  handleChangePage,
  ...rest
}: AdditiveTableProps) => {
  let columns: ColumnsType<IContractAdditive> = [];

  switch (additiveType) {
    case "TIME":
      columns = [
        {
          title: "Descrição",
          dataIndex: "description",
          key: "description",
          ellipsis: true,
        },
        {
          title: "Historico de Data",
          dataIndex: "oldDate",
          key: "oldDate",
          ellipsis: true,
          render: (text) => formatters.simpleDate(text),
        },
        {
          title: "Aditivo de Data",
          dataIndex: "currentDate",
          key: "currentDate",
          ellipsis: true,
          render: (text) => formatters.simpleDate(text),
        },
        {
          title: "Status",
          dataIndex: "type",
          key: "type",
          render: (text) => <AdditiveStatusTag status={text} />,
        },
      ];
      break;
    case "VALUE":
      columns = [
        {
          title: "Descrição",
          dataIndex: "description",
          key: "description",
          ellipsis: true,
        },
        {
          title: "Porcentagem",
          dataIndex: "value",
          key: "value",
          ellipsis: true,
          render: (text) => `${text}%`,
        },
        {
          title: "Status",
          dataIndex: "type",
          key: "type",
          render: (text) => <AdditiveStatusTag status={text} />,
        },
      ];
      break;
    default:
      columns = [
        {
          title: "Descrição",
          dataIndex: "description",
          key: "description",
          ellipsis: true,
        },
      ];
      break;
  }

  return (
    <Table
      pagination={{
        position: ["bottomCenter"],
        showSizeChanger: true,
        pageSizeOptions: [5, 10, 15],
        pageSize: meta.per_page,
        onChange: handleChangePage,
      }}
      size="middle"
      rowKey={(record: IContractAdditive) => record.id}
      columns={columns}
      dataSource={data}
      {...rest}
    />
  );
};

export { ContractAdditives };
