import { useState, useEffect, useCallback, FC } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Button, Descriptions, Spin, Typography } from "antd";
import { FilePdfOutlined, OrderedListOutlined } from "@ant-design/icons";

import { api } from "../../../services/api";
import { ILocal, IPagination, IPmoc } from "../../../types";
import { MenuItem, PageHeader } from "../../../components/PageHeader";
import { formatters } from "../../../utils";
import { usePmoc } from "../../../contexts/PmocContext";

const { Text } = Typography;

const { Item } = Descriptions;

export const getPmocById = async (id: string) => {
  return await api.get<IPmoc>(`/pmocs/${id}`);
};

export const getPmocLocals = async (id: string, page = 1, perPage = 10) => {
  return await api.get<IPagination<ILocal>>(`/pmocs/${id}/locals`, {
    params: {
      page,
      perPage,
    },
  });
};

const Pmoc: FC = () => {
  const { handleSetPmoc, pmoc: _pmoc } = usePmoc();
  const { id } = useParams();
  const navigate = useNavigate();

  const [pmoc, setPmoc] = useState<IPmoc>();
  const [loading, setLoading] = useState(false);

  const items: MenuItem[] = [
    {
      label: "Equipamentos",
      key: "equipments",
      icon: <OrderedListOutlined />,
    },
    {
      label: "Arquivos",
      key: "files",
      icon: <FilePdfOutlined />,
    },
  ];

  const getData = useCallback(async () => {
    setLoading(true);
    await getPmocById(String(id))
      .then(({ data }) => {
        setPmoc(data);
        handleSetPmoc(data);
      })
      .catch((error) => {
        if (error?.response?.data?.code === "E_ROW_NOT_FOUND") {
          navigate("/pmocs");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate, handleSetPmoc, id]);

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [getData, id]);

  useEffect(() => {
    if (id) {
      if (!_pmoc || _pmoc.id !== id) {
        getData();
      } else {
        setPmoc(_pmoc);
      }
    }
  }, [_pmoc, id, getData]);

  return (
    <Spin spinning={loading}>
      <PageHeader
        title={pmoc?.name}
        crumb={true}
        footer={[
          <Button key={"delete-button"} danger>
            Deletar
          </Button>,
        ]}
        menuItems={items}
      >
        <Descriptions size="small" column={4}>
          <Item label="ID">
            <Text strong>{String(pmoc?.sid || 0).padStart(4, "0")}</Text>
          </Item>
          <Item label="Inicio">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.simpleDate(pmoc?.startDate)}
            </Text>
          </Item>
          <Item label="Cliente">
            <Text strong>{pmoc?.client?.name}</Text>
          </Item>
          <Item label="Contrato">
            <Text
              strong
              style={{ width: "100%" }}
              ellipsis={{
                suffix: "",
              }}
            >
              {pmoc?.contract?.name}
            </Text>
          </Item>
          <Item label="Descrição">
            <Text strong>{pmoc?.description}</Text>
          </Item>
          <Item label="Fim">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.simpleDate(pmoc?.endDate)}
            </Text>
          </Item>
        </Descriptions>
      </PageHeader>
      <div style={{ marginTop: "16px" }}>
        <Outlet />
      </div>
    </Spin>
  );
};

export { Pmoc };
