import { RouteObject } from "react-router-dom";
import { EquipmentTypes } from "./list";

export const EquipmentTypeRouter: RouteObject = {
  path: "/equipment-types",
  handle: { crumb: () => "Cadastros / Geral / Tipos de Equipamentos" },
  children: [
    {
      index: true,
      element: <EquipmentTypes />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
