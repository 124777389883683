import { FC, useEffect, useState } from "react";

import { App, Button, Form, Input, Modal, Spin } from "antd";

import { api } from "../../../services/api";
import { ISector, ModalProps } from "../../../types";
import { useParams } from "react-router-dom";

const SectorModal: FC<ModalProps<ISector>> = ({
  onClose,
  isModalOpen,
  data: sector,
}) => {
  const { message } = App.useApp();

  const { unitId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sector) {
      form.setFieldsValue(sector);
    }
  }, [form, sector]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return values;
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });

    if (!values) {
      setLoading(false);
      return;
    }

    await handleSubmitForm(values)
      .then(({ config: { method } }) => {
        message.success(
          `Setor ${method === "put" ? "atualizado" : "criado"} com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: ISector) => {
    if (sector) {
      return api.put<ISector>(`/sectors/${sector.id}`, values);
    }
    return api.post<ISector>(`/sectors/${unitId}`, values);
  };

  return (
    <Modal
      title={`${sector ? "Editar" : "Atualizar"} setor`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="unit_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome do Setor!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Área(m²)" name="area">
            <Input />
          </Form.Item>
          <Form.Item label="Ocupantes" name="occupants">
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="description">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { SectorModal };
