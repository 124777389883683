import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { App, Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { api } from "../../../services/api";
import { IPagination, ISector } from "../../../types";
import { Content } from "../../../components/Content";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { SectorModal } from "../components/SectorModal";
import { PageHeader } from "../../../components/PageHeader";

export const getUnitSectors = async (
  unitId?: string,
  page = 1,
  perPage = 10
) => {
  return api.get<IPagination<ISector>>(`/sectors/${unitId}`, {
    params: {
      page,
      perPage,
    },
  });
};

const Sectors: React.FC = () => {
  const { modal, message } = App.useApp();
  const sectorsTableRef = useRef<any>(null);
  const { unitId } = useParams();

  const [sectors, setSectors] = useState<ISector[]>([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<any>({
    current_page: 1,
    per_page: 10,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(false);
  const [sector, setSector] = useState<ISector>();

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setLoading(true);
      await getUnitSectors(unitId)
        .then((units) => {
          const filteredSectors = units.data?.data.filter(
            (sector) => sector.isActive
          );
          setSectors(filteredSectors);
          setMeta(units.data?.meta);
          sectorsTableRef.current?.scrollIntoView({ behavior: "smooth" });
        })
        .finally(() => setLoading(false));
    };
    getData();
  }, [unitId, load]);

  const handleChangePage = async (page: number, pageSize: number) => {
    setLoading(true);
    await getUnitSectors(unitId, page, pageSize)
      .then(({ data }) => {
        const filteredSectors = data?.data.filter((sector) => sector.isActive);
        setSectors(filteredSectors);
        setMeta(data?.meta);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const onDeleteSector = async (record: ISector) => {
    return modal.confirm({
      title: "Deseja deletar o setor?",
      content: <>O setor será excluído permanentemente!</>,
      okText: "Deletar",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      cancelText: "Cancelar",
      cancelButtonProps: {
        type: "default",
        danger: true,
      },
      onOk: async () => {
        await api
          .delete(`/sectors/${record.id}`)
          .catch(() => message.error("Ops! Algo deu errado!"))
          .then(handleRefreshData);
      },
    });
  };

  const actions: ActionFunction<ISector> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteSector(record),
      },
    ];
  };

  const columns: ColumnsType<ISector> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Área(m²)",
      dataIndex: "area",
      key: "area",
      width: 120,
      render: (value) => (value ? `${value} m²` : "--"),
    },
    {
      title: "Ocupantes",
      dataIndex: "occupants",
      key: "occupants",
      width: 120,
      render: (value) => (value ? value : "--"),
    },
    ActionColumn(actions),
  ];

  const handleCloseModal = (refresh?: boolean) => {
    if (refresh) {
      setLoad(!load);
    }
    setIsModalOpen(false);
    setSector(undefined);
  };

  const handleOpenModal = (record?: ISector) => {
    if (record) {
      setSector(record);
    }
    setIsModalOpen(true);
  };

  return (
    <Content>
      <SectorModal
        isModalOpen={isModalOpen}
        onClose={handleCloseModal}
        data={sector}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        title="Setores"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            type={"primary"}
            onClick={() => handleOpenModal()}
          >
            Criar Setor
          </Button>,
        ]}
      />
      <Table
        ref={sectorsTableRef}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
          pageSize: meta.page_size,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size="small"
        loading={loading}
        rowKey={(record: ISector) => record.id}
        columns={columns}
        dataSource={sectors}
      />
    </Content>
  );
};

export { Sectors };
