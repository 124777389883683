export function ChecklistTextareaIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="2" fill="#F0F0F0" />
      <path
        d="M8.53969 10.0794H21.68C21.9616 10.0794 22.2197 9.84473 22.2197 9.53969C22.2197 9.23465 21.985 9 21.68 9H8.53969C8.25811 9 8 9.23465 8 9.53969C8 9.84473 8.25811 10.0794 8.53969 10.0794Z"
        fill="#434343"
      />
      <path
        d="M21.68 12.9883H8.53969C8.25811 12.9883 8 13.2229 8 13.528C8 13.833 8.23465 14.0677 8.53969 14.0677H21.68C21.9616 14.0677 22.2197 13.833 22.2197 13.528C22.2197 13.2229 21.9616 12.9883 21.68 12.9883Z"
        fill="#434343"
      />
      <path
        d="M21.68 16.9551H8.53969C8.25811 16.9551 8 17.1897 8 17.4948C8 17.7998 8.23465 18.0345 8.53969 18.0345H21.68C21.9616 18.0345 22.2197 17.7998 22.2197 17.4948C22.2197 17.1897 21.9616 16.9551 21.68 16.9551Z"
        fill="#434343"
      />
      <path
        d="M17.5736 20.9199H8.53969C8.25811 20.9199 8 21.1546 8 21.4596C8 21.7647 8.23465 21.9993 8.53969 21.9993H17.5736C17.8552 21.9993 18.1133 21.7647 18.1133 21.4596C18.1133 21.1546 17.8552 20.9199 17.5736 20.9199Z"
        fill="#434343"
      />
    </svg>
  );
}
