import { RouteObject } from "react-router-dom";
import { Items } from "./list";
import { loader } from "../../utils/loader";

export const ItemRouter: RouteObject = {
  path: "/items",
  handle: { crumb: () => "Cadastros / Itens de serviços" },
  children: [
    {
      index: true,
      loader: loader,
      element: <Items />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
