import { createElement } from "react";
import { Checkbox, Form } from "antd";
import { Fields } from "./SortableItem";

export interface FormField {
  element: keyof typeof Fields;
  name: string;
  options: any[];
  defaultValue: any;
  id: number | string;
  value: any;
}

interface FieldProps {
  Element: FormField;
}

const Field = ({
  Element: { element, name, id, options },
}: FieldProps): any => {
  let field: any = Fields[element];

  const render = () => {
    let _field: any;
    switch (element) {
      case "Title":
        _field = createElement(field, {
          key: id,
          children: name,
          level: 2,
          style: { marginBottom: 0 },
        });
        break;

      case "Text":
        _field = createElement(field, {
          key: id,
          children: name,
        });
        break;

      case "Divider":
        _field = createElement(field, {
          key: id,
          style: { borderWidth: 10, minWidth: "initial" },
        });
        break;

      case "Slider":
        _field = (
          <Form.Item label={name + ":"} style={{ width: "100%" }}>
            {createElement(field, {
              defaultValue: 30,
            })}
          </Form.Item>
        );
        break;

      case "Select":
        _field = (
          <Form.Item label={name + ":"} style={{ width: "100%" }}>
            {createElement(field, {
              options: options.map((item) => {
                return { key: item, label: item };
              }),
            })}
          </Form.Item>
        );
        break;

      case "Checkbox":
        _field = (
          <Form.Item label={name + ":"} style={{ width: "100%" }}>
            {options.map((item) => {
              return (
                <div style={{ marginBottom: 10 }}>
                  <Checkbox key={item}>{item}</Checkbox>
                </div>
              );
            })}
          </Form.Item>
        );
        break;

      default:
        _field = (
          <Form.Item label={name + ":"} style={{ width: "100%" }}>
            {createElement(field)}
          </Form.Item>
        );
        break;
    }

    return _field;
  };

  return render();
};

export { Field };
