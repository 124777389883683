import { FC, useEffect, useState } from "react";
import { Dropdown, Table } from "antd";
import { ActionFunction } from "../ActionColumn";
import { TableProps } from "antd/lib";

interface ActionTableProps<RecordType = any> extends TableProps<RecordType> {
  actions: ActionFunction<RecordType>;
}

const ActionTable: FC<ActionTableProps> = ({ actions, ...props }) => {
  const [open, setOpen] = useState(false);
  const [record, setRecord] = useState<any | undefined>();
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  function handleRightClick(
    event: React.MouseEvent<any, MouseEvent>,
    record: any
  ) {
    event.preventDefault();
    setPosition({
      x:
        event.clientX < window.innerWidth - 160
          ? event.clientX
          : window.innerWidth - 160,
      y: event.clientY,
    });
    setRecord(record);
    setOpen(true);
  }

  useEffect(() => {
    document
      .getElementById("root")
      ?.addEventListener("click", () => setOpen(false))!;
  }, []);

  return (
    <Dropdown
      menu={{
        items: actions(record),
        style: {
          top: position.y,
          left: position.x,
        },
      }}
      open={open}
      getPopupContainer={(e) => e}
      destroyPopupOnHide={true}
      trigger={["contextMenu"]}
    >
      <>
        <Table
          id="action-column"
          onRow={(record) => {
            return {
              onContextMenu: (e) => handleRightClick(e, record),
            };
          }}
          size="small"
          rowKey={(record) => record.id}
          {...props}
        />
      </>
    </Dropdown>
  );
};

export { ActionTable };
