import { FC, useEffect, useState } from "react";
import { Col, DatePicker, DatePickerProps, Form, Select } from "antd";
import dayjs from "dayjs";

import { useSearchParams } from "react-router-dom";

import { IClient, IContract, IUser } from "../../../types";
import { getAllContracts } from "../../contracts/list";

import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { colors } from "../../orders/components/ServiceOrderStatusTag";
import { getClients } from "../../../services/repositories";
import { getUsers } from "../../users/list";

const { RangePicker } = DatePicker;

interface OrderFilterForm {
  id: string;
  clientId: string;
  contractId: string;
  date: Array<DatePickerProps["value"]>;
  status: string[];
}

interface FilterOrderListProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const FilterOrderList: FC<FilterOrderListProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm<OrderFilterForm>();

  const [, setSearchParams] = useSearchParams();
  const [technical, setTechnical] = useState<IUser[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        ...filter,
        date: filter.date
          ? filter.date.map((date: string) => dayjs(date))
          : undefined,
      });
    }
  }, [form, filter]);

  useEffect(() => {
    if (clients.length === 0) {
      getClients(1, 1000).then(({ data }) => setClients(data));
    }
    if (filter?.clientId) {
      getAllContracts(1, 1000, { clientId: filter.clientId }).then(
        ({ data }) => {
          setContracts(data.data);
        }
      );
    }
    getUsers(1, 1000, { profile: "TECHNICIAN" }).then(({ data }) => {
      setTechnical(data.data);
    });
  }, [filter, clients.length]);

  const onFinish = (values: OrderFilterForm) => {
    const params = JSON.parse(
      JSON.stringify({
        ...values,
        date: values?.date?.map((date) => date?.toISOString().slice(0, 10)),
      })
    );
    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const handleChangeClient = async (clientId: string) => {
    setContracts([]);
    await loadContracts(clientId);
  };

  const loadContracts = async (clientId?: string) => {
    await getAllContracts(1, 1000, { clientId }).then(({ data }) => {
      setContracts(data.data);
    });
  };

  const fields = [
    <Col span={6} key={1}>
      <Form.Item
        name={"technical"}
        label={"Técnico"}
        rules={[
          {
            required: true,
            message: "Selecione ao menos um tecnico!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          mode="multiple"
          showSearch
          allowClear
          options={technical.map((technical) => {
            return {
              label: technical.name,
              value: technical.id,
            };
          })}
          placeholder="Selecione o técnico"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item shouldUpdate noStyle>
        <Form.Item
          name={"date"}
          label={"Período"}
          rules={[
            {
              required: true,
              message: "Por favor selecione um Período!",
            },
          ]}
        >
          <RangePicker
            format="DD/MM/YYYY"
            style={{ width: "100%" }}
            allowClear
          />
        </Form.Item>
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item label="Status" name="status">
        <Select
          mode="multiple"
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={Object.entries(colors).map((key) => {
            return {
              label: key[1].name,
              value: key[0],
            };
          })}
          placeholder="Selecione um status"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={4}>
      <Form.Item name={"clientId"} label={"Cliente"}>
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={clients.map((client) => {
            return {
              label: client.name,
              value: client.id,
            };
          })}
          onChange={handleChangeClient}
          placeholder="Selecione um cliente"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={5}>
      <Form.Item label="Contrato" name="contractId">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={contracts.map((contract) => {
            return {
              label: contract.name,
              value: contract.id,
            };
          })}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterOrderList };
