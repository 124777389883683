export function PNG() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="svg-icon"
      style={{ width: "2rem", height: "2rem", verticalAlign: "middle" }}
      fill="currentColor"
      overflow="hidden"
      viewBox="0 0 1024 1024"
    >
      <path
        fill="#FCCC00"
        d="M192 384h640a42.667 42.667 0 0142.667 42.667v362.666A42.667 42.667 0 01832 832H192v106.667A21.333 21.333 0 00213.333 960h725.334A21.333 21.333 0 00960 938.667V308.82l-10.09-10.154H823.38a98.048 98.048 0 01-98.048-98.048V72.66L716.715 64H213.333A21.333 21.333 0 00192 85.333V384zm-64 448H42.667A42.667 42.667 0 010 789.333V426.667A42.667 42.667 0 0142.667 384H128V85.333A85.333 85.333 0 01213.333 0H743.36L1024 282.453v656.214A85.333 85.333 0 01938.667 1024H213.333A85.333 85.333 0 01128 938.667V832zM92.31 472.49v274.177h40.32V641.45h61.013c59.989 0 89.962-28.416 89.962-84.864 0-56.064-29.973-84.096-89.28-84.096H92.31zm40.32 38.4h58.602c17.579 0 30.677 3.457 38.955 10.753 8.277 6.912 12.757 18.432 12.757 34.944 0 16.512-4.139 28.416-12.416 35.712-8.277 6.912-21.376 10.752-39.296 10.752h-58.603v-92.16zm185.45-38.4v274.177h40.32V546.219h1.387l123.072 200.448h39.296V472.49h-40.662v197.76h-1.365L358.443 472.49h-40.32zm359.19-5.375c-36.523 0-65.494 13.824-86.87 42.24-20.33 26.496-30.315 60.288-30.315 101.376 0 40.704 9.984 74.112 30.315 99.84 21.717 27.648 52.053 41.472 91.35 41.472 22.08 0 41.727-3.072 59.647-9.216a138.027 138.027 0 0044.8-26.112V600.747h-105.45v38.4h65.152v56.448a108.373 108.373 0 01-26.539 12.672 115.67 115.67 0 01-33.088 4.608c-29.995 0-52.053-9.216-66.197-27.648-13.099-17.28-19.627-42.24-19.627-74.496 0-33.792 7.21-59.52 22.037-77.952 13.44-17.664 31.723-26.112 54.827-26.112 18.603 0 33.77 4.224 45.141 13.056 11.03 8.832 18.624 21.888 22.059 39.552h40.683c-4.48-30.72-15.51-53.376-33.088-68.736-18.283-15.744-43.094-23.424-74.816-23.424z"
      ></path>
    </svg>
  );
}
