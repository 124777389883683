export function ChecklistTitleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="2" fill="#F0F0F0" />
      <path
        d="M8.68031 21.3H10.408C10.7014 21.3 11.0274 21.0718 11.0926 20.8436L11.9075 18.7899H18.0359L18.7857 20.8436C18.8509 21.137 19.1769 21.3 19.4703 21.3H21.3284C21.9151 21.2348 22.2411 20.7132 22.0129 20.2895L17.0254 8.42377C16.895 8.13039 16.6668 8 16.3734 8H13.7656C13.5048 8 13.1788 8.13039 13.1136 8.42377L8.06095 20.3547C7.86536 20.811 8.15874 21.3 8.68031 21.3ZM15.0043 11.651L16.7646 16.3125H13.2114L15.0043 11.651Z"
        fill="#434343"
      />
    </svg>
  );
}
