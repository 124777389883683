import { FC, useEffect, useState } from "react";

import { App, Button, Form, Input, Modal, Spin, Select } from "antd";

import { api } from "../../../services/api";
import { IActivity, ModalProps } from "../../../types";

export const FREQUENCIES = [
  { label: "Semanal", value: "WEEKLY" },
  { label: "Quinzenal", value: "FORTNIGHTLY" },
  { label: "Mensal", value: "MONTHLY" },
  { label: "Bimestral", value: "BIMONTHLY" },
  { label: "Trimestral", value: "QUARTERLY" },
  { label: "Semestral", value: "SEMESTERLY" },
  { label: "Anual", value: "YEARLY" },
];

const ActivityModal: FC<ModalProps<IActivity>> = ({
  onClose,
  isModalOpen,
  data: activity,
}) => {
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {};
    if (isModalOpen) {
      getData().then(() => {
        if (activity) {
          form.setFieldsValue(activity);
        }
      });
    }
  }, [form, isModalOpen, activity]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          name: values.name.toUpperCase(),
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then(() => {
        message.success(
          `Atividade ${!!activity ? "atualizada" : "criada"} com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    if (activity) {
      return await api.put<IActivity>(`/activities/${activity.id}`, values);
    }
    return await api.post<IActivity>(`/activities`, values);
  };

  return (
    <Modal
      title={`${activity ? "Editar" : "Adicionar"} atividade`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      centered
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {activity ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="activity_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome do Item!",
              },
            ]}
          >
            <Input placeholder="Insira o nome" />
          </Form.Item>
          <Form.Item
            label="Frequência"
            name="frequency"
            rules={[
              {
                required: true,
                message: "Por favor selecione a frequência da atividade!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={FREQUENCIES.map((frequency) => {
                return {
                  value: frequency.value,
                  label: frequency.label,
                };
              })}
              placeholder="Selecione a frequência"
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ActivityModal };
