const downloader = async (url: string, name: string) => {
  fetch(process.env.REACT_APP_ENDPOINT + url, {
    method: "GET",
    headers: {
      "Content-Type": "application/pdf",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", name ? name : new Date().toTimeString());

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode?.removeChild(link);
    })
    .catch((e) => console.log(e));
};

export { downloader };
