import { FC } from "react";
import { Col, Form, Input, Select } from "antd";
import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { FREQUENCIES } from "./ActivityModal";

interface FilterActivitiesListProps {
  onSubmit?: any;
}

interface FilterItemFormInstance {
  name?: string;
  units?: string[];
  isConsumible?: boolean;
}

const FilterActivitiesList: FC<FilterActivitiesListProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<FilterItemFormInstance>();

  const fields = [
    <Col span={9} key={1}>
      <Form.Item name={"name"} label={"Nome"}>
        <Input allowClear placeholder="Insira o nome" />
      </Form.Item>
    </Col>,
    <Col span={9} key={2}>
      <Form.Item name={"frequencies"} label={"Frequência"}>
        <Select
          placeholder="Selecione a Frequência"
          showSearch
          allowClear
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={FREQUENCIES.map((frequency) => {
            return {
              value: frequency.value,
              label: frequency.label,
            };
          })}
        />
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterItemFormInstance) => {
    if (onSubmit) {
      onSubmit({ ...values });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterActivitiesList };
