import { useState, useEffect, FC } from "react";

import { Button, DatePicker, Typography } from "antd";

import { api } from "../../../services/api";

import { Content } from "../../../components/Content";
import { OrderCard } from "../components/OrderCard";
import { Charts } from "../components/Charts";
import { ReloadOutlined } from "@ant-design/icons";
import { IUser } from "../../../types";

const { RangePicker } = DatePicker;

const Dashboard: FC = () => {
  const [load, setLoad] = useState(false);
  const [orders, setOrders] = useState<any>([]);
  const [technical, setTechnical] = useState<any>([]);

  useEffect(() => {
    const getData = async () => {
      await api.get("/dashboard").then(({ data }) => {
        setOrders(data);
      });
      await api.get<IUser[]>("/dashboard/technical").then(({ data }) => {
        let users = [];
        for (let user of data) {
          users.push({
            name: user.name,
            count: Number(+user.meta?.total),
          });
        }
        setTechnical(users);
      });
    };

    getData();
  }, [load]);

  return (
    <>
      <Content
        margin
        transparent={false}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={3} style={{ margin: 0 }}>
          Filtrar informações da dashboard
        </Typography.Title>
        <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
          <Button type="text">Diário</Button>
          <Button type="text">Semanal</Button>
          <Button type="text">Mensal</Button>
          <Button type="text">Anual</Button>

          <RangePicker style={{ width: "256px" }} />

          <Button
            type="text"
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setLoad(!load)}
          >
            <ReloadOutlined />
          </Button>
        </div>
      </Content>
      <Content
        margin
        transparent
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 16,
          paddingRight: 0,
          paddingLeft: 0,
        }}
      >
        <OrderCard
          title="OS Totais"
          description="Total de Ordens de Serviços no período."
          total={orders?.total}
          color="#51CAF5"
        />
        <OrderCard
          title="OS em Aberto"
          description="Ordens de Serviços aguardando atendimento pelo Técnico."
          total={orders?.OPEN}
          color="#39AF72"
        />
        <OrderCard
          title="OS em Execução"
          description="Ordens de Serviços em processo de atendimento pelo Técnico."
          total={orders?.EXECUTION}
          color="#1A1BCB"
        />
        <OrderCard
          title="OS em Atraso"
          description="Ordens de Serviços onde a previsão de conclusão expirou e que ainda não foram atendidas ou finalizadas pelo Técnico."
          total={orders?.DELAYED}
          color="#C32059"
        />
        <OrderCard
          title="OS pendentes"
          description="Ordens de serviços que estão paralisadas devido à falta de peça ou outro motivo."
          total={orders?.PENDING}
          color="#FA541C"
        />
        <OrderCard
          title="OS Finalizadas"
          description="Ordens de Serviços que já foram atendidas e finalizadas pelo Técnico."
          total={orders?.FINALIZED}
          color="#3561A0"
        />
        <OrderCard
          title="OS Validadas"
          description="Ordens de Serviços que após finalizadas, foram validadas pela equipe interna."
          total={orders?.VALIDATED}
          color="#9D5EE0"
        />
      </Content>

      <Charts rankingByService={technical} />
    </>
  );
};

export { Dashboard };
