import { FC, useCallback, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { App, Button, Skeleton } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  MailOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { ActionFunction } from "../../../components/ActionColumn";
import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";

import { api } from "../../../services/api";

import { IUser, IMeta, IPagination, Profile } from "../../../types";
import { UserModal } from "../components/UserModal";
import { FilterUserList } from "../components/FilterUserList";
import { ActionTable } from "../../../components/ActionTable";


const getUsers = async (page = 1, perPage = 10, q?: any) => {
  return await api.get<IPagination<IUser>>(`/users`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const deleteUser = async (userId: string) => {
  return await api.delete<void>(`/users/${userId}`)
};

enum ModalActions {
  CREATE_USER = "CREATE_USER",
  EDIT_USER = "EDIT_USER",
  CLOSE_MODAL = "CLOSE_MODAL",
}

interface ModalAction {
  type: ModalActions;
  payload?: ModalState;
}

interface ModalState {
  user?: IUser;
}

const PROFILES = {
  ADMINISTRATOR: "ADMINISTRADOR",
  ATTENDANT: "ATENTENTE",
  TECHNICIAN: "TECNICO",
  ASSISTANT: "ASSISTENTE",
  ENGINEER: "ENGENHEIRO",
  CUSTOMER: "CLIENTE",
  MANAGER: "FISCAL",
};

const modalReducer = (_: ModalState, action: ModalAction) => {
  const { type, payload } = action;
  switch (type) {
    case "CREATE_USER":
      return { isOpen: true, user: undefined };
    case "EDIT_USER":
      return { isOpen: true, user: payload?.user };
    case "CLOSE_MODAL":
      return { isOpen: false, user: undefined };
    default:
      throw new Error("Unknown action: " + action.type);
  }
};

const Users: FC = () => {
  const navigate = useNavigate();
  const { modal, message } = App.useApp();

  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [filter, setFilter] = useState<any | undefined>();

  const [state, dispatch] = useReducer(modalReducer, {
    isOpen: false,
    user: undefined,
  });

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      await getUsers(page, pageSize, filter)
        .then(({ data }) => {
          const activeUsers = data.data.filter(
            user => user.isActive && !["CUSTOMER", "MANAGER"].includes(user.profile)
          );
          setUsers(activeUsers);
          setMeta(data.meta);
        })    
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [filter]
  );
  

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, filter, handleChangePage, meta.per_page]);

  const actions: ActionFunction<IUser> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => {
          dispatch({
            type: ModalActions.EDIT_USER,
            payload: {
              user: record,
            },
          });
        },
      },
      {
        key: "2",
        icon: <SearchOutlined />,
        label: "Detalhar",
        onClick: () => navigate(record.id),
      },
      {
        key: "3",
        icon: <MailOutlined />,
        label: "Reenviar Email",
        disabled: record?.isValidated === true,
        danger: true,
        onClick: () => resendEmail(record),
      },
      {
        key: "4",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteUser(record),
      },
    ];
  };

  const columns: ColumnsType<IUser> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      width: 400,
    },
    {
      title: "Login",
      dataIndex: "username",
      key: "Login",
      width: 400,
    },
    {
      title: "Email",
      dataIndex: "account",
      key: "email",
      render: (account) => account?.email,
      width: 400,
    },
    {
      title: "Função",
      dataIndex: "profile",
      key: "profile",
      render: (profile: Profile) => PROFILES[profile],
    },
    // {
    //   title: "Validado",
    //   dataIndex: "isValidated",
    //   key: "isValidated",
    //   render: (value) => (value ? "SIM" : "NÃO"),
    // },
  ];

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const resendEmail = async (record: IUser) => {
    setLoading(true);
    await api
      .put(`/users/${record.id}/resend-email`)
      .then(() => message.success("Email enviado com sucesso!"))
      .then(() => handleRefreshData())
      .finally(() => setLoading(false));
  };

  const onDeleteUser = async (record: IUser) => {
    return modal.confirm({
      title: "Deseja deletar esse usuário?",
      content: (
        <>
        O usuário será excluído permanentemente!
        </>
      ),
      okText: "Deletar",
      okButtonProps: { 
        type: "primary",
        danger: true
      },
      cancelText: "Cancelar",
      cancelButtonProps: { 
        type: "default",
         danger: true
      },
      onOk: async () =>
      {
        setLoading(true)
        await deleteUser(record.id)
        .then(() => handleRefreshData()).catch((error: any) => {
          const techWithPendingServiceOrder = error.response?.data?.errors[0].message
          if (techWithPendingServiceOrder) {
            message.error('Não foi possível deletar porque existem um ou mais atendimentos não finalizados para esse usuário.');
            return
          }

          message.error('Ops! Algo deu errado!');
        })
        .finally(() => setLoading(false))
      },
    });
  };


  return (
    <Skeleton loading={false}>
      <PageHeader
        title="Usuários"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            key={"create_user"}
            type="primary"
            onClick={() =>
              dispatch({
                type: ModalActions.CREATE_USER,
              })
            }
          >
            Adicionar usuário
          </Button>,
        ]}
        crumb
      />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
        <FilterUserList onSubmit={(values) => setFilter(values)} />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          actions={actions}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={users}
        />
      </Content>
      <UserModal
        handleRefreshData={handleRefreshData}
        onClose={() =>
          dispatch({
            type: ModalActions.CLOSE_MODAL,
          })
        }
        isModalOpen={state.isOpen}
        data={state.user}
      />
    </Skeleton>
  );
};

export { Users, getUsers, deleteUser };
