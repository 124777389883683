import { FC, useEffect } from "react";
import { Col, Form, Input, Radio, Select } from "antd";
import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { unitTypes } from "./ItemModal";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

interface FilterItemListProps {
  onSubmit?: any;
  filter?: any;
}

interface FilterItemFormInstance {
  name?: string;
  units?: string[];
  isConsumible?: boolean;
}

const FilterItemList: FC<FilterItemListProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm<FilterItemFormInstance>();
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        ...filter,
        units: filter.units ? filter.units?.split(",") : undefined,
      });
    }
  }, [form, filter]);

  const onFinish = (values: any) => {
    const params = JSON.parse(
      JSON.stringify({
        ...values,
        units: values.units?.toString(),
      })
    );
    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    onSubmit();
  };

  const fields = [
    <Col span={6} key={1}>
      <Form.Item name={"name"} label={"Nome"}>
        <Input allowClear placeholder="Insira o nome" />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item name={"units"} label={"Unidade"}>
        <Select
          placeholder="Selecione a unidade"
          allowClear
          showSearch
          optionFilterProp="children"
          mode="multiple"
        >
          {unitTypes.map((unitType) => {
            return (
              <Option key={unitType.value} value={unitType.value}>
                {unitType.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item name="isConsumible" label="Consumível">
        <Radio.Group>
          <Radio value={"true"}>Sim</Radio>
          <Radio value={"false"}>Não</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>,
    <Col span={6} key={4}>
      <Form.Item name="isDetached" label="Avulso">
        <Radio.Group>
          <Radio value={"true"}>Sim</Radio>
          <Radio value={"false"}>Não</Radio>
        </Radio.Group>
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterItemList };
