import { RouteObject } from "react-router-dom";
import { Users } from "./list";
import { User } from "./user";
import { UserConfigs } from "./userConfigs";

export const UserRouter: RouteObject = {
  path: "/users",
  handle: { crumb: () => "Cadastros / Usuários" },
  children: [
    {
      index: true,
      element: <Users />,
    },
    {
      path: ":id",
      element: <User />,
      children: [
        {
          path: "configs",
          element: <UserConfigs />,
        },
      ],
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
