import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Timeline, Typography } from "antd";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { useServiceOrder } from "../../../contexts/OrderContext";
import {
  ServiceOrderStatusTag,
  colors,
} from "../components/ServiceOrderStatusTag";
import { formatters } from "../../../utils";
import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import { HistoryModal } from "../components/HistoryModal";
import { useModal } from "../../../hooks/useModal";
import { api } from "../../../services/api";
import { IServiceOrderHistories } from "../../../types";

const OrderTimeLine: FC = () => {
  const { serviceOrder } = useServiceOrder();
  const { id } = useParams();
  const navigate = useNavigate();

  const [histories, setHistories] = useState<IServiceOrderHistories[]>([]);
  const [loading, setLoading] = useState(true);

  const { data, isOpen, handleCloseModal, handleOpenModal } = useModal<string>(
    () => navigate(0)
  );

  useEffect(() => {
    api
      .get(`/service-orders/${id}/history`)
      .then(({ data }) => setHistories(data))
      .finally(() => setLoading(false));
  }, [id]);

  return (
    <Content width="calc(100% - 48px)">
      <HistoryModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        data={data}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Linha do Tempo"}
        actions={[
          <Button
            key="1"
            icon={<CheckOutlined />}
            disabled={serviceOrder?.status !== "PENDING"}
            onClick={() => handleOpenModal("REOPENED")}
          >
            Solucionar Pendência
          </Button>,
          <Button
            key="2"
            icon={<PlusOutlined />}
            disabled={serviceOrder?.status !== "PENDING"}
            onClick={() => handleOpenModal("PENDING")}
          >
            Adicionar Histórico
          </Button>,
        ]}
      />
      <Timeline
        mode="alternate"
        pending={loading}
        items={histories?.map((history) => {
          let pos =
            history.user?.profile === "TECHNICIAN" ||
            history.user?.profile === "ASSISTANT";
          return {
            color:
              history.status === "FINALIZED"
                ? "gray"
                : colors[history.status].color,
            position: pos ? "right" : "left",
            children: (
              <>
                <ServiceOrderStatusTag status={history.status} />
                <br />
                {formatters.customDate(
                  history.createdAt,
                  "dd 'de' MMMM 'de' yyyy 'às' HH:mm"
                )}
                <br />
                {history.user?.name}
                {history.type === "HISTORY" && (
                  <>
                    <br />
                    <Typography.Title level={5} style={{ marginBottom: 0 }}>
                      {history.title}
                    </Typography.Title>
                    <Typography.Paragraph
                      style={{ width: "50%", marginBottom: 0 }}
                      ellipsis={{
                        rows: 2,
                        expandable: true,
                      }}
                    >
                      {history.description}
                    </Typography.Paragraph>
                  </>
                )}
              </>
            ),
          };
        })}
      />
    </Content>
  );
};

export { OrderTimeLine };
