import { FC, useEffect, useState } from "react";
import { Form, FormInstance, Input, Select, Switch } from "antd";

import { getAllContracts } from "../../contracts/list";
import { getLocalUnits } from "../../clients/client/ClientLocalUnits";
import { getServiceTypes } from "../../serviceTypes/list";
import { getEquipmentTypes } from "../../equipmentTypes/list";

import {
  IContract,
  IEquipmentType,
  ILocal,
  ISector,
  IServiceOrder,
  IServiceType,
  IUnit,
} from "../../../types";
import { getClients, getContractLocals } from "../../../services/repositories";
import { getUnitSectors } from "../../clients/client/ClientUnitSectors";

import { Select as SSelect } from "../../../components/Form/Select";

interface ContractOrderFormProps {
  form: FormInstance;
  data?: IServiceOrder | null;
}

const ContractOrderForm: FC<ContractOrderFormProps> = ({ form, data }) => {
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [sectors, setSectors] = useState<ISector[]>([]);

  const [equipmentTypes, setEquipmentTypes] = useState<IEquipmentType[]>([]);
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getServiceTypes(1, 1000).then(({ data }) => {
        setServiceTypes(data.data);
      });
      await getEquipmentTypes(1, 1000).then(({ data }) => {
        setEquipmentTypes(data.data);
      });

      if (data) {
        if (data.contractId) {
          await getContractLocals({
            id: data.contractId,
            page: 1,
            perPage: 1000,
          }).then(({ data }) => {
            setLocals(data);
          });
        }
        await getAllContracts(1, 1000, { clientId: data.clientId }).then(
          ({ data }) => {
            setContracts(data.data);
          }
        );
        await getLocalUnits(data.localId, 1, 1000).then(({ data }) => {
          setUnits(data.data);
        });
      }
    };
    getData();
  }, [form, data]);

  const handleChangeClient = async (clientId: string) => {
    setContracts([]);
    await loadContracts(clientId);
  };

  const handleChangeContract = async (contractId: string) => {
    form.setFieldsValue({ localId: null, unitId: null });
    await getContractLocals({
      id: contractId,
      page: 1,
      perPage: 1000,
    }).then(({ data }) => {
      setLocals(data);
    });
  };

  const loadContracts = async (clientId?: string) => {
    form.setFieldValue("contractId", null);
    if (clientId) {
      await getAllContracts(1, 1000, { clientId }).then(({ data }) => {
        setContracts(data.data);
      });
    }
  };

  const loadUnits = async (localId?: string) => {
    form.setFieldValue("unitId", null);
    if (localId) {
      await getLocalUnits(localId, 1, 1000).then(({ data }) => {
        setUnits(data.data);
      });
    }
  };

  const loadSectors = async (unidId?: string) => {
    form.setFieldValue("sectorId", null);
    if (unidId) {
      await getUnitSectors(unidId, 1, 1000).then(({ data }) => {
        setSectors(data.data);
      });
    }
  };

  const DISABLED = !!data;

  return (
    <>
      <Form.Item
        label="Cliente:"
        name="clientId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o cliente!",
          },
        ]}
      >
        <SSelect
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          disabled={DISABLED}
          handleLoadData={getClients}
          onChange={handleChangeClient}
          placeholder="Selecione um cliente"
        />
      </Form.Item>
      <Form.Item
        label="Contrato:"
        name="contractId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o contrato!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          disabled={DISABLED}
          options={contracts.map((contract) => {
            return {
              label: contract.name,
              value: contract.id,
            };
          })}
          onChange={handleChangeContract}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
      <Form.Item
        label="Local:"
        name="localId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o local!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          disabled={DISABLED}
          options={locals.map((local) => {
            return {
              label: local.name,
              value: local.id,
            };
          })}
          onChange={loadUnits}
          placeholder="Selecione um local"
        />
      </Form.Item>
      <Form.Item
        label="Unidade:"
        name="unitId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione a unidade!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={units.map((unit) => {
            return {
              label: unit.name,
              value: unit.id,
            };
          })}
          onChange={loadSectors}
          placeholder="Selecione uma unidade"
        />
      </Form.Item>
      <Form.Item label="Setor:" name="sectorId">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={sectors.map((unit) => {
            return {
              label: unit.name,
              value: unit.id,
            };
          })}
          placeholder="Selecione um Setor"
        />
      </Form.Item>
      <Form.Item
        label="Tipos de Serviço:"
        name="serviceTypes"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o tipo de serviço!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          mode="multiple"
          showSearch
          allowClear
          options={serviceTypes.map((serviceType) => {
            return {
              label: serviceType.name,
              value: serviceType.id,
            };
          })}
          placeholder="Selecione o tipo de serviço"
        />
      </Form.Item>
      <Form.Item
        label="Tipos de Equipamento:"
        name="equipmentTypes"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o tipo de equipamento!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          disabled={DISABLED}
          options={equipmentTypes.map((equipmentType) => {
            return {
              label: equipmentType.name,
              value: equipmentType.id,
            };
          })}
          placeholder="Selecione o tipo de equipamento"
        />
      </Form.Item>
      <Form.Item
        label="Necessita de Equipamento?"
        name="isRequiredEquipment"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
      <Form.Item label="Número de Referência:" name="referenceNumber">
        <Input placeholder="Insira um número de referência" />
      </Form.Item>
    </>
  );
};

export { ContractOrderForm };
