import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Form, Input, Typography, theme, Divider, Spin } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

import { Content } from "../../../components/Content";

import { useAuth } from "../../../contexts/AuthContext";
import { useColorMode } from "../../../contexts/ColorModeContext";

import Image from "../../../assets/logo.svg";
import ImageDark from "../../../assets/logo-dark.svg";

const { Text } = Typography;

type FormProps = { username: string; password: string };

const Login = () => {
  const { isAuthenticated, signIn } = useAuth();
  const { selectedTheme } = useColorMode();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [state] = useState(location.state || {});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(state?.from || "/");
    }
  }, [isAuthenticated, navigate, state]);

  const {
    token: { colorPrimary, colorBgContainer, colorTextDescription },
  } = theme.useToken();

  const onFinish = async (values: FormProps) => {
    setIsLoading(true);
    await signIn(values).catch(() => {
      setIsLoading(false);
    });
  };

  return (
    <Content
      style={{
        minHeight: "100vh",
        width: "100%",
        background: colorBgContainer,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#F0F0F0",
      }}
    >
      <div
        style={{
          padding: 8,
          minHeight: 500,
          minWidth: 400,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 16,
          }}
        >
          <img
            src={selectedTheme === "light" ? ImageDark : Image}
            alt="Logo Arcke"
            style={{ height: "54px" }}
          />
          <Text
            style={{
              color: colorTextDescription,
              textAlign: "center",
              fontSize: 14,
            }}
          >
            Sistema de Gestão de Ordem de Serviço e PMOC
          </Text>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: 70,
          }}
        >
          <Text
            style={{
              color: "#3030DA",
              paddingBottom: 3,
              borderBottom: "2px solid #3030DA",
            }}
          >
            Login
          </Text>
          <Divider style={{ marginTop: 6 }} />
          <Spin spinning={isLoading}>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: 336,
                alignItems: "center",
              }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Por favor insira seu nome de usuário!",
                  },
                ]}
              >
                <Input
                  prefix={
                    <UserOutlined
                      style={{
                        color: colorPrimary,
                      }}
                    />
                  }
                  style={{ width: 336 }}
                  placeholder="Nome de Usuário"
                  size="large"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Por favor insira sua senha!" },
                ]}
              >
                <Input.Password
                  prefix={
                    <LockOutlined
                      style={{
                        color: colorPrimary,
                      }}
                    />
                  }
                  style={{ width: 336 }}
                  placeholder="Senha"
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <Button
                    type="link"
                    htmlType="button"
                    style={{ padding: 0 }}
                    onClick={() => navigate("/forgot-password")}
                  >
                    Esqueci minha senha
                  </Button>
                  <Button
                    type="primary"
                    size="large"
                    htmlType="submit"
                    style={{ width: 100 }}
                  >
                    Entrar
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </Content>
  );
};

export { Login };
