import { FC } from "react";
import { Outlet } from "react-router-dom";


import { Content } from "../../../components/Content";
import { usePagination } from "../../../hooks/usePaginations";
import { getFinancial } from "../../../services/repositories";


interface LocalsHeaderProps {
  title?: string;
}

const Financial: FC<LocalsHeaderProps> = ({ title }) => {
  const {
    data: financial,
    // isLoading,
    // filter,
    // setFilter,
    // handleRefreshData,
    // pagination,
  } = usePagination(getFinancial);
  console.log(financial)

  return (
    <>
      <Content width="calc(100% - 48px)">

      </Content>
      <Outlet />
    </>
  );
};

export { Financial };
