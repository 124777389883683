import { RouteObject } from "react-router-dom";
import { Equipments } from "./list";

export const EquipmentRouter: RouteObject = {
  path: "/equipments",
  handle: { crumb: () => "Equipamentos" },
  children: [
    {
      index: true,
      element: <Equipments />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
