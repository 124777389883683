export function ChecklistDividerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="2" fill="#F0F0F0" />
      <path
        d="M22.3328 15.725H6.66724C6.31911 15.725 6 15.35 6 14.8625C6 14.4125 6.2901 14 6.66724 14H22.3328C22.6809 14 23 14.375 23 14.8625C23 15.3125 22.6809 15.725 22.3328 15.725Z"
        fill="#434343"
      />
    </svg>
  );
}
