import { FC, useEffect, useState } from "react";
import { Col, DatePicker, DatePickerProps, Form, Input, Select } from "antd";
import dayjs from "dayjs";

import { useSearchParams } from "react-router-dom";

import { IClient, IContract } from "../../../types";
import { getAllContracts } from "../../contracts/list";
import { getUsers } from "../../users/list";
import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { colors } from "../../orders/components/ServiceOrderStatusTag";
import { getClients } from "../../../services/repositories";
import { IUser } from "../../../types";

const { RangePicker } = DatePicker;

interface OrderFilterForm {
  clientId: string;
  contractId: string;
  technicianId: string;
  date: Array<DatePickerProps["value"]>;
  status: string[];
}

interface FilterOrderListProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const FilterOrderList: FC<FilterOrderListProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm<OrderFilterForm>();

  const [, setSearchParams] = useSearchParams();
  const [clients, setClients] = useState<IClient[]>([]);
  const [technical, setTechnical] = useState<IUser[]>([]);
  const [, setContracts] = useState<IContract[]>([]);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        ...filter,
        date: filter.date
          ? filter.date.split(",").map((date: string) => dayjs(date))
          : undefined,
        status: filter.status ? filter.status?.split(",") : undefined,
      });
    }
  }, [form, filter]);

  useEffect(() => {
    getClients(1, 1000).then(({ data }) => setClients(data));
    if (filter?.clientId) {
      getAllContracts(1, 1000, { clientId: filter?.clientId }).then(
        ({ data }) => {
          setContracts(data.data);
        }
      );
    }
  }, [filter]);

  useEffect(() => {
    const getData = async () => {
      await getUsers(1, 1000, { profile: "TECHNICIAN" }).then(({ data }) => {
        setTechnical(data.data);
      });
    };
    getData();
  }, []);

  const onFinish = (values: OrderFilterForm) => {
    const params = JSON.parse(
      JSON.stringify({
        ...values,
        date: values.date
          ?.map((date) => date?.toISOString().slice(0, 10))
          .toString(),
        status: values.status?.toString(),
      })
    );
    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const handleChangeClient = async (clientId: string) => {
    setContracts([]);
    await loadContracts(clientId);
  };

  const handleChangeTechnical = async (technicalId: string) => {
    setContracts([]);
    await loadContracts(technicalId);
  };

  const loadContracts = async (clientId?: string, technicalId?: string) => {
    await getAllContracts(1, 1000, { clientId, technicalId }).then(
      ({ data }) => {
        setContracts(data.data);
      }
    );
  };

  const fields = [
    <Col span={6} key={1}>
      <Form.Item label="ID" name="sid">
        <Input allowClear placeholder="Insira um ID" />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item label="Tipo" name="type">
        <Select
          mode="multiple"
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={[
            { label: "ORÇAMENTO", value: "BUDGET" },
            { label: "CONTRATO", value: "CONTRACT" },
            { label: "AVULSA", value: "SINGLE" },
            { label: "PMOC", value: "PMOC" },
          ]}
          placeholder="Selecione o tipo de atendimento"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item name={"date"} label={"Período"}>
        <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} allowClear />
      </Form.Item>
    </Col>,
    <Col span={6} key={4}>
      <Form.Item name={"clientId"} label={"Cliente"}>
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={clients.map((client) => {
            return {
              label: client.name,
              value: client.id,
            };
          })}
          onChange={handleChangeClient}
          placeholder="Selecione o cliente"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={5}>
      <Form.Item name={"technicianId"} label={"Técnico"}>
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={technical.map((technical) => {
            return {
              label: technical.name,
              value: technical.id,
            };
          })}
          onChange={handleChangeTechnical}
          placeholder="Selecione o técnico"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={6}>
      <Form.Item label="Status" name="status">
        <Select
          mode="multiple"
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={Object.entries(colors).map((key) => {
            return {
              label: key[1].name,
              value: key[0],
            };
          })}
          placeholder="Selecione o status"
        />
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterOrderList };
