import { FC } from "react";

import { App, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { IContract } from "../../../../types";
import { Content } from "../../../../components/Content";
import { PageHeader } from "../../../../components/PageHeader";
import { formatters } from "../../../../utils/formatters";
import { Filter } from "./Filter";
import { reports } from "../../../../services/reports";
import { getContracts } from "../../../../services/repositories";
import { ActionTable } from "../../../../components/ActionTable";
import { ActionFunction } from "../../../../components/ActionColumn";
import { usePagination } from "../../../../hooks";

const ItemBalance: FC = () => {
  const { message } = App.useApp();
  const {
    data: orders,
    isLoading,
    filter,
    setFilter,
    pagination,
  } = usePagination(getContracts);

  const columns: ColumnsType<IContract> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Contrato",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Ciente",
      dataIndex: "client",
      key: "client",
      ellipsis: true,
      render: (client) => client?.name,
    },
    {
      title: "Responsável",
      dataIndex: "responsible",
      key: "responsible",
      ellipsis: true,
      render: (user) => user?.name || "--",
    },
    {
      title: "Valor do contrato",
      dataIndex: "value",
      key: "value",
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>{formatters.currency(value)}</div>
        );
      },
    },
    {
      title: "Aberto em:",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => formatters.simpleDate(date),
    },
    {
      title: "Finaliza em:",
      dataIndex: "endDate",
      key: "endDate",
      render: (date) => formatters.simpleDate(date),
    },
  ];

  const actions: ActionFunction<IContract> = (record) => {
    return [
      {
        key: "1",
        icon: <PrinterOutlined />,
        label: "Imprimir Registro",
        onClick: () => onPrint(record.id),
      },
    ];
  };

  const onPrint = (record?: string) => {
    if (record) {
      reports("item-balance", { id: record });
      return;
    }
    if (filter && orders.length > 0) {
      reports("item-balance", filter);
      return;
    }
    if (filter && orders.length === 0) {
      message.info("Não há registros para impressão.");
      return;
    }
    message.error("Você precisa filtar as informações!");
  };

  return (
    <>
      <PageHeader
        title="Relatórios de Itens de Serviços Detalhados"
        backButton={false}
        actions={[
          <Button
            onClick={() => onPrint()}
            key={"print_table"}
            icon={<PrinterOutlined />}
            type="primary"
          >
            Imprimir
          </Button>,
        ]}
        crumb
      />
      <Content margin>
        <Filter onSubmit={(values) => setFilter(values)} filter={filter} />
      </Content>
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          size="small"
          loading={isLoading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={orders}
        />
      </Content>
    </>
  );
};

export { ItemBalance };
