import { FC } from "react";

import { Button, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { Content } from "../../../components/Content";
import { ContractProductModal } from "../components/ContractProductModal";
import { IProduct } from "../../../types";
import { ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { formatters } from "../../../utils/formatters";
import { getContractProducts } from "../../../services/repositories";
import { ActionTable } from "../../../components/ActionTable";
import { useDelete, useModal, usePagination } from "../../../hooks";

const ContractProducts: FC = () => {
  const {
    data: contractProducts,
    isLoading,
    pagination,
    handleRefreshData,
  } = usePagination(getContractProducts);

  const {
    data: contractProduct,
    isOpen,
    handleCloseModal,
    handleOpenModal,
  } = useModal<IProduct>(handleRefreshData);

  const { handleDeleteData } = useDelete(handleRefreshData);

  const actions: ActionFunction<IProduct> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => {
          handleOpenModal(record);
        },
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () =>
          handleDeleteData(
            `/contracts/${record.meta?.pivot_contract_id}/products/${record.id}`,
            "Deseja deletar o produto?"
          ),
      },
    ];
  };

  const columns: ColumnsType<IProduct> = [
    {
      title: "Numero",
      dataIndex: "meta",
      key: "number",
      render: ({ pivot_number }) => <Tag>{pivot_number}</Tag>,
      width: 100,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Quantidade",
      dataIndex: "meta",
      key: "quantity",
      width: 120,
      render: ({ pivot_quantity }) => pivot_quantity,
    },
    {
      title: "Uso",
      dataIndex: "meta",
      key: "usage",
      width: 120,
      render: ({ pivot_usage }) => pivot_usage,
    },
    {
      title: "Valor",
      dataIndex: "meta",
      key: "value",
      render: ({ pivot_value }) => formatters.currency(pivot_value),
      width: 120,
    },
    {
      title: "Custo",
      dataIndex: "meta",
      key: "cost",
      render: ({ pivot_cost }) => formatters.currency(pivot_cost),
      width: 120,
    },
  ];

  return (
    <Content>
      <ContractProductModal
        isModalOpen={isOpen}
        data={contractProduct}
        onClose={handleCloseModal}
        onRefreshData={handleRefreshData}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0, marginBottom: "16px" }}
        width="100%"
        backButton={false}
        title={"Itens do Contrato"}
        actions={[
          <Button
            key={"add_item_contract"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleOpenModal()}
          >
            Adicionar Item
          </Button>,
        ]}
      />
      <ActionTable
        pagination={pagination}
        loading={isLoading}
        actions={actions}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={contractProducts}
      />
    </Content>
  );
};

export { ContractProducts };
