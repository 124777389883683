import { TabsProps } from "antd";
import { Permissions } from "./Permissions";
import { Organization } from "./Organization";
import { Globals } from "./Globals";

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Permissões",
    children: <Permissions />,
  },
  {
    key: "2",
    label: "Organização",
    children: <Organization />,
  },
  {
    key: "3",
    label: "Globais",
    children: <Globals />,
  },
];

export { items };
