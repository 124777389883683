import { useState, useEffect, useRef, useCallback, FC } from "react";
import { useParams } from "react-router-dom";

import { Button, Image, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  FilePdfOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { api } from "../../../services/api";
import { Content } from "../../../components/Content";
import { IPmocFile, IMeta, IPagination } from "../../../types";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
// import { ContractFileModal } from "../components/ContractFileModal";
import { downloader } from "../../../utils";
import { PmocFileModal } from "../components/PmocFileModal";

const getPmocFiles = async (pmocId: string, page = 1, perPage = 10) => {
  return await api.get<IPagination<IPmocFile>>(`/pmocs/${pmocId}/files`, {
    params: {
      page,
      perPage,
    },
  });
};

const PmocFiles: FC = () => {
  const itemsTable = useRef<any>();
  const { id } = useParams();

  const [pmocFiles, setPmocFiles] = useState<IPmocFile[]>([]);
  const [pmocFile, setPmocFile] = useState<IPmocFile>();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowModal = (pmocFile?: IPmocFile) => {
    if (pmocFile) {
      setPmocFile(pmocFile);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setPmocFile(undefined);
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      getPmocFiles(String(id), page, pageSize)
        .then(({ data }) => {
          setPmocFiles(data?.data);
          setMeta(data?.meta);
          itemsTable.current?.scrollIntoView({ behavior: "smooth" });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, id, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IPmocFile> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        conditional: true,
        onClick: () => handleShowModal(record),
      },
      {
        key: "2",
        icon: <DownloadOutlined />,
        label: "Baixar",
        conditional: record.name.slice(-3) === "pdf",
        onClick: () => downloader(record.url, record.name),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        conditional: true,
        danger: true,
        onClick: () => {},
      },
    ].filter((item) => item.conditional);
  };

  const columns: ColumnsType<IPmocFile> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Arquivo",
      dataIndex: "url",
      key: "url",
      ellipsis: true,
      render: (url: string) =>
        url.slice(-3) !== "pdf" ? (
          <Image
            height={60}
            style={{ objectFit: "cover" }}
            src={process.env.REACT_APP_ENDPOINT + url}
          />
        ) : (
          <FilePdfOutlined style={{ fontSize: "64px", color: "#08c" }} />
        ),
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
      ellipsis: true,
    },
    ActionColumn(actions),
  ];

  return (
    <Content width="calc(100% - 48px)">
      <PmocFileModal
        onRefreshData={handleRefreshData}
        onClose={handleCloseModal}
        isModalOpen={isModalOpen}
        data={pmocFile}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Itens do Contrato"}
        actions={[
          <Button
            key={"add_item_contract"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleShowModal()}
          >
            Adicionar Arquivo
          </Button>,
        ]}
      />
      <Table
        ref={itemsTable}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
          pageSize: meta.per_page,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size="small"
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={pmocFiles}
      />
    </Content>
  );
};

export { PmocFiles };
