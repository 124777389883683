import { FC } from "react";

import { App, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { IContract } from "../../../../types";
import { Content } from "../../../../components/Content";
import { PageHeader } from "../../../../components/PageHeader";
import { reports } from "../../../../services/reports";
import { getLocals } from "../../../../services/repositories";
import { ActionTable } from "../../../../components/ActionTable";
import { ActionFunction } from "../../../../components/ActionColumn";
import { usePagination } from "../../../../hooks";
import { Filter } from "./Filter";

const ServiceBilling: FC = () => {
  const { message } = App.useApp();
  const {
    data: orders,
    isLoading,
    filter,
    setFilter,
    pagination,
  } = usePagination(getLocals);

  const columns: ColumnsType<IContract> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      ellipsis: true,
      width: 70,
    },
    {
      title: "Local",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Serviços Realizados",
      dataIndex: "meta",
      key: "meta",
      ellipsis: true,
      render: (meta) => meta?.orders_count,
    },
  ];

  const actions: ActionFunction<IContract> = (record) => {
    return [
      {
        key: "1",
        icon: <PrinterOutlined />,
        label: "Imprimir Registro",
        onClick: () => onPrint(record.id),
      },
    ];
  };

  const onPrint = (record?: string) => {
    if (record) {
      reports("service-billing", { id: record });
      return;
    }
    if (filter && orders.length > 0) {
      reports("service-billing", filter);
      return;
    }
    if (filter && orders.length === 0) {
      message.info("Não há registros para impressão.");
      return;
    }
    message.error("Você precisa filtar as informações!");
  };

  return (
    <>
      <PageHeader
        title="Relatórios de Itens de Serviços Detalhados"
        backButton={false}
        actions={[
          <Button
            onClick={() => onPrint()}
            key={"print_table"}
            icon={<PrinterOutlined />}
            type="primary"
          >
            Imprimir
          </Button>,
        ]}
        crumb
      />
      <Content margin>
        <Filter onSubmit={(values) => setFilter(values)} filter={filter} />
      </Content>
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          size="small"
          loading={isLoading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={orders}
        />
      </Content>
    </>
  );
};

export { ServiceBilling };
