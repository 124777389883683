import { RouteObject } from "react-router-dom";
import { ServiceTypes } from "./list";

export const ServiceTypeRouter: RouteObject = {
  path: "/service-types",
  handle: { crumb: () => "Cadastros / Geral / Grupo de Serviço" },
  children: [
    {
      index: true,
      element: <ServiceTypes />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
