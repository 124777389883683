import { StatusTag } from "../../../components/StatusTag";
import { ContractStatus } from "../../../types";

export const colors = {
  CURRENT: { name: "ATIVO", color: "green" },
  ADDITIVE: { name: "ADITIVADO", color: "yellow" },
  CANCELED: { name: "CANCELADO", color: "red" },
  EXPIRED: { name: "EXPIRADO", color: "black" },
  TERMINATED: { name: "FINALIZADO", color: "pink" },
};

interface StatusTagsProps {
  status: ContractStatus;
  size?: "full";
}

const ContractStatusTag = ({ status, size }: StatusTagsProps) => {
  return (
    <StatusTag size={size ? size : undefined} color={colors[status].color}>
      {colors[status].name.toUpperCase()}
    </StatusTag>
  );
};

export { ContractStatusTag };
