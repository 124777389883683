import { ReactNode, FC, CSSProperties } from "react";
import { theme } from "antd";

interface ContentProps {
  children?: ReactNode;
  transparent?: boolean;
  margin?: boolean;
  width?: string;
  style?: CSSProperties;
}

const Content: FC<ContentProps> = ({
  children,
  width,
  margin,
  style,
  transparent,
}) => {
  const {
    token: { colorBgContainer, borderRadius, boxShadowTertiary },
  } = theme.useToken();

  return (
    <div
      style={{
        backgroundColor: transparent ? "transparent" : colorBgContainer,
        borderRadius: borderRadius,
        boxShadow: boxShadowTertiary,
        padding: "10px 10px",
        margin: margin ? "24px auto 0" : "0 auto",
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export { Content };
