import React from "react";

import { App, Button } from "antd";
import { ColumnsType } from "antd/es/table";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { useNavigate } from "react-router-dom";
import { api } from "../../../services/api";

import { Content } from "../../../components/Content";
import { IContract, IPagination } from "../../../types";
import { formatters } from "../../../utils/formatters";
import { ActionFunction } from "../../../components/ActionColumn";
import { ActionTable } from "../../../components/ActionTable";
import { ContractStatusTag } from "../components/ContractStatusTag";
import { PageHeader } from "../../../components/PageHeader";
import { ContractModal } from "../components/ContractModal";
import { getContracts } from "../../../services/repositories";
import { useModal } from "../../../hooks/useModal";
import { FilterContractsList } from "../components/FilterContractsList";
import { usePagination } from "../../../hooks";

export const getAllContracts = async (page = 1, perPage = 10, q?: any) => {
  return await api.get<IPagination<IContract>>(`/contracts`, {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const Contracts: React.FC = () => {
  const navigate = useNavigate();
  const { modal, message } = App.useApp();

  const {
    data: contracts,
    isLoading,
    pagination,
    handleRefreshData,
  } = usePagination(getContracts);

  const {
    data: contract,
    handleCloseModal,
    handleOpenModal,
    isOpen,
  } = useModal<IContract>(handleRefreshData);

  const onDeleteContract = async (record: IContract) => {
    return modal.confirm({
      title: "Deseja deletar o contrato?",
      content: <>O contrato será excluído permanentemente!</>,
      okText: "Deletar",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      cancelText: "Cancelar",
      cancelButtonProps: {
        type: "default",
        danger: true,
      },
      onOk: async () => {
        await api
          .delete(`/contracts/${record.id}`)
          .catch(() => message.error("Ops! Algo deu errado!"))
          .then(handleRefreshData);
      },
    });
  };

  const actions: ActionFunction<IContract> = (record) => {
    return [
      {
        key: "1",
        label: "Detalhar",
        icon: <SearchOutlined />,
        onClick: () => {
          navigate(`${record.id}`);
        },
      },
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => {
          handleOpenModal(record);
        },
      },
      {
        key: "3",
        label: "Deletar",
        icon: <DeleteOutlined />,
        danger: true,
        onClick: () => onDeleteContract(record),
      },
    ];
  };

  const columns: ColumnsType<IContract> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Contrato",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Ciente",
      dataIndex: "client",
      key: "client",
      ellipsis: true,
      render: (client) => client?.name,
    },
    {
      title: "Responsável",
      dataIndex: "responsible",
      key: "responsible",
      ellipsis: true,
      render: (user) => user?.name || "--",
    },
    {
      title: "Valor do contrato",
      dataIndex: "value",
      key: "value",
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>{formatters.currency(value)}</div>
        );
      },
    },
    {
      title: "Aberto em:",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => formatters.simpleDate(date),
    },
    {
      title: "Finaliza em:",
      dataIndex: "endDate",
      key: "endDate",
      render: (date) => formatters.simpleDate(date),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <ContractStatusTag status={text} />,
    },
  ];

  return (
    <>
      <ContractModal
        data={contract}
        isModalOpen={isOpen}
        onClose={handleCloseModal}
      />
      <PageHeader
        title="Contratos"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            key={"create_contract"}
            type="primary"
            onClick={() => handleOpenModal()}
          >
            Adicionar contrato
          </Button>,
        ]}
        crumb
      />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
        <FilterContractsList />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          pagination={pagination}
          actions={actions}
          loading={isLoading}
          columns={columns}
          dataSource={contracts}
        />
      </Content>
    </>
  );
};

export { Contracts };
