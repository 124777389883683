import { FC, useEffect, useState } from "react";
import { Col, DatePicker, DatePickerProps, Form, Select } from "antd";
import dayjs from "dayjs";

import { useSearchParams } from "react-router-dom";

import { IUser } from "../../../types";

import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { colors } from "../../orders/components/ServiceOrderStatusTag";
import { getUsers } from "../../users/list";

const { RangePicker } = DatePicker;

interface OrderFilterForm {
  id: string;
  clientId: string;
  contractId: string;
  date: Array<DatePickerProps["value"]>;
  status: string[];
  technical: string[];
}

interface FilterOrderListProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const FilterOrderList: FC<FilterOrderListProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm<OrderFilterForm>();

  const [, setSearchParams] = useSearchParams();
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        ...filter,
        date: filter.date
          ? filter.date.split(",").map((date: string) => dayjs(date))
          : undefined,
        status: filter.status ? filter.status?.split(",") : undefined,
      });
    }
  }, [form, filter]);

  useEffect(() => {
    if (users.length === 0) {
      getUsers(1, 1000, {
        profile: "TECHNICIAN",
      }).then(({ data }) => setUsers(data.data));
    }
  }, [filter, users.length]);

  const onFinish = (values: OrderFilterForm) => {
    const params = JSON.parse(
      JSON.stringify({
        ...values,
        date: values?.date
          ?.map((date) => date?.toISOString().slice(0, 10))
          .toString(),
        status: values?.status?.toString(),
        technical: values?.technical?.toString(),
      })
    );
    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const fields = [
    <Col span={6} key={4}>
      <Form.Item
        name={"technical"}
        label={"Técnico"}
        rules={[
          {
            required: true,
            message: "Por favor selecione um ou mais Técnico!",
          },
        ]}
      >
        <Select
          mode="multiple"
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={users.map((user) => {
            return {
              label: user.name,
              value: user.id,
            };
          })}
          placeholder="Selecione o técnico"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item
        name={"date"}
        label={"Período"}
        rules={[
          {
            required: true,
            message: "Por favor selecione um Período!",
          },
        ]}
      >
        <RangePicker format="DD/MM/YYYY" style={{ width: "100%" }} allowClear />
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item label="Status" name="status">
        <Select
          mode="multiple"
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={Object.entries(colors).map((key) => {
            return {
              label: key[1].name,
              value: key[0],
            };
          })}
          placeholder="Selecione um status"
        />
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterOrderList };
