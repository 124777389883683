import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { App, Button, Form, Modal, Spin } from "antd";

import { api } from "../../../services/api";

import { IActivity, IEquipment, ModalProps } from "../../../types";
import { usePmoc } from "../../../contexts/PmocContext";
import { CloseOutlined } from "@ant-design/icons";
import { FREQUENCIES } from "../../activities/components/ActivityModal";
import { Select } from "../../../components/Select";
import { getPmocActivities } from "../pmoc/EquipmentActivities";
import { getActivities, getEquipments } from "../../../services/repositories";

const PmocEquipmentModal: FC<ModalProps> = ({ onClose, isModalOpen }) => {
  const { id } = useParams();
  const { pmoc } = usePmoc();
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [equipments, setEquipments] = useState<IEquipment[]>([]);
  const [references, setReferences] = useState<IEquipment[]>([]);
  const [activies, setActivies] = useState<IActivity[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await getEquipments(
        { page: 1, perPage: 1000 },
        {
          notInPmocId: id,
          clientId: pmoc?.clientId,
          locals: pmoc?.locals?.map((local) => local.id),
        }
      ).then(({ data }) => {
        setEquipments(data);
      });
      await getEquipments(
        { page: 1, perPage: 1000 },
        {
          pmocId: id,
          clientId: pmoc?.clientId,
        }
      ).then(({ data }) => {
        setReferences(data);
      });
      await getActivities({ page: 1, perPage: 1000 }).then(({ data }) => {
        setActivies(data);
      });
    };
    if (isModalOpen) {
      setLoading(true);
      getData()
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }
  }, [isModalOpen, form, id, pmoc]);

  const handleCloseModal = (refresh: boolean = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then(() => {
        message.success(`Atividade adicionada com sucesso!`);
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    return await api.post(`/pmocs/${id}/equipments`, values);
  };

  const onSelecetReference = async (equipmentId: string) => {
    setLoading(true);
    await getPmocActivities(String(id), String(equipmentId), 1, 1000)
      .then(({ data }) => {
        let activities = [];
        for (let activity of data.data) {
          activities.push({
            activtyId: activity.activityId,
            frequency: activity.frequency,
          });
        }
        form.setFieldsValue({ activities });
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      title={"Adicinar Equipamento"}
      open={isModalOpen}
      width={900}
      centered
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="pmoc_equipment_form_modal">
          <Form.Item
            label="Equipmento"
            name="equipments"
            rules={[
              {
                required: true,
                message: "Por favor selecione um equipamento!",
              },
            ]}
          >
            <Select mode="multiple" options={equipments} />
          </Form.Item>
          {references.length > 0 && (
            <Form.Item
              label="Equipamento Referência"
              rules={[
                {
                  required: true,
                  message: "Por favor selecione um equipamento!",
                },
              ]}
            >
              <Select onSelect={onSelecetReference} options={references} />
            </Form.Item>
          )}
          <Form.Item
            label="Atividates"
            rules={[
              {
                required: true,
                message: "Por favor selecione uma atividade!",
              },
            ]}
            required
          >
            <Form.List name="activities">
              {(fields, { add, remove }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    rowGap: 16,
                    width: "100%",
                  }}
                >
                  {fields.map((field, index) => (
                    <div
                      key={field.key}
                      style={{
                        display: "flex",
                        gap: 16,
                      }}
                    >
                      <Form.Item
                        noStyle
                        name={[field.name, "activtyId"]}
                        rules={[
                          {
                            required: true,
                            message: "Selecione uma Atividade.",
                          },
                        ]}
                      >
                        <Select
                          style={{
                            minWidth: "80%",
                          }}
                          disabled
                          options={activies}
                        />
                      </Form.Item>
                      <Form.Item
                        noStyle
                        name={[field.name, "frequency"]}
                        rules={[
                          {
                            required: true,
                            message: "Selecione uma Frequencia.",
                          },
                        ]}
                      >
                        <Select
                          style={{
                            width: "20%",
                          }}
                          options={FREQUENCIES.map((frequency) => {
                            return {
                              id: frequency.value,
                              name: frequency.label.toUpperCase(),
                            };
                          })}
                        />
                      </Form.Item>
                      <CloseOutlined
                        onClick={() => {
                          remove(field.name);
                        }}
                      />
                    </div>
                  ))}
                  <Select
                    placeholder="Selecione uma Atividade"
                    onChange={(value) => {
                      let activiy = activies.find((item) => item.id === value);
                      if (activiy) {
                        add({
                          activtyId: activiy?.id,
                          frequency: activiy?.frequency,
                        });
                      }
                    }}
                    options={activies.filter(
                      (activiy) =>
                        !form
                          .getFieldValue("activities")
                          ?.some((item: any) => item.activtyId === activiy.id)
                    )}
                  />
                </div>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { PmocEquipmentModal };
