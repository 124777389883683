import { FC } from "react";

import { ColumnsType } from "antd/es/table";
import {
  CameraOutlined,
  CheckCircleTwoTone,
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";

import { PageHeader } from "../../../components/PageHeader";
import { usePagination } from "../../../hooks/useNewPagination";
import { getServiceActivities } from "../../../services/repositories";
import { ActionTable } from "../../../components/ActionTable";
import { FREQUENCIES } from "../../activities/components/ActivityModal";

const OrderServiceActivities: FC = () => {
  const {
    data: serviceItems,
    isLoading,
    pagination,
  } = usePagination(getServiceActivities);

  const actions = () => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        disabled: true,
      },
      {
        key: "2",
        icon: <CameraOutlined />,
        label: "Fotos",
        disabled: true,
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        disabled: true,
      },
    ];
  };

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "activity",
      key: "sid",
      width: 80,
      render: ({ sid }) => sid,
    },
    {
      title: "Nome",
      dataIndex: "activity",
      key: "name",
      ellipsis: true,
      width: "70%",
      render: ({ name }) => name,
    },
    {
      title: "Status",
      dataIndex: "isDone",
      key: "isDone",
      width: 120,
      render: (isDone) =>
        isDone ? (
          <CheckCircleTwoTone
            style={{
              fontSize: 32,
            }}
            twoToneColor="#52C41A"
          />
        ) : (
          <ExclamationCircleTwoTone
            style={{
              fontSize: 32,
            }}
            twoToneColor="#FF603B"
          />
        ),
    },
    {
      title: "Frequência",
      dataIndex: "frequency",
      key: "frequency",
      render: (_, record) =>
        FREQUENCIES.find(
          ({ value }) =>
            value === record.equipmentActivity.pmocEquipment.frequency
        )?.label.toUpperCase(),
    },
  ];

  return (
    <>
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Atividades"}
      />
      <ActionTable
        actions={actions}
        pagination={pagination}
        dataSource={serviceItems}
        loading={isLoading}
        columns={columns}
      />
    </>
  );
};

export { OrderServiceActivities };
