import { FC, useEffect, useState } from "react";
import { App, Button, DatePicker, Form, Input, Modal, Spin } from "antd";
import { api } from "../../../services/api";

import dayjs from "dayjs";

import { IClient, IContract, IUser, ModalProps } from "../../../types";
import { formatters } from "../../../utils";
import { Select } from "../../../components/Select";
import { getUsers } from "../../users/list";
import { getClients } from "../../../services/repositories";

const ContractModal: FC<ModalProps<IContract>> = ({
  onClose,
  isModalOpen,
  data: contract,
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [clients, setClients] = useState<IClient[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState<dayjs.Dayjs>();

  useEffect(() => {
    const getData = async () => {
      await getClients(1, 1000).then(({ data }) => {
        setClients(data);
      });
      if (contract) {
        form.setFieldsValue({
          ...contract,
          startDate: dayjs(
            formatters.simpleDate(contract.startDate),
            "DD/MM/YYYY"
          ),
          endDate: dayjs(formatters.simpleDate(contract.endDate), "DD/MM/YYYY"),
        });
      }
    };
    if (isModalOpen) {
      getData().finally(() => setLoading(false));
    }
  }, [contract?.clientId, contract?.id, isModalOpen, contract, form]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    setDate(undefined);
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => console.log("Validate Failed:", info))
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Contrato ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitForm = async (values: IContract) => {
    if (contract) {
      return await api.put<IContract>(`/contracts/${contract?.id}`, values);
    }
    return await api.post<IContract>(`/contracts`, values);
  };

  const disabledDate = (current: dayjs.Dayjs) => {
    if (date) {
      return current && current <= date.add(1, "day");
    }
    return true;
  };

  const handleLoadUsers = async () => {
    if (users.length > 0) {
      return;
    }
    await getUsers(1, 1000, {
      profile: "ATTENDANT",
    }).then(({ data }) => {
      setUsers(data.data);
    });
  };

  return (
    <Modal
      title={`${contract ? "Editar" : "Adicionar"} contrato`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      width={700}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {contract ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="contract_form_modal">
          <Form.Item
            name="clientId"
            label="Cliente:"
            rules={[
              {
                required: true,
                message: "Por favor, selecione um cliente.",
              },
            ]}
          >
            <Select
              disabled={!!contract}
              options={clients}
              placeholder="Selecione um cliente"
            />
          </Form.Item>
          <Form.Item
            name="responsibleId"
            label="Responsável:"
          >
            <Select
              options={users}
              handleLoadData={() => handleLoadUsers()}
              placeholder="Selecione um responsável"
            />
          </Form.Item>
          <Form.Item
            name="name"
            label="Nome:"
            rules={[
              {
                required: true,
                message: "Por favor, insira o nome do contrato.",
              },
            ]}
          >
            <Input placeholder="Insira um nome" />
          </Form.Item>
          <Form.Item
            name="object"
            label="Objeto:"
            rules={[
              {
                required: true,
                message: "Por favor, insira o objeto do contrato.",
              },
            ]}
          >
            <Input.TextArea
              showCount
              maxLength={1000}
              rows={5}
              placeholder="Insira um objeto do contrato"
            />
          </Form.Item>
          <Form.Item
            label="Vigencia:"
            required
            style={{
              margin: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "baseline",
                gap: 16,
              }}
            >
              <Form.Item
                name="startDate"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
                rules={[
                  {
                    required: true,
                    message: "Por favor insira a data de incio!",
                  },
                ]}
              >
                <DatePicker
                  disabled={!!contract}
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  onChange={(value) => {
                    setDate(value || undefined);
                  }}
                />
              </Form.Item>
              até
              <Form.Item
                name="endDate"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 8px)",
                }}
                rules={[
                  {
                    required: true,
                    message: "Por favor insira a data de fim!",
                  },
                ]}
              >
                <DatePicker
                  disabled={!!contract}
                  picker="date"
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ContractModal };
