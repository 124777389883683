import { Column, Pie } from "@ant-design/charts";
import { DualAxes } from "@ant-design/plots";

import { Badge, Card, Col, Row, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { api } from "../../../services/api";

type ServiceData = {
  name: string;
  count: number;
  ranking?: string;
};

type TotalServicesData = {
  service: number;
  revenue: number;
  year: string;
};

type EquipmentType = {
  type: string;
  value: number;
};

type ServiceType = {
  type: string;
  value: number;
};

type ChartsProps = {
  rankingByService: ServiceData[];
};

export const Charts = ({ rankingByService }: ChartsProps) => {
  const cardStyle = {
    margin: "0 auto",
    marginTop: 24,
  };

  const [equipmentType, setEquipmentType] = useState<EquipmentType[]>([]);
  const [serviceType, setServiceType] = useState<ServiceType[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: equipmentResponse } = await api.get(
          "/dashboard/equipment-types"
        );
        const { data: serviceResponse } = await api.get(
          "/dashboard/service-types"
        );

        if (equipmentResponse) {
          const data = equipmentResponse
            .filter((item: any) => item.meta.total > 0)
            .map((item: any) => ({
              type: item.name,
              value: parseFloat(item.meta.total),
            }));

          setEquipmentType(data);
        }

        if (serviceResponse) {
          const data = serviceResponse
            .filter((item: any) => item.meta.total > 0)
            .map((item: any) => ({
              type: item.name,
              value: parseFloat(item.meta.total),
            }));

          setServiceType(data);
        }
      } catch (error) {
        console.error("Ocorreu um erro ao buscar os dados:", error);
      }
    };

    fetchData();
  }, []);

  const rankedData: ServiceData[] = rankingByService
    ?.sort((a, b) => b.count - a.count)
    ?.map((item, index) => ({ ...item, ranking: `${index + 1}` }));

  const rankingToNameMap: Record<string, string> = rankedData?.reduce(
    (acc, item) => {
      acc[item.ranking as string] = item.name;
      return acc;
    },
    {} as Record<string, string>
  );

  const configColumn = {
    data: rankedData,
    xField: "ranking",
    yField: "count",
    columnWidthRatio: 0.4,
    color: "#0001fe",
    tooltip: {
      showTitle: false,
      formatter: (datum: any) => {
        const name = rankingToNameMap[datum.ranking];
        return { name: name, value: `Serviços: ${datum.count}` };
      },
    },
  };

  const billingAndTotalServices: TotalServicesData[] = [
    { year: "2020", service: 20, revenue: 5000 },
    { year: "2021", service: 18, revenue: 4500 },
    { year: "2022", service: 20, revenue: 5500 },
    { year: "2023", service: 35, revenue: 7000 },
  ];

  const configDualAxes = {
    data: [billingAndTotalServices, billingAndTotalServices],
    xField: "year",
    yField: ["service", "revenue"],
    geometryOptions: [
      {
        geometry: "line",
        color: "#5B8FF9",
      },
      {
        geometry: "line",
        color: "#5AD8A6",
      },
    ],
    legend: {
      itemName: {
        formatter: (text: string) => {
          return text === "service" ? "Serviços" : "Faturamento";
        },
      },
    },
  };

  equipmentType.sort((a, b) => b.value - a.value);
  const serviceByEquipmentTypeTotal = equipmentType.reduce(
    (r, d) => r + d.value,
    0
  );

  const configPie = {
    appendPadding: 5,
    data: equipmentType,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.7,
    meta: {
      value: {
        formatter: (v: number) =>
          `${((v / serviceByEquipmentTypeTotal) * 100).toFixed(2)}%`,
      },
    },
    label: {
      type: "inner",
      content: "{value}",
    },
    interactions: [
      { type: "element-selected" },
      { type: "element-active" },
      { type: "pie-statistic-active" },
    ],
    legend: {
      itemName: {
        formatter: (text: any) => {
          const item = equipmentType.find((d) => d.type === text);
          if (item) {
            return `${text} ${(
              (item.value / serviceByEquipmentTypeTotal) *
              100
            ).toFixed(2)}% (${item.value})`;
          }
          return text;
        },
      },
    },
  };

  serviceType.sort((a, b) => b.value - a.value);
  const serviceByTypeTotal = serviceType.reduce((r, d) => r + d.value, 0);

  const configPieServiceByType = {
    appendPadding: 5,
    data: serviceType,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.7,
    meta: {
      value: {
        formatter: (v: number) =>
          `${((v / serviceByTypeTotal) * 100).toFixed(2)}%`,
      },
    },
    label: {
      type: "inner",
      content: "{value}",
    },
    interactions: [
      { type: "element-selected" },
      { type: "element-active" },
      { type: "pie-statistic-active" },
    ],
    legend: {
      itemName: {
        formatter: (text: any) => {
          const item = serviceType.find((d) => d.type === text);
          if (item) {
            return `${text} ${((item.value / serviceByTypeTotal) * 100).toFixed(
              2
            )}% (${item.value})`;
          }
          return text;
        },
      },
    },
  };

  return (
    <>
      <Card style={cardStyle}>
        <Typography.Text>Ranking de Técnico por Serviço</Typography.Text>
        <Row gutter={[50, 16]} style={{ marginTop: 40 }}>
          <Col span={16}>
            <Column {...configColumn} />
          </Col>
          <Col span={6}>
            <div style={{ display: "flex", gap: 36 }}>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 14 }}
              >
                <Typography.Text style={{ marginBottom: 6 }}>
                  Ranking por nome
                </Typography.Text>
                {rankingByService
                  .sort((a, b) => b.count - a.count)
                  .map((item, index) => (
                    <Space
                      direction="horizontal"
                      size="middle"
                      key={item.ranking}
                    >
                      <Badge count={index + 1} size="default" color="#3030DA" />
                      <Typography.Text>{item.name}</Typography.Text>
                    </Space>
                  ))}
              </div>
              <div
                style={{ display: "flex", flexDirection: "column", gap: 14 }}
              >
                <Typography.Text style={{ marginBottom: 6 }}>
                  Atendimentos
                </Typography.Text>
                {rankingByService
                  .sort((a, b) => b.count - a.count)
                  .map((item) => (
                    <Typography.Text key={item.name}>
                      {item.count}
                    </Typography.Text>
                  ))}
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <Card style={cardStyle}>
        <Typography.Text style={{ display: "block", marginBottom: 40 }}>
          Faturamento e serviços totais
        </Typography.Text>

        <DualAxes {...configDualAxes} />
      </Card>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          margin: "0 auto",
          marginTop: "24px",
        }}
      >
        <Card style={{ width: "55%" }}>
          <Typography.Text>Atendimento por tipo de equipamento</Typography.Text>
          <Pie style={{ marginTop: 40 }} {...configPie} />
        </Card>
        <Card style={{ width: "45%" }}>
          <Typography.Text>Atendimento por tipo de serviço</Typography.Text>
          <Pie style={{ marginTop: 40 }} {...configPieServiceByType} />
        </Card>
      </div>
    </>
  );
};
