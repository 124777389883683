const parsers = {
  phone(value?: string) {
    if (!value) {
      return "";
    }

    value = value.replace(/\D/g, "");
    return value;
  },

  currency(value: any) {
    var tmp = parseInt(value!.replace(/[\D]+/g, ""));
    return tmp / 100;
  },

  identifier(text?: string) {
    if (!text) {
      return "";
    }

    text = text.replace(/\D/g, "");
    return text;
  },
};

export { parsers };
