import { FC, useState } from "react";

import { Button, Form, Input, App, Modal, Spin } from "antd";

import { api } from "../../../services/api";
import { IContract, ILocal, ModalProps } from "../../../types";

const CreateLocalModal: FC<ModalProps<IContract>> = ({
  onClose,
  isModalOpen,
  data: contract,
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleCloseModal = (refresh: boolean = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) =>
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        )
      )
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    await handleSubmit(values)
      .then(() => message.success("Local criado com sucesso!"))
      .catch(() => message.error("Algo inesperado ocorreu!"))
      .finally(() => setLoading(false));

    handleCloseModal(true);
  };

  const handleSubmit = async (values: any) => {
    if (contract?.clientId && values) {
      return api.post<ILocal>(`/locals/${contract.clientId}`, values);
    }
  };

  return (
    <Modal
      title={"Criar Local"}
      open={isModalOpen}
      centered
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="local_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome da Local!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Endereço"
            name="address"
            rules={[
              {
                required: true,
                message: "Por favor insira o endereço do Local!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Descrição" name="description">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { CreateLocalModal };
