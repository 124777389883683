import { FC, useState } from "react";
import { App, Button, Form, Input, Modal, Spin } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import { ModalProps } from "../../../types";
import { renderField } from "./RenderField";

interface InsertModalProps extends ModalProps<String> {
  insert: (value: any) => void;
}

const InsertFieldModal: FC<InsertModalProps> = ({
  onClose,
  insert,
  isModalOpen,
  data: type,
}) => {
  const { message } = App.useApp();
  const [form] = Form.useForm<any>();

  const [loading, setLoading] = useState(false);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          name: values.name || type,
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    insert({ ...values, element: type });

    handleCloseModal();
  };

  return (
    <Modal
      title={`Adicionar campo`}
      open={isModalOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Adicionar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          {renderField(type)}
          {type === "Select" && (
            <Form.List
              name="options"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 2) {
                      return Promise.reject(
                        new Error("Insira ao menos duas opções.")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Form.Item
                      label={index === 0 ? "Opções" : ""}
                      required={true}
                      key={field.key}
                    >
                      <div
                        key={field.key}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: 16,
                        }}
                      >
                        <Form.Item
                          {...field}
                          validateTrigger={["onChange", "onBlur"]}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                "Por favor insira o nome da opção ou exclua este campo.",
                            },
                            ({ getFieldValue }) => ({
                              validator() {
                                let options: string[] | undefined =
                                  getFieldValue("options");
                                let array = options?.filter(
                                  (item, index) =>
                                    options?.indexOf(item) !== index
                                );
                                if (array?.length === 0) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "Nao é possivel ter duas opções iguais!"
                                  )
                                );
                              },
                            }),
                          ]}
                          noStyle
                        >
                          <Input placeholder={"Opção " + (index + 1)} />
                        </Form.Item>
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      </div>
                    </Form.Item>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Adicionar opção
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
          )}
          {type === "Checkbox" && (
            <Form.List
              name="options"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 2) {
                      return Promise.reject(
                        new Error("Insira ao menos duas opções.")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => {
                return (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={index === 0 ? "Opções" : ""}
                        required={true}
                        key={field.key}
                      >
                        <div
                          key={field.key}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            gap: 16,
                          }}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Por favor insira o nome da opção ou exclua este campo.",
                              },
                              ({ getFieldValue }) => ({
                                validator() {
                                  let options: string[] | undefined =
                                    getFieldValue("options");
                                  let array = options?.filter(
                                    (item, index) =>
                                      options?.indexOf(item) !== index
                                  );
                                  if (array?.length === 0) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      "Nao é possivel ter duas opções iguais!"
                                    )
                                  );
                                },
                              }),
                            ]}
                            noStyle
                          >
                            <Input placeholder={"Opção " + (index + 1)} />
                          </Form.Item>
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                          />
                        </div>
                      </Form.Item>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: "100%" }}
                        icon={<PlusOutlined />}
                      >
                        Adicionar opção
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                );
              }}
            </Form.List>
          )}
        </Form>
      </Spin>
    </Modal>
  );
};

export { InsertFieldModal };
