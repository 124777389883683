import { FC } from "react";

import { Button } from "antd";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";

import { IBrand } from "../../../types";
import { BrandModal } from "../components/BrandModal";
import { FilterBrandList } from "../components/FilterBrandList";
import { getBrands } from "../../../services/repositories";
import { ActionTable } from "../../../components/ActionTable";
import { useDelete, useModal, usePagination } from "../../../hooks";

const Brands: FC = () => {
  const {
    data: brands,
    isLoading,
    filter,
    setFilter,
    handleRefreshData,
    pagination,
  } = usePagination(getBrands);

  const {
    data: brand,
    isOpen,
    handleCloseModal,
    handleOpenModal,
  } = useModal<IBrand>(handleRefreshData);

  const { handleDeleteData } = useDelete(handleRefreshData);

  const actions = (record: IBrand) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () =>
          handleDeleteData(`/brands/${record.id}`, "Deseja deletar a marca?"),
      },
    ];
  };

  const columns: ColumnsType<IBrand> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
  ];

  return (
    <>
      <PageHeader
        title="Marcas"
        backButton={false}
        actions={[
          <Button
            key={"create_item"}
            onClick={() => handleOpenModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar marca
          </Button>,
        ]}
        crumb
      />
      <Content margin style={{ padding: "24px 40px 0" }}>
        <FilterBrandList onSubmit={setFilter} filter={filter} />
      </Content>
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          loading={isLoading}
          columns={columns}
          dataSource={brands}
        />
      </Content>
      <BrandModal
        onClose={handleCloseModal}
        isModalOpen={isOpen}
        data={brand}
      />
    </>
  );
};

export { Brands };
