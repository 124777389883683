import { FC } from "react";

import { App, Button, Tag } from "antd";
import { ColumnsType } from "antd/es/table";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { api } from "../../../services/api";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { FilterItemList } from "../components/FilterItemList";
import { IItem } from "../../../types";
import { ItemModal } from "../components/ItemModal";
import { formatters } from "../../../utils";
import { getItems } from "../../../services/repositories";
import { ActionTable } from "../../../components/ActionTable";
import { useModal, usePagination } from "../../../hooks";

const Items: FC = () => {
  const { modal, message } = App.useApp();

  const {
    data: items,
    isLoading,
    filter,
    setFilter,
    handleRefreshData,
    pagination,
  } = usePagination(getItems);

  const {
    data: item,
    isOpen,
    handleOpenModal,
    handleCloseModal,
  } = useModal<IItem>(handleRefreshData);

  const actions: ActionFunction<IItem> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteItem(record),
      },
    ];
  };

  const columns: ColumnsType<IItem> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      width: 300,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    {
      title: "Unidade",
      dataIndex: "unit",
      key: "unit",
      width: 80,
    },
    {
      title: "Consumível",
      dataIndex: "isConsumible",
      key: "isConsumible",
      render: (text) =>
        text === true ? (
          <Tag color={"green"}>SIM</Tag>
        ) : (
          <Tag color={"red"}>NÃO</Tag>
        ),
      width: 120,
    },
    {
      title: "Avulso",
      dataIndex: "isDetached",
      key: "isDetached",
      render: (text) =>
        text === true ? (
          <Tag color={"green"}>SIM</Tag>
        ) : (
          <Tag color={"red"}>NÃO</Tag>
        ),
      width: 120,
    },
    {
      title: "Preço Base",
      dataIndex: "value",
      key: "value",
      render: (value) => formatters.currency(value),
      width: 120,
    },
    {
      title: "Custo Base",
      dataIndex: "cost",
      key: "cost",
      render: (value) => formatters.currency(value),
      width: 120,
    },
  ];

  const onDeleteItem = async (record: IItem) => {
    return modal.confirm({
      title: "Atenção!",
      content: (
        <>
          Deseja Realmente deletar esse registro?
          <br />
          <i>'{record.name}'</i>
        </>
      ),
      onOk: () =>
        api
          .delete(`/items/${record.id}`)
          .catch(() => message.error(`Algo inesperado ocorreu!`))
          .then(handleRefreshData),
    });
  };

  return (
    <>
      <PageHeader
        title="Itens de serviços"
        backButton={false}
        actions={[
          <Button
            key={"create_item"}
            onClick={() => handleOpenModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar item
          </Button>,
        ]}
        crumb
      />
      <ItemModal onClose={handleCloseModal} isModalOpen={isOpen} data={item} />
      <Content margin>
        <FilterItemList onSubmit={setFilter} filter={filter} />
      </Content>
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          loading={isLoading}
          columns={columns}
          dataSource={items}
        />
      </Content>
    </>
  );
};

export { Items };
