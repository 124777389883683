import { RouteObject } from "react-router-dom";
import { Client } from "./client";
import { Locals } from "./client/ClientLocals";
import { Units } from "./client/ClientLocalUnits";
import { Sectors } from "./client/ClientUnitSectors";
import { Clients } from "./list";
import { loader } from "../../utils/loader";
import { ClientUsers } from "./client/ClientUsers";
import { ClientEquipments } from "./client/ClientEquipments";
import { api } from "../../services/api";
import { IClient, ILocal, IUnit } from "../../types";
import { LocalsHeader } from "./components/LocalsHeader";

export const ClientRouter: RouteObject = {
  path: "/clients",
  handle: { crumb: () => "Cadastros / Clientes" },
  children: [
    {
      index: true,
      loader: loader,
      element: <Clients />,
    },
    {
      path: ":id",
      loader: async ({ params }) => {
        const { data } = await api.get<IClient>(`/clients/${params.id}`);
        return data;
      },
      handle: {
        crumb: (data?: IClient) => data?.name,
      },
      element: <Client />,
      children: [
        {
          path: "locals",
          handle: { crumb: () => "Locais" },
          element: <Locals />,
          children: [
            {
              path: ":localId",
              loader: async ({ params }) => {
                const { data } = await api.get<ILocal>(
                  `/locals/${params.localId}`
                );
                return data;
              },
              handle: {
                crumb: (data?: ILocal) => data?.name,
              },
              element: <LocalsHeader title="Local" />,
              children: [
                {
                  path: "units",
                  handle: { crumb: () => "Unidades" },
                  element: <Units />,
                  children: [
                    {
                      path: ":unitId",
                      loader: async ({ params }) => {
                        const { data } = await api.get<IUnit>(
                          `/units/${params.unitId}`
                        );
                        return data;
                      },
                      handle: {
                        crumb: (data?: IUnit) => data?.name,
                      },
                      element: <LocalsHeader title="Unidade" />,
                      children: [
                        {
                          path: "sectors",
                          handle: { crumb: () => "Setores" },
                          element: <Sectors />,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          path: "users",
          handle: { crumb: () => "Central do Cliente" },
          element: <ClientUsers />,
        },
        {
          path: "equipments",
          handle: { crumb: () => "Equipamentos" },
          element: <ClientEquipments />,
        },
      ],
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
