import { FC, useEffect } from "react";
import { Col, DatePicker, DatePickerProps, Form } from "antd";
import dayjs from "dayjs";

import { useSearchParams } from "react-router-dom";
import { ExpandableFilter } from "../../../../components/ExpandableFilter";

const { RangePicker } = DatePicker;

interface FilterForm {
  endDate: Array<DatePickerProps["value"]>;
}

interface FilterOrderListProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const Filter: FC<FilterOrderListProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm();

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        endDate: filter.endDate
          ? filter.endDate.map((endDate: string) => dayjs(endDate))
          : undefined,
      });
    }
  }, [form, filter]);

  const onFinish = (values: FilterForm) => {
    const params = JSON.parse(
      JSON.stringify({
        ...values,
        endDate: values?.endDate?.map((endDate) =>
          endDate?.toISOString().slice(0, 10)
        ),
      })
    );
    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const fields = [
    <Col span={6} key={1}>
      <Form.Item shouldUpdate noStyle>
        <Form.Item
          name={"endDate"}
          label={"Período"}
          rules={[
            {
              required: true,
              message: "Por favor selecione um Período!",
            },
          ]}
        >
          <RangePicker
            format="DD/MM/YYYY"
            style={{ width: "100%" }}
            allowClear
          />
        </Form.Item>
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { Filter };
