import { FC, useEffect, useState } from "react";
import { Form, FormInstance, Select, Switch } from "antd";

import { getClientLocals } from "../../clients/client/ClientLocals";
import { getLocalUnits } from "../../clients/client/ClientLocalUnits";
import { getServiceTypes } from "../../serviceTypes/list";
import { getEquipmentTypes } from "../../equipmentTypes/list";

import {
  IClient,
  IEquipmentType,
  ILocal,
  IServiceType,
  IUnit,
} from "../../../types";
import { getClients } from "../../../services/repositories";

interface BudgetOrderFormProps {
  form: FormInstance;
}

const BudgetOrderForm: FC<BudgetOrderFormProps> = ({ form }) => {
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);

  const [equipmentTypes, setEquipmentTypes] = useState<IEquipmentType[]>([]);
  const [serviceTypes, setServiceTypes] = useState<IServiceType[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getClients(1, 1500).then(({ data }) => {
        setClients(data);
      });
      await getServiceTypes(1, 1000).then(({ data }) => {
        setServiceTypes(data.data);
      });
      await getEquipmentTypes(1, 1000).then(({ data }) => {
        setEquipmentTypes(data.data);
      });
    };
    getData();
  }, [form]);

  const handleChangeClient = async (clientId?: string) => {
    form.setFieldValue("localId", null);
    if (clientId) {
      await getClientLocals(clientId, 1, 1000).then(({ data }) => {
        setLocals(data.data);
      });
    }
  };

  const handleChangeLocal = async (localId?: string) => {
    form.setFieldValue("unitId", null);
    setUnits([]);
    if (localId) {
      await getLocalUnits(localId, 1, 1000).then(({ data }) => {
        setUnits(data.data);
      });
    }
  };

  return (
    <>
      <Form.Item
        label="Cliente:"
        name="clientId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o cliente!",
          },
        ]}
      >
        <Select
          optionFilterProp="children"
          showSearch
          allowClear
          options={clients.map((client) => {
            return {
              label: client.name,
              value: client.id,
            };
          })}
          onChange={handleChangeClient}
          placeholder="Selecione um cliente"
        />
      </Form.Item>
      <Form.Item
        label="Local:"
        name="localId"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o local!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={locals.map((local) => {
            return {
              label: local.name,
              value: local.id,
            };
          })}
          onChange={handleChangeLocal}
          placeholder="Selecione um local"
        />
      </Form.Item>
      {units.length > 0 && (
        <Form.Item label="Unidade:" name="unitId">
          <Select
            optionLabelProp="label"
            optionFilterProp="label"
            showSearch
            allowClear
            options={units.map((unit) => {
              return {
                label: unit.name,
                value: unit.id,
              };
            })}
            placeholder="Selecione uma unidade"
          />
        </Form.Item>
      )}
      <Form.Item
        label="Tipos de Serviço:"
        name="serviceTypes"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o tipo de serviço!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          mode="multiple"
          showSearch
          allowClear
          options={serviceTypes.map((serviceType) => {
            return {
              label: serviceType.name,
              value: serviceType.id,
            };
          })}
          placeholder="Selecione o tipo de serviço"
        />
      </Form.Item>
      <Form.Item
        label="Tipos de Equipamento:"
        name="equipmentTypes"
        rules={[
          {
            required: true,
            message: "Por favor, selecione o tipo de equipamento!",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={equipmentTypes.map((equipmentType) => {
            return {
              label: equipmentType.name,
              value: equipmentType.id,
            };
          })}
          placeholder="Selecione o tipo de equipamento"
        />
      </Form.Item>
      <Form.Item
        label="Necessita de Equipamento?"
        name="isRequiredEquipment"
        valuePropName="checked"
      >
        <Switch />
      </Form.Item>
    </>
  );
};

export { BudgetOrderForm };
