import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { App, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  CameraOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { IService } from "../../../types";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { useServiceOrder } from "../../../contexts/OrderContext";
import { ServiceModal } from "../components/ServiceModal";
import { ActionTable } from "../../../components/ActionTable";

import { useModal } from "../../../hooks/useModal";
import { usePagination } from "../../../hooks/useNewPagination";

import { api } from "../../../services/api";
import { getServices } from "../../../services/repositories";

const allowedStatus = new Set([
  "EXECUTION",
  "REOPENED",
  "DELAYED",
  "FINALIZED",
]);

const OrderServices: FC = () => {
  const navigate = useNavigate();
  const { modal, message } = App.useApp();

  const { serviceOrder } = useServiceOrder();

  const {
    data: services,
    isLoading,
    pagination,
    handleRefreshData,
  } = usePagination(getServices);

  const {
    data: service,
    handleCloseModal,
    handleOpenModal,
    isOpen,
  } = useModal<IService>(handleRefreshData);

  const onDeleteService = async (record: IService) => {
    return modal.confirm({
      title: "Deseja deletar esse serviço?",
      content: "O serviço será excluído permanentemente!",
      okText: "Deletar",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      cancelText: "Cancelar",
      cancelButtonProps: {
        type: "default",
        danger: true,
      },
      onOk: async () => {
        await api
          .delete(`/service-orders/${serviceOrder?.id}/services/${record.id}`)
          .catch(() => message.error("Ops! Algo deu errado!"))
          .then(handleRefreshData);
      },
    });
  };

  const actions = (record: IService) => {
    return [
      {
        key: "1",
        icon: <SearchOutlined />,
        label: "Detalhar",
        onClick: () =>
          navigate(
            `${record.id}/${
              serviceOrder?.type === "PMOC" ? "activities" : "items"
            }`
          ),
      },
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        icon: <CameraOutlined />,
        label: "Fotos",
        onClick: () => navigate(`${record.id}/photos`),
      },
      {
        key: "4",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteService(record),
      },
    ];
  };

  const columns: ColumnsType<IService> = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      width: "50%",
      ellipsis: true,
    },
    {
      title: "Unidade",
      dataIndex: "unit",
      key: "unit",
      ellipsis: true,
      render: (unit) => unit?.name,
    },
    {
      title: "Setor",
      dataIndex: "sector",
      key: "sector",
      ellipsis: true,
      render: (sector) => sector?.name,
    },
    {
      title: "Equipamento",
      dataIndex: "equipment",
      key: "equipment",
      ellipsis: true,
      render: (equipment) => equipment?.name ?? "--",
    },
  ];

  const ORDER_STATUS = serviceOrder && allowedStatus.has(serviceOrder.status);

  return (
    <Content width="calc(100% - 48px)">
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Serviços Realizados"}
        actions={[
          <Button
            key={"add_service"}
            type="primary"
            disabled={
              (serviceOrder?.type === "BUDGET" &&
                serviceOrder?.status === "VALIDATED") ||
              !ORDER_STATUS
            }
            onClick={() => handleOpenModal()}
            icon={<PlusOutlined />}
          >
            Adicionar Serviço
          </Button>,
        ]}
      />
      <ServiceModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        data={service}
      />
      <ActionTable
        actions={actions}
        pagination={pagination}
        loading={isLoading}
        columns={columns}
        dataSource={services}
      />
    </Content>
  );
};

export { OrderServices };
