import { useState, useEffect, useCallback, FC } from "react";
import { Outlet, useLoaderData, useNavigate } from "react-router-dom";

import { App, Button, Descriptions, Spin, Switch, Typography } from "antd";
import {
  CheckOutlined,
  ClockCircleOutlined,
  ReconciliationOutlined,
  RetweetOutlined,
  StopOutlined,
} from "@ant-design/icons";

import { ServiceOrderStatusTag } from "../components/ServiceOrderStatusTag";

import { api } from "../../../services/api";
import { IServiceOrder } from "../../../types";
import { formatters } from "../../../utils/formatters";
import { MenuItem, PageHeader } from "../../../components/PageHeader";
import { useServiceOrder } from "../../../contexts/OrderContext";
import { ApproveBudgetModal } from "../components/ApproveBudgetModal";

const { Text, Paragraph } = Typography;

const { Item } = Descriptions;

const ServiceOrder: FC = () => {
  const data = useLoaderData() as IServiceOrder;
  const navigate = useNavigate();

  const { message, modal } = App.useApp();

  const { handleSetServiceOrder } = useServiceOrder();

  const [serviceOrder, setServiceOrder] = useState<IServiceOrder>(data);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const items: MenuItem[] = [
    {
      label: "Linha do Tempo",
      key: "time-line",
      icon: <ClockCircleOutlined />,
    },
    {
      label: "Serviços Realizados",
      key: "services",
      icon: <ReconciliationOutlined />,
    },
  ];

  const getData = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/service-orders/${serviceOrder.id}`)
      .then(({ data }) => {
        setServiceOrder(data);
      })
      .catch((error) => {
        if (error?.response?.data?.code === "E_ROW_NOT_FOUND") {
          navigate("/orders");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [navigate, serviceOrder.id]);

  useEffect(() => {
    handleSetServiceOrder(serviceOrder);
  }, [handleSetServiceOrder, serviceOrder]);

  const handleValidateOrder = async () => {
    await api
      .put(`/service-orders/${serviceOrder?.id}/validate`)
      .then(() => message.info("Ordem de Serviço está sendo validada."))
      .catch(() => message.error("Algo inesperado ocorreu."))
      .finally(() => navigate(-1));
  };

  const handleReopenOrder = async () => {
    await api
      .put(`/service-orders/${serviceOrder?.id}/reopen`)
      .then(() => {
        message.success("Ordem de Serviço reaberta.");
        getData();
      })
      .catch(() => message.error("Algo inesperado ocorreu."))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleApproveBudget = async () => {
    setIsModalOpen(true);
  };

  const handleDisapproveBudget = async () => {
    modal.confirm({
      title: "Deseja reprovar o Orçamento?",
      content: "Atenção! Ação não reversível.",
      closable: true,
      maskClosable: true,
      cancelText: "Cancelar",
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        await api
          .put(`/service-orders/${serviceOrder?.id}/disapprove-budget`)
          .then(() => {
            message.success("Orçamento reprovado.");
            getData();
          })
          .catch(() => message.error("Algo inesperado ocorreu."))
          .finally(() => setLoading(false));
      },
    });
  };

  const filterActions = () => {
    let actions = [
      <Button
        key={"validate"}
        disabled={serviceOrder?.status !== "FINALIZED"}
        icon={<CheckOutlined />}
        onClick={handleValidateOrder}
      >
        Validar
      </Button>,
      <Button
        disabled={
          serviceOrder?.type === "BUDGET" ||
          (serviceOrder?.status !== "VALIDATED" &&
            serviceOrder?.status !== "FINALIZED")
        }
        key={"reopen"}
        icon={<RetweetOutlined />}
        onClick={handleReopenOrder}
      >
        Reabrir
      </Button>,
      <Button
        key={"approve"}
        disabled={
          serviceOrder?.type !== "BUDGET" ||
          (serviceOrder?.status === "VALIDATED" &&
            serviceOrder?.type === "BUDGET")
        }
        icon={<CheckOutlined />}
        onClick={handleApproveBudget}
      >
        Aprovar Orçamento
      </Button>,
      <Button
        key={"disapprove"}
        disabled={
          serviceOrder?.type !== "BUDGET" ||
          (serviceOrder?.status === "VALIDATED" &&
            serviceOrder?.type === "BUDGET")
        }
        icon={<StopOutlined />}
        onClick={handleDisapproveBudget}
      >
        Reprovar Orçamento
      </Button>,
    ];

    return actions.filter((item) => !item.props.disabled);
  };

  const actions = filterActions();

  return (
    <Spin spinning={loading}>
      <ApproveBudgetModal
        data={serviceOrder}
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      <PageHeader
        title={
          `${
            serviceOrder?.type === "BUDGET" ? "Orçamento" : "Ordem de Serviço"
          }: ` + serviceOrder?.sid
        }
        menuItems={items}
        actions={actions}
        crumb={true}
      >
        <Descriptions size="small" column={4}>
          <Item label="Tipo de atendimento">
            <Text strong>{serviceOrder?.type}</Text>
          </Item>
          <Item label="Cliente">
            <Text strong>{serviceOrder?.client?.name}</Text>
          </Item>
          <Item label="N° de Referência">
            <Text strong style={{ color: "#1668dc" }}>
              {serviceOrder?.referenceNumber}
            </Text>
          </Item>
          <Item label="Previsão de Atendimento">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.simpleDate(serviceOrder?.startTo)}
            </Text>
          </Item>
          <Item label="Tipo de serviço">
            <Text strong>
              {serviceOrder?.serviceTypes
                ?.map((type) => type.name)
                .toString()
                .replaceAll(",", ", ")}
            </Text>
          </Item>
          <Item label="Contrato">
            <Text
              strong
              title={serviceOrder?.contract?.name}
              style={{ width: "100%" }}
              ellipsis={{
                suffix: "",
              }}
            >
              {serviceOrder?.contract?.name}
            </Text>
          </Item>
          <Item label="ID Real">
            <Text strong>{serviceOrder?.id}</Text>
          </Item>
          <Item label="Previsão de Conclusão">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.simpleDate(serviceOrder?.endTo)}
            </Text>
          </Item>
          <Item label="Necessita de Equipamento">
            <Switch
              checkedChildren="SIM"
              unCheckedChildren="NÃO"
              checked={serviceOrder?.isRequiredEquipment}
            />
          </Item>
          <Item label="Local">
            <Text strong>{serviceOrder?.local?.name}</Text>
          </Item>
          <Item label="Técnico">
            <Text strong>
              {
                serviceOrder?.users?.find(
                  (user) => user.profile === "TECHNICIAN"
                )?.name
              }
            </Text>
          </Item>
          <Item label="Data de Inicialização">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.simpleDate(serviceOrder?.startedAt)}
            </Text>
          </Item>
          <Item label="Tipo de equipamento">
            <Text strong>
              {serviceOrder?.equipmentTypes
                ?.map((type) => type.name)
                .toString()
                .replaceAll(",", ", ")}
            </Text>
          </Item>
          <Item label="Unidade">
            <Text strong>{serviceOrder?.unit?.name}</Text>
          </Item>
          <Item label="Status">
            {serviceOrder ? (
              <ServiceOrderStatusTag status={serviceOrder.status} />
            ) : null}
          </Item>
          <Item label="Data de Finalização">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.simpleDate(serviceOrder?.finishedAt)}
            </Text>
          </Item>
          <Item label="Descrição" span={4}>
            <Paragraph
              strong
              ellipsis={{ rows: 2, expandable: true, symbol: "mais" }}
            >
              {serviceOrder?.description}
            </Paragraph>
          </Item>
        </Descriptions>
      </PageHeader>
      <div style={{ marginTop: "16px" }}>
        <Outlet />
      </div>
    </Spin>
  );
};

export { ServiceOrder };
