import { useState, useEffect, useRef, useCallback, FC } from "react";
import { useParams } from "react-router-dom";

import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { PDF, PNG, JPG } from "../../../assets/icons";

import { api } from "../../../services/api";
import { Content } from "../../../components/Content";
import { IContractFile, IMeta, IPagination } from "../../../types";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { ContractFileModal } from "../components/ContractFileModal";
import { downloader, formatters } from "../../../utils";

const getContractFiles = async (contractId: string, page = 1, perPage = 10) => {
  return await api.get<IPagination<IContractFile>>(
    `/contracts/${contractId}/files`,
    {
      params: {
        page,
        perPage,
      },
    }
  );
};

const ContractFiles: FC = () => {
  const itemsTable = useRef<any>();
  const { id } = useParams();

  const [contractFiles, setContractFiles] = useState<IContractFile[]>([]);
  const [contractFile, setContractFile] = useState<IContractFile | undefined>();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShowModal = (contractFile?: IContractFile) => {
    if (contractFile) {
      setContractFile(contractFile);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setContractFile(undefined);
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      getContractFiles(String(id), page, pageSize)
        .then(({ data }) => {
          setContractFiles(data?.data);
          setMeta(data?.meta);
          itemsTable.current?.scrollIntoView({ behavior: "smooth" });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [load, id, meta.per_page, handleChangePage]);

  const actions: ActionFunction<IContractFile> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        conditional: true,
        onClick: () => handleShowModal(record),
      },
      {
        key: "2",
        icon: <DownloadOutlined />,
        label: "Baixar",
        conditional: record.name.slice(-3) === "pdf",
        onClick: () => downloader(record.url, record.name),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        conditional: true,
        danger: true,
        onClick: () => {},
      },
    ].filter((item) => item.conditional);
  };

  type FileIcons = {
    [key: string]: () => JSX.Element;
  };

  const fileIcons: FileIcons = {
    png: () => <PNG />,
    jpg: () => <JPG />,
    pdf: () => <PDF />,
  };

  function getFileExtension(fileName: string): string {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
  }

  function getIconForFile(fileName: string): JSX.Element | null {
    const extension = getFileExtension(fileName).toLowerCase();
    const IconComponent = fileIcons[extension];

    if (IconComponent) {
      return <IconComponent />;
    }

    return null;
  }

  const columns: ColumnsType<IContractFile> = [
    {
      title: "Nome do arquivo",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      render: (_, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {getIconForFile(record.name)}
          <span style={{ marginLeft: 8 }}>{record.name}</span>
        </div>
      ),
    },
    {
      title: "Enviado por",
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      render: (_, record) => record.user?.name,
    },
    {
      title: "Enviado em",
      dataIndex: "updatedAt",
      key: "updatedAt",
      ellipsis: true,
      render: (text: string) =>
        formatters.customDate(text, "dd/MM/yyyy HH:mm:ss"),
    },
    ActionColumn(actions),
  ];

  return (
    <Content width="calc(100% - 48px)">
      <ContractFileModal
        isModalOpen={isModalOpen}
        contractFile={contractFile}
        onClose={handleCloseModal}
        onRefreshTable={handleRefreshData}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0, marginBottom: "16px" }}
        width="100%"
        backButton={false}
        title={"Arquivos do contrato"}
        actions={[
          <Button
            key={"add_item_contract"}
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() => handleShowModal()}
          >
            Upload de arquivo
          </Button>,
        ]}
      />
      <Table
        ref={itemsTable}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
          pageSize: meta.per_page,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size="small"
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={contractFiles}
      />
    </Content>
  );
};

export { ContractFiles };
