import { Flex, Image } from "antd";
import { FC } from "react";

import fallback from "../../assets/fallaback-image.png";

interface PreviewGroupProps {
  images?: string[];
}

const PreviewGroup: FC<PreviewGroupProps> = ({ images }) => {
  return (
    <Image.PreviewGroup>
      <Flex gap={4}>
        {images?.map((image, index) => (
          <Image
            key={index}
            src={process.env.REACT_APP_ENDPOINT + image}
            height={32}
            width={32}
            style={{ objectFit: "cover", borderRadius: "4px" }}
            fallback={fallback}
            loading="lazy"
          />
        ))}
      </Flex>
    </Image.PreviewGroup>
  );
};

export { PreviewGroup };
