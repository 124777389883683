import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export function PencilIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={() => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M15.5317 2.96689C14.7225 2.06623 13.8627 1.16556 13.0029 0.291391C12.8259 0.10596 12.6236 0 12.396 0C12.1684 0 11.9408 0.0794702 11.7891 0.264901L2.2555 10.1722C2.10377 10.3311 2.00262 10.5166 1.92676 10.702L0.535917 15.1523C0.460053 15.3642 0.510629 15.5762 0.611781 15.7351C0.738221 15.894 0.915237 16 1.14283 16H1.24398L5.56823 14.4901C5.77054 14.4106 5.94756 14.3046 6.074 14.1457L15.557 4.23841C15.7087 4.07947 15.8099 3.84106 15.8099 3.60265C15.8099 3.36424 15.7087 3.15232 15.5317 2.96689ZM5.26478 13.3245C5.23949 13.351 5.2142 13.351 5.18891 13.3775L1.92676 14.5166L3.01414 11.0993C3.01414 11.0728 3.03943 11.0464 3.06472 11.0199L10.0189 3.76159L12.219 6.06623L5.26478 13.3245ZM13.0029 5.21854L10.8029 2.91391L12.3454 1.29801C13.0788 2.03973 13.8121 2.83444 14.5202 3.60265L13.0029 5.21854Z"
            fill={props.fill}
          />
        </svg>
      )}
      {...props}
    />
  );
}
