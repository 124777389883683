import { FC, useEffect, useState } from "react";

import { Button, Form, Input, App, Modal, Spin } from "antd";

import { api } from "../../../services/api";
import { ILocal, ModalProps } from "../../../types";
import { useParams } from "react-router-dom";

const LocalModal: FC<ModalProps<ILocal>> = ({
  onClose,
  isModalOpen,
  data: local,
}) => {
  const { id } = useParams();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (local) {
      form.setFieldsValue(local);
    }
  }, [form, local]);

  const handleCloseModal = (refresh: boolean = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return values;
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      })
      .finally(() => setLoading(false));

    setLoading(true);
    await handleSubmit(values)
      .then((data) => {
        message.success(
          `Local ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = async (values: any) => {
    if (local) {
      return api.put<ILocal>(`/locals/${local.id}`, values);
    }
    return api.post<ILocal>(`/locals/${id}`, values);
  };

  return (
    <Modal
      title={`${local ? "Editar" : "Criar"} Local`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="local_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome da Local!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Endereço"
            name="address"
            rules={[
              {
                required: true,
                message: "Por favor insira o endereço do Local!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { LocalModal };
