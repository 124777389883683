import React from "react";
import ReactDOM from "react-dom/client";
import { MyApp } from "./App";
import "./styles/index.css";
import "antd/dist/reset.css";
import { ColorModeProvider } from "./contexts/ColorModeContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <ColorModeProvider>
      <MyApp />
    </ColorModeProvider>
  </React.StrictMode>
);
