import { RouteObject } from "react-router-dom";
import { Checklist } from "./create";
import { Checklists } from "./list";

export const ChecklistRouter: RouteObject = {
  path: "/checklists",
  handle: { crumb: () => "Planejamento / Checklist" },
  children: [
    {
      index: true,
      element: <Checklists />,
    },
    {
      path: "create",
      handle: { crumb: () => "Adicionar checklist" },
      element: <Checklist />,
    },
    {
      path: "edit/:id",
      handle: { crumb: () => "Editar checklist" },
      element: <Checklist />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
