import { FC } from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import { Descriptions, Typography } from "antd";

import { ILocal } from "../../../types";
import { Content } from "../../../components/Content";

const { Text } = Typography;

interface LocalsHeaderProps {
  title?: string;
}

const LocalsHeader: FC<LocalsHeaderProps> = ({ title }) => {
  const data = useLoaderData() as ILocal;

  return (
    <>
      <Content width="calc(100% - 48px)">
        <Descriptions size="small" style={{ marginBottom: "16px" }} column={1}>
          <Descriptions.Item label={title}>
            <Text strong>{data?.name}</Text>
          </Descriptions.Item>
          {title === "Local" && (
            <Descriptions.Item label="Endereço">
              <Text strong>{data?.address}</Text>
            </Descriptions.Item>
          )}
          <Descriptions.Item label="Descrição">
            <Text strong>{data?.description}</Text>
          </Descriptions.Item>
        </Descriptions>
      </Content>
      <Outlet />
    </>
  );
};

export { LocalsHeader };
