import { useState, useEffect, FC } from "react";
import { useParams } from "react-router-dom";

import { Button, Descriptions, Typography } from "antd";
import { api } from "../../../services/api";
import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { EditFilled } from "@ant-design/icons";
import { useModal } from "../../../hooks";
import { UserConfigModal } from "../components/UserConfigModal";
import { IUserConfig } from "../../../types";

const { Text } = Typography;

const { Item } = Descriptions;

const UserConfigs: FC = () => {
  const { id } = useParams();
  const { data, handleCloseModal, handleOpenModal, isOpen } =
    useModal<IUserConfig>(() => setLoad(!load));

  const [userConfig, setUserConfig] = useState<IUserConfig>();
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`/users/${id}/configs`)
        .then(({ data }) => setUserConfig(data))
        .catch((error) => console.log(error));
    };
    getData();
  }, [id, load]);

  return (
    <Content width="calc(100% - 48px)">
      <UserConfigModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        data={data}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        title="Configurações do Usuário"
        backButton={false}
        actions={[
          <Button
            icon={<EditFilled />}
            key={"create_user"}
            type="primary"
            onClick={() => handleOpenModal(userConfig)}
          >
            Editar Configurações
          </Button>,
        ]}
      />
      <Descriptions size="small" column={2} bordered>
        <Item label="Assinatura">
          <Text strong>{userConfig?.signature}</Text>
        </Item>
        <Item label="Porcentagem">
          <Text strong>
            {userConfig?.percentage && userConfig?.percentage + "%"}
          </Text>
        </Item>
      </Descriptions>
    </Content>
  );
};

export { UserConfigs };
