import {
  createContext,
  ReactNode,
  useState,
  useContext,
  useCallback,
} from "react";
import { Outlet } from "react-router-dom";
import { IPmoc } from "../types";

type PmocContextData = {
  pmoc: IPmoc | undefined;
  handleSetPmoc: (data: IPmoc) => void;
};

type PmocProviderProps = {
  children: ReactNode;
};

const PmocContext = createContext({} as PmocContextData);

const PmocProvider = ({ children }: PmocProviderProps) => {
  const [pmoc, setPmoc] = useState<IPmoc>();

  const handleSetPmoc = useCallback((data: IPmoc) => {
    setPmoc(data);
  }, []);

  return (
    <PmocContext.Provider value={{ pmoc, handleSetPmoc }}>
      {children}
    </PmocContext.Provider>
  );
};

const usePmoc = () => {
  return useContext(PmocContext);
};

const PmocProviderLayout = () => (
  <PmocProvider>
    <Outlet />
  </PmocProvider>
);

export { PmocContext, PmocProvider, usePmoc, PmocProviderLayout };
