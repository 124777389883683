import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { App, Button, Form, InputNumber, Modal, Select, Spin } from "antd";

import { api } from "../../../services/api";

import { IProduct, IServiceOrder, ModalProps } from "../../../types";

import { useServiceOrder } from "../../../contexts/OrderContext";
import { formatters, parsers } from "../../../utils";

import { getProducts } from "../../products/list";

const _getProducts = (serviceOrder: IServiceOrder, serviceId: string) => {
  const params = {
    notInService: serviceId,
  };

  // if (serviceOrder.serviceType === "CONTRACT") {
  //   return getContractProducts(serviceOrder.contractId!, 1, 1000, params);
  // }

  return getProducts(1, 1000, params);
};

const ProductModal: FC<ModalProps<IProduct>> = ({
  onClose,
  isModalOpen,
  data: product,
}) => {
  const { message } = App.useApp();
  const { serviceOrder } = useServiceOrder();
  const { serviceId } = useParams();

  const [Products, setProducts] = useState<IProduct[]>([]);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const IS_EDIT_ITEM = !!product;

  const getData = useCallback(async () => {
    await _getProducts(serviceOrder!, serviceId!).then(({ data }) => {
      setProducts(data.data);
    });
  }, [serviceId, serviceOrder]);

  useEffect(() => {
    if (product) {
      form.setFieldsValue({
        productId: product.id,
        value: product.meta?.pivot_value || product.value,
        quantity: product.meta?.pivot_quantity || product.meta?.pivot_quantity,
        cost: product.meta?.pivot_cost || product.meta?.pivot_cost,
      });
      setProducts([product]);
    } else {
      if (isModalOpen) {
        getData();
      }
    }
  }, [form, serviceOrder, serviceId, product, isModalOpen, getData]);

  const handleCloseModal = (refresh = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Item ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IProduct) => {
    if (product) {
      return await api.put<IProduct>(
        `/service-orders/${serviceOrder?.id}/services/${serviceId}/products`,
        values
      );
    }
    return await api.post<IProduct>(
      `/service-orders/${serviceOrder?.id}/services/${serviceId}/products`,
      values
    );
  };

  const handleSelectItem = (productId: string) => {
    const _product = Products.find((item) => item.id === productId);

    if (_product) {
      form.setFieldsValue({
        value: _product.meta?.pivot_value || _product.value,
        quantity: 1,
        cost: _product.meta?.pivot_cost || _product.cost,
      });
    }
  };

  return (
    <Modal
      title={`${product ? "Editar" : "Adicionar"} Produto`}
      open={isModalOpen}
      centered
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="service_form_modal"
          initialValues={{
            quantity: 1,
          }}
        >
          <Form.Item
            label="Produto"
            name="productId"
            rules={[
              {
                required: true,
                message: "Por favor selecione o Produto!",
              },
            ]}
          >
            <Select
              disabled={IS_EDIT_ITEM}
              optionLabelProp="label"
              optionFilterProp="label"
              showSearch
              allowClear
              options={Products.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
              onSelect={handleSelectItem}
            />
          </Form.Item>
          <Form.Item
            name="value"
            label="Preço"
            rules={[
              {
                required: true,
                message: "Por favor, insira o valor do item.",
              },
            ]}
          >
            <InputNumber
              disabled={!!serviceOrder?.contractId}
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="cost"
            label="Preço de Custo"
            rules={[
              {
                required: true,
                message: "Por favor, insira o valor de custo do item.",
              },
            ]}
          >
            <InputNumber
              disabled={!!serviceOrder?.contractId}
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Quantidade"
            rules={[
              {
                required: true,
                message: "Por favor, insira a quantidade do item.",
              },
            ]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ProductModal };
