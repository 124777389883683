import {
  App,
  Button,
  Card,
  Form,
  InputNumber,
  Modal,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { FC, useEffect, useState } from "react";
import { useContract } from "../../../contexts/ContractContext";
import { api } from "../../../services/api";
import { IContractItem, IProduct, ModalProps } from "../../../types";
import { formatters } from "../../../utils/formatters";
import { parsers } from "../../../utils/parsers";
import { getProducts } from "../../../services/repositories";

const { Text, Paragraph } = Typography;

const ContractProductModal: FC<ModalProps<IProduct>> = ({
  onClose,
  onRefreshData,
  isModalOpen,
  data: contractProduct,
}) => {
  const { message } = App.useApp();

  const { contract } = useContract();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IProduct[]>([]);
  const [item, setItem] = useState<IProduct>();

  const IS_EDIT_ITEM = !!contractProduct;

  useEffect(() => {
    const getData = async () => {
      if (contractProduct) {
        setItems([contractProduct]);
        setItem(contractProduct);
        form.setFieldsValue({
          itemId: contractProduct.id,
          number: contractProduct.meta?.pivot_number,
          value: contractProduct.meta?.pivot_value,
          cost: contractProduct.meta?.pivot_cost,
          quantity: contractProduct.meta?.pivot_quantity,
        });
        return;
      }

      let { data } = await getProducts(1, 1000, {
        notInContractId: contract?.id,
      });

      setItems(data);
    };
    if (isModalOpen) {
      getData();
    }
  }, [contract?.id, contractProduct, form, isModalOpen]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    setItem(undefined);
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    const values = await form
      .validateFields()
      .then((values) => {
        return values;
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }
    setLoading(true);
    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Item ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal();
        if (onRefreshData) {
          onRefreshData();
        }
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IContractItem) => {
    if (contractProduct) {
      return await api.put<IContractItem>(
        `/contracts/${contract?.id}/products/${contractProduct.meta?.pivot_id}`,
        values
      );
    }
    return await api.post<IContractItem>(
      `/contracts/${contract?.id}/products`,
      values
    );
  };

  const handleSelectItem = (itemId: string) => {
    let _item = items.find((item) => item.id === itemId);
    setItem(_item ?? undefined);
    form.setFieldsValue({
      value: _item?.value,
      cost: _item?.cost,
    });
  };

  return (
    <Modal
      title="Editar Produto"
      open={isModalOpen}
      style={{ top: 260 }}
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      {!!item && <ItemCard item={item} />}
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="contract_item_form_modal">
          <Form.Item
            name="productId"
            label="Produto"
            rules={[
              {
                required: true,
                message: "Por favor, selecione um produto.",
              },
            ]}
          >
            <Select
              disabled={IS_EDIT_ITEM}
              optionLabelProp="label"
              optionFilterProp="label"
              showSearch
              allowClear
              options={items?.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })}
              onChange={handleSelectItem}
            />
          </Form.Item>
          <Form.Item
            name="number"
            label="Número"
            rules={[
              {
                required: true,
                message: "Por favor, insira o número do item.",
              },
            ]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="value"
            label="Preço"
            rules={[
              {
                required: true,
                message: "Por favor, insira o valor do item.",
              },
            ]}
          >
            <InputNumber
              disabled={IS_EDIT_ITEM}
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="cost"
            label="Custo"
            rules={[
              {
                required: true,
                message: "Por favor, insira o custo do item.",
              },
            ]}
          >
            <InputNumber
              disabled={IS_EDIT_ITEM}
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Quantidade"
            rules={[
              {
                required: true,
                message: "Por favor, insira a quantidade do item.",
              },
            ]}
          >
            <InputNumber
              disabled={IS_EDIT_ITEM}
              min={1}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export const ItemCard = ({ item }: { item?: IProduct }) => {
  return (
    <Card
      style={{
        zIndex: -1,
        display: !!item ? "initial" : "none",
        top: 10,
        width: 520,
        position: "fixed",
        left: "50%",
        transform: "translate(-50%, 0)",
      }}
    >
      <Space direction="vertical" size={0} style={{ width: "100%" }}>
        <Text strong>Descrição:</Text>
        <Paragraph ellipsis={{ rows: 2 }}>{item?.description}</Paragraph>
      </Space>
    </Card>
  );
};

export { ContractProductModal };
