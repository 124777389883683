import { Form, InputNumber } from "antd";

const ValueAdditiveForm = () => {
  // const { contract } = useContract();

  return (
    <>
      <Form.Item
        name="value"
        label="Porcentagem"
        rules={[
          {
            required: true,
            message: "Por favor, insira a Porcentagem do aditivo.",
          },
        ]}
      >
        <InputNumber
          defaultValue="1.000"
          min="1"
          step="0.001"
          style={{ width: "100%" }}
        />
      </Form.Item>
    </>
  );
};

export { ValueAdditiveForm };
