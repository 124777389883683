import { useState, useEffect, FC } from "react";

import { App, Button, Form, Input, Spin, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { TOKEN_API, api } from "../../../services/api";
import { IOrganization } from "../../../types";
import { UploadProps } from "antd/lib";
import { formatters, normFile, parsers } from "../../../utils";

const Organization: FC = () => {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [load] = useState(false);
  const [file, setFile] = useState<any>([]);
  const [label, setLabel] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await api.get<IOrganization>("/organization").then(({ data }) => {
        setFile(
          data.logo
            ? [
                {
                  uid: "-1",
                  status: "done",
                  name: "Logo",
                  url: data?.logo,
                  thumbUrl: process.env.REACT_APP_API + data?.logo,
                },
              ]
            : []
        );
        setLabel(
          data.label
            ? [
                {
                  uid: "-1",
                  status: "done",
                  name: "Etiqueta",
                  url: data?.label,
                  thumbUrl: process.env.REACT_APP_API + data?.label,
                },
              ]
            : []
        );
        form.setFieldsValue({
          ...data,
          cnpj: formatters.identifier(data.cnpj),
          phone: formatters.phone(data.phone),
        });
      });
      setLoading(false);
    };

    getData();
  }, [load, form]);

  const props: UploadProps = {
    name: "file",
    multiple: false,
    action: process.env.REACT_APP_API + `/upload`,
    listType: "picture",
    fileList: file,
    data: {
      folder: "organization",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onRemove: () => {
      setFile([]);
    },
    onChange(info) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      setFile(newFileList);

      if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(`${info.file.name}-${err.message}`)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept: "image/*",
  };

  const propsLabel: UploadProps = {
    name: "file",
    multiple: false,
    action: process.env.REACT_APP_API + `/upload`,
    listType: "picture",
    fileList: label,
    data: {
      folder: "organization",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onRemove: () => {
      setLabel([]);
    },
    onChange(info) {
      let newFileList = [...info.fileList];
      newFileList = newFileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      setLabel(newFileList);

      if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(`${info.file.name}-${err.message}`)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept: "image/*",
  };

  const onSubmit = async () => {
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          cnpj: parsers.identifier(values.cnpj),
          phone: parsers.phone(values.phone),
          logo: file[0]?.url,
          label: label[0]?.url,
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    await api
      .put("/organization", values)
      .then(() => {
        message.success("Organização atualizada com sucesso");
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item label={"Razão Social"} name={"corporateName"}>
          <Input />
        </Form.Item>
        <Form.Item label={"Nome Fantasia"} name={"tradeName"}>
          <Input />
        </Form.Item>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            columnGap: "16px",
          }}
        >
          <Form.Item
            label={"Telefone"}
            name={"phone"}
            normalize={formatters.phone}
          >
            <Input />
          </Form.Item>
          <Form.Item label={"Email"} name={"email"}>
            <Input />
          </Form.Item>
          <Form.Item
            label={"CNPJ"}
            name={"cnpj"}
            normalize={formatters.identifier}
            rules={[
              {
                required: true,
                message: "Por favor insira o CNPJ!",
              },
              {
                min: 13,
                message: "Por favor insira um CNPJ valido!",
              },
              {
                max: 18,
                message: "Por favor insira um CNPJ valido!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label={"Inscrição Estadual"} name={"ie"}>
            <Input />
          </Form.Item>
        </div>
        <Form.Item
          label="Logo"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          style={{ width: "50%" }}
        >
          <Upload {...props}>
            {file[0]?.url ? null : (
              <Button icon={<UploadOutlined />}>Upload</Button>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          label="Etiqueta"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          style={{ width: "50%" }}
        >
          <Upload {...propsLabel}>
            {label[0]?.url ? null : (
              <Button icon={<UploadOutlined />}>Upload</Button>
            )}
          </Upload>
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 16,
          }}
        >
          <Button>Cancelar</Button>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export { Organization };
