export function ChecklistDescriptionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="2" fill="#F0F0F0" />
      <path
        d="M8.53765 11.0753H21.6282C21.9087 11.0753 22.1658 10.8415 22.1658 10.5376C22.1658 10.2338 21.9321 10 21.6282 10H8.53765C8.25714 10 8 10.2338 8 10.5376C8 10.8415 8.25714 11.0753 8.53765 11.0753Z"
        fill="#434343"
      />
      <path
        d="M21.6282 13.9746H8.53765C8.25714 13.9746 8 14.2084 8 14.5123C8 14.8161 8.23376 15.0499 8.53765 15.0499H21.6282C21.9087 15.0499 22.1658 14.8161 22.1658 14.5123C22.1658 14.2084 21.9087 13.9746 21.6282 13.9746Z"
        fill="#434343"
      />
      <path
        d="M21.6282 17.9258H8.53765C8.25714 17.9258 8 18.1595 8 18.4634C8 18.7673 8.23376 19.0011 8.53765 19.0011H21.6282C21.9087 19.0011 22.1658 18.7673 22.1658 18.4634C22.1658 18.1595 21.9087 17.9258 21.6282 17.9258Z"
        fill="#434343"
      />
    </svg>
  );
}
