import { FC, useEffect, useState } from "react";
import { Col, Form } from "antd";
import dayjs from "dayjs";

import { useSearchParams } from "react-router-dom";

import { IClient, IContract } from "../../../../types";
import { getAllContracts } from "../../../contracts/list";
import { Select } from "../../../../components/Select";

import { ExpandableFilter } from "../../../../components/ExpandableFilter";
import { getClients, getContracts } from "../../../../services/repositories";

interface FilterProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const Filter: FC<FilterProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm();

  const [, setSearchParams] = useSearchParams();
  const [clients, setClients] = useState<IClient[]>([]);
  const [contracts, setContracts] = useState<IContract[]>([]);
  // const [items, setItems] = useState<IItem[]>([]);

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        ...filter,
        date: filter.date
          ? filter.date.split(",").map((date: string) => dayjs(date))
          : undefined,
        status: filter.status ? filter.status?.split(",") : undefined,
      });
    }
  }, [form, filter]);

  useEffect(() => {
    if (clients.length === 0) {
      getClients(1, 1000).then(({ data }) => setClients(data));
    }
    if (filter?.clientId) {
      getAllContracts(1, 1000, { clientId: filter.clientId }).then(
        ({ data }) => {
          setContracts(data.data);
        }
      );
    }
  }, [filter, clients.length]);

  const onFinish = (values: any) => {
    const params = JSON.parse(
      JSON.stringify({
        ...values,
        status: values?.status?.toString(),
      })
    );
    setSearchParams(params);
    onSubmit(params);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const loadContracts = async () => {
    await getContracts(
      {
        page: 1,
        perPage: 1000,
      },
      {
        clientId: form.getFieldValue("clientId"),
      }
    ).then(({ data }) => {
      setContracts(data);
    });
  };

  // const loadItems = async () => {
  //   await getItems(
  //     {
  //       page: 1,
  //       perPage: 1000,
  //     },
  //     {
  //       contractId: form.getFieldValue("contractId"),
  //     }
  //   ).then(({ data }) => {
  //     setItems(data);
  //   });
  // };

  const fields = [
    <Col span={6} key={1}>
      <Form.Item
        name={"clientId"}
        label={"Cliente"}
        rules={[
          {
            required: true,
            message: "Selecione ao menos um cliente",
          },
        ]}
      >
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={clients}
          onChange={() => form.setFieldValue("contractId", null)}
          placeholder="Selecione um cliente"
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item label="Contrato" name="contractId">
        <Select
          optionLabelProp="label"
          optionFilterProp="label"
          showSearch
          allowClear
          options={contracts}
          handleLoadData={loadContracts}
          placeholder="Selecione um contrato"
        />
      </Form.Item>
    </Col>,
    // <Col span={6} key={3}>
    //   <Form.Item label="Itens" name="items">
    //     <Select
    //       mode="multiple"
    //       options={items}
    //       handleLoadData={loadItems}
    //       placeholder="Selecione um ou mais itens"
    //     />
    //   </Form.Item>
    // </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { Filter };
