import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

export const COLOR_MODE = "@arcke:color";

type ColorModeContextData = {
  selectedTheme: string;
  handleChangeColorMode: () => void;
};

type ColorModeProviderProps = {
  children: ReactNode;
};

const ColorModeContext = createContext({} as ColorModeContextData);

const ColorModeProvider = ({ children }: ColorModeProviderProps) => {
  const [selectedTheme, setSelectedTheme] = useState<string>("light");

  useEffect(() => {
    const colorMode = localStorage.getItem(COLOR_MODE);
    if (colorMode) {
      colorMode === "light"
        ? setSelectedTheme("light")
        : setSelectedTheme("dark");
    }
  }, []);

  const handleChangeColorMode = () => {
    let change = selectedTheme === "light" ? "dark" : "light";
    setSelectedTheme(change);
    localStorage.setItem(COLOR_MODE, change);
  };

  return (
    <ColorModeContext.Provider value={{ selectedTheme, handleChangeColorMode }}>
      {children}
    </ColorModeContext.Provider>
  );
};

const useColorMode = () => {
  return useContext(ColorModeContext);
};

export { ColorModeContext, ColorModeProvider, useColorMode };
