import { RouteObject } from "react-router-dom";
import { Suppliers } from "./list";
import { ISupplier } from "../../types";
import { Supplier } from "./supplier";
import { api } from "../../services/api";
import { Financial } from "./supplier/Financial";

export const SupplierRouter: RouteObject = {
  path: "/suppliers",
  handle: { crumb: () => "Cadastros / Fornecedores" },
  children: [
    {
      index: true,
      element: <Suppliers />,
    },
    {
      path: ":id",
      loader: async ({ params }) => {
        const { data } = await api.get<ISupplier>(`/suppliers/${params.id}`);
        return data;
      },
      handle: {
        crumb: (data?: ISupplier) => data?.name,
      },
      element: <Supplier />,
      children: [
        {
          path: "financial",
          handle: { crumb: () => "Contas a pagar" },
          element: <Financial />,
        },
      ],
      
    }
  ],
  errorElement: <h1>Route not found!</h1>,
};
