import { FC, useEffect, useState } from "react";

import {
  App,
  Button,
  Form,
  Input,
  Modal,
  Spin,
  Upload,
  UploadProps,
} from "antd";

import { TOKEN_API, api } from "../../../services/api";

import { IContractAdditive, ModalProps } from "../../../types";
import { useContract } from "../../../contexts/ContractContext";

import { formatters } from "../../../utils";

import dayjs from "dayjs";
import { InboxOutlined } from "@ant-design/icons";

import { TimeAdditiveForm } from "../forms/TimeAdditiveForm";
import { ValueAdditiveForm } from "../forms/ValueAdditiveForm";

interface ContractAdditiveProps extends ModalProps<IContractAdditive> {
  type: IContractAdditive["type"];
}

const ContractAdditiveModal: FC<ContractAdditiveProps> = ({
  onClose,
  isModalOpen,
  type,
}) => {
  const { message } = App.useApp();

  const { contract } = useContract();

  const [form] = Form.useForm();
  const [files, setFiles] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [contract?.id, form, isModalOpen]);

  const handleCloseModal = (refresh: boolean = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    const values = await form
      .validateFields()
      .then((value) => {
        return {
          ...value,
          currentDate: value.currentDate?.format("YYYY-MM-DD"),
        };
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm({ ...values, files })
      .then(() => {
        message.success(`Aditivico criado com sucesso!`);
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IContractAdditive) => {
    return await api.post<IContractAdditive>(
      `/contracts/${contract?.id}/additives/${type}`,
      values
    );
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_API + `/upload`,
    data: {
      folder: "contract_files",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setFiles(info.fileList.map((file) => file.response));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(`${info.file.name}-${err.message}`)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept: "image/*,.pdf",
    listType: "picture",
    maxCount: 3,
  };

  return (
    <Modal
      title="Adiconar Aditivo"
      open={isModalOpen}
      centered
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="contract_item_form_modal"
          initialValues={{
            currentDate: dayjs(
              formatters.simpleDate(contract?.endDate),
              "DD/MM/YYYY"
            ),
          }}
        >
          <Form.Item
            name="description"
            label="Descrição"
            rules={[
              {
                required: true,
                message: "Por favor, insira a Descrição do aditivo.",
              },
            ]}
          >
            <Input.TextArea rows={2} />
          </Form.Item>
          {type === "TIME" && <TimeAdditiveForm />}
          {type === "VALUE" && <ValueAdditiveForm />}
          <Form.Item
            label="Arquivos"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload.Dragger name="file" {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click ou arraste o arquivo para essa area de upload
              </p>
              <p className="ant-upload-hint">
                Suporte para upload único ou em massa.
              </p>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ContractAdditiveModal };
