import { DollarOutlined, SyncOutlined } from "@ant-design/icons";
import { StatusTag } from "../../../components/StatusTag";
import { IContractAdditive } from "../../../types";

export const colors = {
  TIME: { name: "RENOVAÇÃO", color: "blue", icon: <SyncOutlined /> },
  VALUE: { name: "VALOR", color: "green", icon: <DollarOutlined /> },
  ITEM: { name: "ITEM", color: "yellow", icon: <SyncOutlined /> },
};

interface StatusTagsProps {
  status: IContractAdditive["type"];
  size?: "full";
}

const AdditiveStatusTag = ({ status, size }: StatusTagsProps) => {
  return (
    <StatusTag
      size={size ? size : undefined}
      color={colors[status].color}
      icon={colors[status].icon}
    >
      {colors[status].name.toUpperCase()}
    </StatusTag>
  );
};

export { AdditiveStatusTag };
