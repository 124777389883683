import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export function DashboardIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={() => (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 1224 1024"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M891.13 136.794C820.779 136.794 764.107 193.466 764.107 263.817C764.107 267.726 764.107 273.588 764.107 277.497L717.207 308.764L664.443 291.176C648.81 238.413 599.955 201.283 543.283 201.283C472.932 201.283 416.26 257.955 416.26 328.306C416.26 340.031 418.214 349.802 420.168 359.573C406.489 371.298 388.901 383.023 375.222 394.749C353.726 381.069 330.275 371.298 302.916 371.298C232.565 371.298 175.894 427.97 175.894 498.321C175.894 568.673 232.565 625.344 302.916 625.344C373.268 625.344 429.939 568.673 429.939 498.321C429.939 486.596 427.985 476.825 426.031 467.054C439.71 455.329 457.298 443.604 470.978 431.879C492.474 445.558 515.924 455.329 543.283 455.329C594.092 455.329 637.084 426.016 658.581 383.023L697.665 396.703C705.481 398.657 711.344 400.611 719.161 400.611C732.84 400.611 746.519 396.703 758.245 388.886L803.191 359.573C826.642 381.069 855.955 392.794 889.176 392.794C959.527 392.794 1016.2 336.123 1016.2 265.771C1016.2 195.42 961.481 136.794 891.13 136.794ZM302.916 537.405C281.42 537.405 263.832 519.818 263.832 498.321C263.832 476.825 281.42 459.237 302.916 459.237C324.413 459.237 342 476.825 342 498.321C342 519.818 324.413 537.405 302.916 537.405ZM543.283 365.436C521.787 365.436 504.199 347.848 504.199 326.352C504.199 304.855 521.787 287.268 543.283 287.268C564.779 287.268 582.367 304.855 582.367 326.352C582.367 347.848 564.779 365.436 543.283 365.436ZM891.13 302.901C869.634 302.901 852.046 285.313 852.046 263.817C852.046 242.321 869.634 224.733 891.13 224.733C912.626 224.733 930.214 242.321 930.214 263.817C930.214 285.313 912.626 302.901 891.13 302.901Z" />
          <path d="M1100.22 0H123.115C56.6718 0 0 54.7176 0 123.115V639.023C0 705.466 54.7176 762.137 123.115 762.137H568.672V934.107H353.71C330.26 934.107 308.764 953.649 308.764 979.053C308.764 1004.46 328.306 1024 353.71 1024H869.619C893.07 1024 914.566 1004.46 914.566 979.053C914.566 953.649 895.024 934.107 869.619 934.107H656.611V762.137H1100.22C1166.66 762.137 1223.33 707.42 1223.33 639.023V121.16C1223.33 54.7176 1166.66 0 1100.22 0ZM1135.39 637.069C1135.39 656.611 1119.76 672.244 1100.22 672.244H123.115C103.573 672.244 87.939 656.611 87.939 637.069V121.16C87.939 101.618 103.573 85.9847 123.115 85.9847H1100.22C1119.76 85.9847 1135.39 101.618 1135.39 121.16V637.069Z" />
        </svg>
      )}
      {...props}
    />
  );
}
