import { FC, useEffect, useState } from "react";

import { App, Button } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";

import { api } from "../../../services/api";

import { IBrand, IMeta, IPagination } from "../../../types";
import { useNavigate } from "react-router-dom";
import { formatters } from "../../../utils";
import { ChecklistPreviewModal } from "../components/ChecklistPreviewModal";
import { ActionTable } from "../../../components/ActionTable";

export const getChecklists = async (page = 1, perPage = 10, q?: any) => {
  return api.get<IPagination<IBrand>>("checklists", {
    params: {
      page,
      perPage,
      ...q,
    },
  });
};

const Checklists: FC = () => {
  const navigate = useNavigate();
  const { modal, message } = App.useApp();

  const [brands, setChecklists] = useState<IBrand[]>([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const [checklist, setChecklist] = useState<IBrand>();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [shouldReload, setShouldReload] = useState(false);

  const [filter] = useState();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      getChecklists(meta.current_page, meta.per_page, filter)
        .then(({ data }) => {
          setChecklists(data.data);
          setMeta(data.meta);
        })
        .finally(() => setLoading(false));
    };
    if (shouldReload) {
      getData();
      setShouldReload(false);
    }
    getData();
  }, [meta.current_page, meta.per_page, filter, shouldReload]);

  const onDeleteChecklist = async (record: IBrand) => {
    return modal.confirm({
      title: "Deseja deletar esse checklist?",
      content: <>O checklist será excluído permanentemente!</>,
      okText: "Deletar",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      cancelText: "Cancelar",
      cancelButtonProps: {
        type: "default",
        danger: true,
      },
      onOk: async () => {
        setLoading(true);
        await api
          .delete(`/checklists/${record.id}`)
          .catch(() => message.error("Ops! Algo deu errado!"))
          .then(() => setShouldReload(true))
          .finally(() => setLoading(false));
      },
    });
  };

  const actions = (record: IBrand) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => navigate(`edit/${record.id}`),
      },
      {
        key: "2",
        icon: <EyeOutlined />,
        label: "Pré-visualizar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteChecklist(record),
      },
    ];
  };

  const columns: ColumnsType<IBrand> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 70,
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      width: 400,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatters.simpleDate(text),
    },
    {
      title: "Atualizado em",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => formatters.simpleDate(text),
    },
  ];

  // const handleFilterData = async (values: any) => {
  //   setFilter(values);
  // };

  const handleChangePage = async (page: number, pageSize: number) => {
    setLoading(true);
    getChecklists(page, pageSize, filter)
      .then(({ data }) => {
        setChecklists(data?.data);
        setMeta(data?.meta);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const handleOpenModal = (record: any) => {
    setChecklist(record);
    setIsOpenModal(true);
  };

  const handleCloseModal = () => {
    setChecklist(undefined);
    setIsOpenModal(false);
  };

  return (
    <>
      <ChecklistPreviewModal
        isModalOpen={isOpenModal}
        data={checklist}
        onClose={handleCloseModal}
      />
      <PageHeader
        title="Checklists"
        backButton={false}
        actions={[
          <Button
            key={"create_checklist"}
            onClick={() => navigate("create")}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar checklist
          </Button>,
        ]}
        crumb
      />
      {/* <Content width="calc(100% - 48px)" margin style={{ padding: '24px 40px 0' }}>
        <FilterBrandList onSubmit={handleFilterData} />
      </Content> */}
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size={"small"}
          loading={loading}
          rowKey={(record: IBrand) => record.id}
          columns={columns}
          dataSource={brands}
        />
      </Content>
    </>
  );
};

export { Checklists };
