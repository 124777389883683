import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { App, Button, Form, Modal, Select, Spin } from "antd";

import { api } from "../../../services/api";
import { ILocal, IUser, ModalProps } from "../../../types";
import { getClientLocals } from "../client/ClientLocals";

const UserLocalModal: FC<ModalProps<IUser>> = ({
  onClose,
  isModalOpen,
  data: user,
}) => {
  const { id } = useParams();
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [locals, setLocals] = useState<ILocal[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getClientLocals(id, 1, 1000).then(({ data }) => {
        setLocals(data.data);
      });
    };
    if (isModalOpen) {
      setLoading(true);
      getData()
        .then(() => {
          form.setFieldValue(
            "locals",
            user?.locals?.map((local) => local.id)
          );
        })
        .finally(() => setLoading(false));
    }
  }, [form, id, user, isModalOpen]);

  const handleCloseModal = (refresh = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then((data) => {
        message.success(`Usuário atualizado com sucesso!`);
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IUser) => {
    if (user) {
      return await api.put<IUser>(`/users/${user.id}/sync-locals`, values);
    }
  };

  return (
    <Modal
      title={`${user ? "Editar" : "Criar"} Serviço`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="service_form_modal">
          <Form.Item label="Locals" name="locals">
            <Select
              mode="multiple"
              optionLabelProp="label"
              optionFilterProp="label"
              showSearch
              allowClear
              options={locals.map((local) => {
                return {
                  value: local.id,
                  label: local.name,
                };
              })}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { UserLocalModal };
