import { App } from "antd";
import { api } from "../services/api";

export function useDelete(handleRefreshData?: () => void) {
  const { modal, message } = App.useApp();

  const handleDeleteData = (url: string, title?: string) => {
    modal.warning({
      title: title,
      content: "O registro será excluído permanentemente!",
      closable: true,
      maskClosable: true,
      okCancel: true,
      cancelText: "Cancelar",
      okButtonProps: {
        danger: true,
      },
      onOk: async () => {
        await api
          .delete(url)
          .catch(() => message.error("Algo inesperado ocorreu!"))
          .finally(onRefresh);
      },
    });
  };

  const onRefresh = () => {
    if (handleRefreshData) {
      handleRefreshData();
    }
  };

  return { handleDeleteData };
}
