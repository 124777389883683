import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useOutlet, useParams } from "react-router-dom";

import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  BarChartOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { api } from "../../../services/api";

import { Content } from "../../../components/Content";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";

import { LocalModal } from "../components/LocalModal";
import { ILocal, IPagination } from "../../../types";

export const getClientLocals = async (
  clientId?: string,
  page = 1,
  perPage = 10,
  q?: any
) => {
  return await api.get<IPagination<ILocal>>(`/locals`, {
    params: {
      page,
      perPage,
      clientId,
      ...q,
    },
  });
};

const deleteLocal = async (localId: any) => {
  return await api.delete(`/locals/${localId}`);
};

const Locals: React.FC = () => {
  const { id } = useParams();
  const outlet = useOutlet();
  const navigate = useNavigate();

  const [locals, setLocals] = useState<ILocal[]>([]);
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [meta, setMeta] = useState<any>({
    current_page: 1,
    per_page: 10,
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [local, setLocal] = useState<ILocal>();

  const handleCloseModal = (refresh: boolean = false) => {
    if (refresh) {
      setLoad(!load);
    }
    setIsModalOpen(false);
    setLocal(undefined);
  };

  useEffect(() => {
    const getOrdens = async () => {
      setLoading(true);
      await getClientLocals(id)
        .then(({ data }) => {
          setLocals(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    };
    getOrdens();
  }, [id, load]);

  const handleShowModal = async (record?: ILocal) => {
    if (record) {
      setLocal(record);
    }
    setIsModalOpen(true);
  };

  const handleChangePage = async (page: number, pageSize: number) => {
    await getClientLocals(id, page, pageSize)
      .then(({ data }) => {
        setLocals(data?.data);
        setMeta(data?.meta);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const actions: ActionFunction<ILocal> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleShowModal(record),
      },
      {
        key: "2",
        icon: <BarChartOutlined />,
        label: "Unidades",
        onClick: () => {
          navigate(`${record.id}/units`);
        },
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: async () => {
          setLoading(true);
          await deleteLocal(record.id)
            .then(() => setLoad(!load))
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
        },
      },
    ];
  };

  const columns: ColumnsType<ILocal> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Endereço",
      dataIndex: "address",
      key: "address",
      ellipsis: true,
    },
    ActionColumn(actions),
  ];

  return outlet ? (
    <Outlet />
  ) : (
    <Content>
      <LocalModal
        isModalOpen={isModalOpen}
        onClose={handleCloseModal}
        data={local}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Locais do Cliente"}
        actions={[
          <Button
            key={"add_client_local"}
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => handleShowModal()}
          >
            Adicionar Local
          </Button>,
        ]}
      />
      <Table
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
          pageSize: meta.per_page,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size="small"
        loading={loading}
        rowKey={(record: ILocal) => record.id}
        columns={columns}
        dataSource={locals}
      />
    </Content>
  );
};

export { Locals };
