import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { App, Button, Form, InputNumber, Modal, Spin } from "antd";

import { api } from "../../../services/api";

import { IItem, IServiceOrder, ModalProps } from "../../../types";

import { useServiceOrder } from "../../../contexts/OrderContext";
import { ItemCard } from "../../contracts/components/ContractItemModal";
import { formatters, parsers } from "../../../utils";

import { Select } from "../../../components/Select";
import { getContractItems, getItems } from "../../../services/repositories";

const _getItems = async (serviceOrder: IServiceOrder, serviceId: string) => {
  const params = {
    notInService: serviceId,
    serviceTypes: serviceOrder?.serviceTypes.map((type) => type.id).toString(),
    equipmentTypes: serviceOrder?.equipmentTypes
      .map((type) => type.id)
      .toString(),
  };
  if (serviceOrder.type === "CONTRACT") {
    const { data } = await getContractItems(
      { id: serviceOrder.contractId!, page: 1, perPage: 1000 },
      params
    );
    return data;
  }

  const { data } = await getItems(
    { page: 1, perPage: 1000 },
    { isDetached: true, ...params }
  );

  return data;
};

const ItemModal: FC<ModalProps<IItem>> = ({
  onClose,
  isModalOpen,
  data: serviceItem,
}) => {
  const { message } = App.useApp();
  const { serviceOrder } = useServiceOrder();
  const { serviceId } = useParams();

  const [item, setItem] = useState<IItem>();
  const [items, setItems] = useState<IItem[]>([]);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const IS_EDIT_ITEM = !!serviceItem;

  const getData = useCallback(async () => {
    await _getItems(serviceOrder!, serviceId!).then((data) => setItems(data));
  }, [serviceId, serviceOrder]);

  useEffect(() => {
    if (serviceItem) {
      setItem(serviceItem);
      form.setFieldsValue({
        itemId: serviceItem.id,
        number: serviceItem.meta?.pivot_number,
        value: serviceItem.meta?.pivot_value,
        cost: serviceItem.meta?.pivot_cost,
        quantity: serviceItem.meta?.pivot_quantity,
      });
      setItems([serviceItem]);
    } else {
      if (isModalOpen) {
        getData();
      }
    }
  }, [form, serviceOrder, serviceId, serviceItem, isModalOpen, getData]);

  const handleCloseModal = (refresh = false) => {
    if (loading) {
      return;
    }
    setItem(undefined);
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Item ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IItem) => {
    if (serviceItem) {
      return await api.put<IItem>(
        `/service-orders/${serviceOrder?.id}/services/${serviceId}/items`,
        values
      );
    }
    return await api.post<IItem>(
      `/service-orders/${serviceOrder?.id}/services/${serviceId}/items`,
      values
    );
  };

  const handleSelectItem = (itemId: string) => {
    const _item = items.find((item) => item.id === itemId);

    if (_item) {
      setItem(_item);
    }
    form.setFieldsValue({
      value: _item?.meta?.pivot_value || _item?.value,
      cost: _item?.meta?.pivot_cost || _item?.cost,
    });
  };

  return (
    <Modal
      title={`${serviceItem ? "Editar" : "Adicionar"} Item`}
      open={isModalOpen}
      centered
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      {!!item && <ItemCard item={item} />}
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="service_form_modal"
          initialValues={{
            quantity: 1,
          }}
        >
          <Form.Item
            label="Item"
            name="itemId"
            rules={[
              {
                required: true,
                message: "Por favor selecione o Item!",
              },
            ]}
          >
            <Select
              disabled={IS_EDIT_ITEM}
              options={items}
              onSelect={handleSelectItem}
            />
          </Form.Item>
          <Form.Item
            name="value"
            label="Preço"
            rules={[
              {
                required: true,
                message: "Por favor, insira o valor do item.",
              },
            ]}
          >
            <InputNumber
              disabled={!!serviceOrder?.contractId}
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="cost"
            label="Custo"
            rules={[
              {
                required: true,
                message: "Por favor, insira o valor de custo do item.",
              },
            ]}
          >
            <InputNumber
              formatter={formatters.currency}
              min={0.1}
              parser={parsers.currency}
              style={{ width: "100%" }}
            />
          </Form.Item>
          <Form.Item
            name="quantity"
            label="Quantidade"
            rules={[
              {
                required: true,
                message: "Por favor, insira a quantidade do item.",
              },
            ]}
          >
            <InputNumber
              disabled={!item?.isConsumible}
              min={1}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ItemModal };
