import { FC, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import {
  ConfigProvider,
  Empty,
  Select as ASelect,
  SelectProps,
  theme,
} from "antd";

interface CustomSelectProps extends SelectProps {
  options: any[];
  handleLoadData?: () => Promise<void>;
}

const defaultEmpty = () => <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
const loadingEmpty = () => (
  <div style={{ textAlign: "center", height: "100%" }}>
    <LoadingOutlined style={{ fontSize: 20 }} />
  </div>
);

const Select: FC<CustomSelectProps> = ({
  options,
  handleLoadData,
  ...props
}) => {
  const {
    token: { colorTextDescription },
  } = theme.useToken();

  const [loading, setLoading] = useState(false);

  async function handleOnFocus() {
    if (handleLoadData) {
      setLoading(true);
      handleLoadData().finally(() => setLoading(false));
    }
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            colorTextDisabled: colorTextDescription,
          },
        },
      }}
      renderEmpty={loading ? loadingEmpty : defaultEmpty}
    >
      <ASelect
        {...props}
        loading={loading || props.loading}
        optionLabelProp="label"
        optionFilterProp="label"
        showSearch
        allowClear
        onFocus={handleOnFocus}
        options={options?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })}
      />
    </ConfigProvider>
  );
};

export { Select };
