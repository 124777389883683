import { useState, useEffect, useCallback, FC } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { Button, Descriptions, Spin, Typography } from "antd";
import { ReconciliationOutlined, UserOutlined } from "@ant-design/icons";

import { api } from "../../../services/api";
import { IUser } from "../../../types";
import { formatters } from "../../../utils/formatters";
import { MenuItem, PageHeader } from "../../../components/PageHeader";
import { Avatar } from "antd/lib";

const { Text } = Typography;

const { Item } = Descriptions;

const User: FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState<IUser>();
  const [loading, setLoading] = useState(false);

  const items: MenuItem[] = [
    {
      label: "Configurações",
      key: "configs",
      icon: <ReconciliationOutlined />,
    },
  ];

  const getData = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/users/${id}`)
      .then(({ data }) => setUser(data))
      .catch((error) => {
        if (error?.response?.data?.code === "E_ROW_NOT_FOUND") {
          navigate("/users");
        }
      })
      .finally(() => setLoading(false));
  }, [navigate, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Spin spinning={loading}>
      <PageHeader
        title={user?.name}
        footer={[
          <Button key={"delete-button"} danger>
            Deletar
          </Button>,
        ]}
        menuItems={items}
      >
        <Descriptions size="small" column={4}>
          <Item label="Avatar">
            <Avatar
              src={user?.account?.image}
              icon={<UserOutlined />}
              shape="square"
              size={"large"}
            />
          </Item>
          <Item label="Email">
            <Text strong style={{ color: "#1668dc" }}>
              {user?.account?.email}
            </Text>
          </Item>
          <Item label="CPF/CNPJ">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.identifier(user?.account?.identifier)}
            </Text>
          </Item>
          <Item label="Telefone">
            <Text strong>{formatters.phone(user?.account?.phone)}</Text>
          </Item>
          <Item label="Perfil">
            <Text strong>{user?.profile}</Text>
          </Item>
          <Item label="ID">
            <Text strong>{String(user?.id || 0).padStart(4, "0")}</Text>
          </Item>
        </Descriptions>
      </PageHeader>
      <div style={{ marginTop: "16px" }}>
        <Outlet />
      </div>
    </Spin>
  );
};

export { User };
