export function ChecklistSliderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="2" fill="#F0F0F0" />
      <path
        d="M23.3328 15.725H7.66724C7.31911 15.725 7 15.35 7 14.8625C7 14.4125 7.2901 14 7.66724 14H23.3328C23.6809 14 24 14.375 24 14.8625C24 15.3125 23.6809 15.725 23.3328 15.725Z"
        fill="#434343"
      />
      <path
        d="M13 18C14.6569 18 16 16.6569 16 15C16 13.3431 14.6569 12 13 12C11.3431 12 10 13.3431 10 15C10 16.6569 11.3431 18 13 18Z"
        fill="#434343"
      />
    </svg>
  );
}
