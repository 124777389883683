import { useState, useEffect, FC } from "react";

import { App, Button, Form, InputNumber, Spin, Switch } from "antd";
import { IOrganization } from "../../../types";
import { api } from "../../../services/api";

const Globals: FC = () => {
  const { message } = App.useApp();
  const [form] = Form.useForm();

  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await api.get<IOrganization>("/organization").then(({ data }) => {
        form.setFieldsValue({
          ...data.configurations,
        });
      });
      setLoading(false);
    };

    getData();
  }, [load, form]);

  const onSubmit = async () => {
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    await api
      .put("/organization/configuration", values)
      .then(() => {
        message.success("Organização atualizada com sucesso");
        setLoad(!load)
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Spin spinning={loading}>
      <Form form={form} layout="vertical" onFinish={onSubmit}>
        <Form.Item label={"Minimo de Fotos"} name={"minimumPhotos"}>
          <InputNumber min={1} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label={"Taxa de Imposto no Serviço"} name={"serviceTax"}>
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label={"Validar Ordens"} name={"orderValidations"} valuePropName="checked">
          <Switch />
        </Form.Item>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: 16,
          }}
        >
          <Button>Cancelar</Button>
          <Button type="primary" htmlType="submit">
            Salvar
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

export { Globals };
