import { FC, useEffect, useState } from "react";
import { Col, Form, Input, Select } from "antd";
import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { IClient, IUser } from "../../../types";
import { getClients } from "../../../services/repositories";
import { getUsers } from "../../users/list";

interface FilterContractsListProps {
  onSubmit?: any;
}

interface FilterContractsFormInstance {
  name?: string;
  units?: string[];
  isConsumible?: boolean;
}

const FilterContractsList: FC<FilterContractsListProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<FilterContractsFormInstance>();
  const [clients, setClients] = useState<IClient[]>([]);
  const [responsibles, setResponsibles] = useState<IUser[]>([]);

  useEffect(() => {
    getClients(1, 1000).then(({ data }) => {
      setClients(data);
    });
  }, []);

  useEffect(() => {
    getUsers(1, 1000)
      .then(({ data }) => {
        setResponsibles(
          data?.data.filter((item) => item.profile === "ATTENDANT")
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const fields = [
    <Col span={6} key={1}>
      <Form.Item name={"contract"} label={"Contrato"}>
        <Input allowClear placeholder="Insira um nome" />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item name={"client"} label={"Cliente"}>
        <Select
          placeholder="Selecione o cliente"
          allowClear
          showSearch
          optionFilterProp="children"
          options={clients.map((client) => {
            return {
              label: client.name,
              value: client.id,
            };
          })}
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item name={"responsible"} label={"Responsável"}>
        <Select
          placeholder="Selecione o responsável"
          allowClear
          showSearch
          optionFilterProp="children"
          options={responsibles.map((responsible) => {
            return {
              label: responsible.name,
              value: responsible.id,
            };
          })}
        />
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: FilterContractsFormInstance) => {
    if (onSubmit) {
      onSubmit({ ...values, units: values.units?.toString() });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterContractsList };
