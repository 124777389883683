import { FC, useEffect, useState } from "react";

import {
  App,
  Button,
  Form,
  Input,
  Modal,
  Spin,
  Switch,
  Upload,
  UploadProps,
} from "antd";

import { TOKEN_API, api } from "../../../services/api";

import { IPmocEquipmentActivity, ModalProps } from "../../../types";
import { useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const PmocActivityModal: FC<ModalProps<IPmocEquipmentActivity>> = ({
  onClose,
  isModalOpen,
  data: activity,
}) => {
  const { id, equipmentId } = useParams();
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any[]>([]);

  useEffect(() => {
    if (isModalOpen) {
      if (activity) {
        let images = activity.images?.map((image, index) => {
          return {
            uid: index,
            status: "done",
            name: image,
            url: image,
            thumbUrl: process.env.REACT_APP_ENDPOINT + image,
          };
        });
        setFiles(images || []);
        form.setFieldsValue(activity);
      }
    }
  }, [form, activity, isModalOpen]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    setFiles([]);
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return { ...values, images: files.map((file) => file.url) };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then(() => {
        message.success("Atividade atualizada com sucesso!");
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    return await api.put(
      `/pmocs/${id}/equipments/${equipmentId}/activities/${activity?.id}`,
      values
    );
  };

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_API + `/upload`,
    fileList: files,
    data: {
      folder: "pmoc_activies",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onChange(info) {
      let filelist = [...info.fileList];
      filelist = filelist.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      setFiles(filelist);

      if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(`${info.file.name}-${err.message}`)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept: "image/*",
    listType: "picture",
    maxCount: 3,
  };

  return (
    <Modal
      title={`Adicinar Atividade`}
      open={isModalOpen}
      centered
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="pmoc_activity_form_modal">
          <Form.Item label="Descrição" name="description">
            <TextArea
              style={{ resize: "none" }}
              rows={5}
              showCount
              maxLength={255}
            />
          </Form.Item>
          <Form.Item label="Realizado" name="isDone" valuePropName="checked">
            <Switch checkedChildren={"SIM"} unCheckedChildren={"NÃO"} />
          </Form.Item>
          <Form.Item
            label="Imagens"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload {...props}>
              {files.length < 3 ? (
                <Button icon={<UploadOutlined />}>Upload</Button>
              ) : null}
            </Upload>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { PmocActivityModal };
