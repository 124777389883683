import { useState } from "react";

export function useModal<T = unknown>(handleRefreshData?: () => void) {
  const [data, setData] = useState<T | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = (record?: T) => {
    if (record) {
      setData(record);
    }
    setIsOpen(true);
  };

  const handleCloseModal = (refresh: boolean = false) => {
    if (refresh && handleRefreshData) {
      handleRefreshData();
    }
    setData(null);
    setIsOpen(false);
  };

  return {
    data,
    isOpen,
    handleOpenModal,
    handleCloseModal,
  };
}
