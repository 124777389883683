import { Spin } from "antd";
import { Content } from "../Content";

const Loading = () => {
  return (
    <Content
      style={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Spin size="large" style={{ justifySelf: "center" }} />
    </Content>
  );
};

export { Loading };
