import { useState, useEffect, useRef, useCallback, FC } from "react";
import { Outlet, useNavigate, useOutlet, useParams } from "react-router-dom";

import { Table, Descriptions, Typography } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";

import { api } from "../../../services/api";

import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { Content } from "../../../components/Content";

import { IEquipment, IMeta, IPagination, IPmocActivity } from "../../../types";
import { FREQUENCIES } from "../../activities/components/ActivityModal";

const { Text } = Typography;

export const getPmocActivities = async (
  pmocId: string,
  equipmentId: string,
  page = 1,
  perPage = 10
) => {
  return await api.get<IPagination<IPmocActivity>>(
    `/pmocs/${pmocId}/equipments/${equipmentId}`,
    {
      params: {
        page,
        perPage,
      },
    }
  );
};

const getEquipmentById = async (equipmentId: string) => {
  return await api.get<IEquipment>(`/equipments/${equipmentId}`);
};

const EquipmentActivities: FC = () => {
  const itemsTable = useRef<any>();
  const outlet = useOutlet();
  const navigate = useNavigate();
  const { id, equipmentId } = useParams();

  const [activities, setActivities] = useState<IPmocActivity[]>([]);
  const [equipment, setEquipment] = useState<IEquipment>();
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<IMeta>({
    current_page: 1,
    per_page: 10,
  });

  const handleChangePage = useCallback(
    async (page: number, pageSize: number) => {
      setLoading(true);
      getPmocActivities(String(id), String(equipmentId), page, pageSize)
        .then(({ data }) => {
          setActivities(data?.data);
          setMeta(data?.meta);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [id, equipmentId]
  );

  useEffect(() => {
    handleChangePage(1, meta.per_page);
  }, [id, meta.per_page, handleChangePage]);

  useEffect(() => {
    const getData = async () => {
      await getEquipmentById(String(equipmentId)).then(({ data }) =>
        setEquipment(data)
      );
    };

    getData();
  }, [equipmentId]);

  const actions: ActionFunction<IPmocActivity> = (record) => {
    return [
      {
        key: "1",
        icon: <SearchOutlined />,
        label: "Detalhar",
        onClick: () =>
          navigate(`activity/${record.id}`, {
            state: record,
          }),
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => {},
      },
    ];
  };

  const columns: ColumnsType<IPmocActivity> = [
    {
      title: "Nome",
      dataIndex: "activity",
      key: "name",
      render: (text) => text.name,
      ellipsis: true,
      width: "70%",
    },
    {
      title: "Frequência",
      dataIndex: "frequency",
      key: "frequency",
      render: (frequency) =>
        FREQUENCIES.find(
          ({ value }) => value === frequency
        )?.label.toUpperCase(),
    },
    ActionColumn(actions),
  ];

  return (
    <Content width="calc(100% - 48px)">
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Equipamento"}
      >
        <Descriptions size="small" column={2}>
          <Descriptions.Item label="Equipamento">
            <Text strong>{equipment?.name}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Código">
            <Text strong>{equipment?.code}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Local">
            <Text strong>{equipment?.local?.name}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Unidade">
            <Text strong>{equipment?.unit?.name}</Text>
          </Descriptions.Item>
          <Descriptions.Item label="Setor">
            <Text strong>{equipment?.sector?.name}</Text>
          </Descriptions.Item>
        </Descriptions>
      </PageHeader>
      {outlet ? (
        <Outlet />
      ) : (
        <Table
          ref={itemsTable}
          pagination={{
            position: ["bottomCenter"],
            showSizeChanger: true,
            pageSizeOptions: [5, 10, 20, 30, 50],
            pageSize: meta.per_page,
            total: meta.total,
            onChange: handleChangePage,
          }}
          size="small"
          loading={loading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={activities}
        />
      )}
    </Content>
  );
};

export { EquipmentActivities };
