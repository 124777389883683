import { FC } from "react";
import { Form, FormInstance, Input } from "antd";
import { formatters } from "../../../../utils";

interface PFProps {
  form: FormInstance;
}

const PF: FC<PFProps> = ({ form }) => {
  return (
    <>
      <Form.Item
        label="Nome:"
        name="name"
        rules={[
          {
            required: true,
            message: "Por favor, insira o nome!",
          },
        ]}
      >
        <Input placeholder="Insira o nome" />
      </Form.Item>
      <Form.Item
        label="CPF:"
        name="document"
        normalize={formatters.identifier}
        rules={[
          {
            min: 11,
            message: "Por favor, insira um CPF valido!",
          },
          {
            max: 14,
            message: "Por favor, insira um CPF valido!",
          },
          {
            required: true,
            message: "Por favor, insira um CPF!",
          },
        ]}
      >
        <Input placeholder="Insira o CPF" />
      </Form.Item>
    </>
  );
};

export { PF };
