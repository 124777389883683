import { useState, useEffect, FC } from "react";

import { App, Checkbox, Spin } from "antd";
import { api } from "../../../services/api";

interface IPermissions {
  id: string;
  module: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

interface IRole {
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  permissions?: IPermissions[];
}

const Permissions: FC = () => {
  const { message } = App.useApp();
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(true);

  const [roles, setRoles] = useState<IRole[]>([]);
  const [permissions, setPermissions] = useState<IPermissions[]>([]);

  useEffect(() => {
    const getData = async () => {
      await api
        .get<IPermissions[]>("/organization/permissions")
        .then(({ data }) => {
          setPermissions(data);
        })
        .finally(() => setLoading(false));
    };

    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await api
        .get<IRole[]>("/organization/role-permissions")
        .then(({ data }) => {
          setRoles(data);
        })
        .finally(() => setLoading(false));
    };

    getData();
  }, [load]);

  const handleChangePermission = async (
    roleId: string,
    permissionId: string,
    checked: boolean
  ) => {
    await api
      .put("/organization/permissions", {
        roleId,
        permissionId,
        checked,
      })
      .then(() => setLoad(!load))
      .catch(() => message.error(`Error`));
  };

  return (
    <Spin spinning={loading}>
      {roles && (
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th
                style={{
                  width: "40%",
                  textAlign: "start",
                }}
              />
              {roles.map((role) => (
                <th key={role.id}>{role.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {permissions.map((permission) => (
              <tr key={permission.id}>
                <td style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                  {permission.description}
                </td>
                {roles.map((role, index) => (
                  <td key={index} style={{ textAlign: "center" }}>
                    <Checkbox
                      onChange={(e) =>
                        handleChangePermission(
                          role.id,
                          permission.id,
                          e.target.checked
                        )
                      }
                      checked={
                        !!role.permissions?.find(
                          (rolePerm: any) => rolePerm.id === permission.id
                        )
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </Spin>
  );
};

export { Permissions };
