import { FC, useEffect } from "react";
import { Col, DatePicker, Form, Input, InputNumber, Select } from "antd";
import { ExpandableFilter } from "../../../components/ExpandableFilter";
import { useSearchParams } from "react-router-dom";
import { InputMask } from "../../../components/InputMask";
import dayjs from "dayjs";

const { Option } = Select;

interface ClientSeachForm {
  id?: number;
  name?: string;
  identifier: string;
  clientType?: "LEGAL" | "NATURAL";
  ie?: string;
  im?: string;
  isActive?: true;
  createdAt?: string;
}

interface FilterItemListProps {
  onSubmit: (values?: any) => void;
  filter?: any;
}

const FilterClientList: FC<FilterItemListProps> = ({ onSubmit, filter }) => {
  const [form] = Form.useForm<ClientSeachForm>();

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (filter) {
      form.setFieldsValue({
        ...filter,
        createdAt: filter.createdAt ? dayjs(filter.createdAt) : undefined,
      });
    }
  }, [filter, form]);

  const onFinish = (values: any) => {
    const a = JSON.parse(
      JSON.stringify({
        ...values,
        identifier: values.identifier?.replace(/\D/g, ""),
      })
    );
    setSearchParams(a);
    onSubmit(a);
  };

  const onClear = () => {
    setSearchParams();
    onSubmit();
  };

  const fields = [
    <Col span={6} key={1}>
      <Form.Item name={"id"} label={"ID"}>
        <InputNumber
          placeholder="Insira o ID"
          min={1}
          style={{ width: "100%" }}
        />
      </Form.Item>
    </Col>,
    <Col span={6} key={2}>
      <Form.Item name={"name"} label={"Cliente"}>
        <Input placeholder="Insira o nome" />
      </Form.Item>
    </Col>,
    <Col span={6} key={3}>
      <Form.Item name={"identifier"} label={"CNPJ/CPF"}>
        <InputMask placeholder="Insira o CNPJ/CPF" />
      </Form.Item>
    </Col>,
    <Col span={6} key={4}>
      <Form.Item name={"clientType"} label={"Tipo de Cliente"}>
        <Select placeholder="Selecione o tipo de cliente" allowClear>
          <Option value="LEGAL">JURÍDICA</Option>
          <Option value="NATURAL">FÍSICA</Option>
        </Select>
      </Form.Item>
    </Col>,
    <Col span={8} key={5}>
      <Form.Item name={"createdAt"} label={"Criado em"}>
        <DatePicker
          onChange={(value) => {
            if (!value) {
              form.setFieldValue("createdAt", undefined);
            }
          }}
          format="DD/MM/YYYY"
          style={{ width: "100%" }}
          placeholder="Selecione a data"
          allowClear
        />
      </Form.Item>
    </Col>,
  ];

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { FilterClientList };
