import { FC, useEffect, useState } from "react";
import "dayjs/locale/pt-br";

import {
  Button,
  Form,
  Input,
  message as Message,
  Modal,
  Select,
  Spin,
} from "antd";

import { api } from "../../../services/api";

import { ClientLegalForm } from "../forms/ClientLegalForm";
import { ClientNaturalForm } from "../forms/ClientNaturalForm";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { formatters } from "../../../utils/formatters";
import { parsers } from "../../../utils/parsers";
import { IClient, ModalProps } from "../../../types";

const clientTypes = [
  { label: "PESSOA JURÍDICA", value: "LEGAL" },
  { label: "PESSOA FÍSICA", value: "NATURAL" },
];

const ClientModal: FC<ModalProps<IClient>> = ({
  onClose,
  data: client,
  isModalOpen,
}) => {
  const [message, context] = Message.useMessage();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      if (client) {
        form.setFieldsValue(client);

        await api.get<IClient>(`/clients/${client.id}`).then(({ data }) => {
          const phones = data.phones.map((phone) => {
            return { phone: phone.phone };
          });

          const emails = data.emails.map((email) => {
            return { email: email.email };
          });

          form.setFieldValue("phones", phones);
          form.setFieldValue("emails", emails);
        });
      }
    };
    if (isModalOpen) {
      getData().finally(() => setLoading(false));
    }
  }, [form, client, isModalOpen]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const handleChangeClientType = async (serviceType: string) => {
    form.resetFields();
    form.setFieldValue("clientType", serviceType);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          identifier: parsers.identifier(values.identifier),
          phones: values.phones?.map((phone: { phone: string }) => {
            return { phone: parsers.phone(phone.phone) };
          }),
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Cliente ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    if (client) {
      return await api.put(`/clients/${client.id}`, values);
    }
    return await api.post(`/clients`, values);
  };

  return (
    <Modal
      title={`${client ? "Editar" : "Cadastrar"} cliente`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          {client ? "Salvar" : "Adicionar"}
        </Button>,
      ]}
    >
      {context}
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="client_form_modal">
          <Form.Item
            label="Tipo de cliente:"
            name="clientType"
            rules={[
              {
                required: true,
                message: "Por favor, selecione o tipo de cliente!",
              },
            ]}
          >
            <Select
              optionFilterProp="children"
              showSearch
              allowClear
              options={clientTypes.map((value) => value)}
              onChange={handleChangeClientType}
              placeholder="Selecione o tipo de cliente"
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              const clientType = form.getFieldValue("clientType");
              if (clientType === "LEGAL") {
                return <ClientLegalForm form={form} />;
              }
              return <ClientNaturalForm form={form} />;
            }}
          </Form.Item>
          <div
            style={{ display: "flex", justifyContent: "space-between", gap: 8 }}
          >
            <Form.Item label="E-mail" style={{ width: "100%" }}>
              <Form.List
                name="emails"
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: 4,
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "email"]}
                          rules={[
                            { type: "email", message: "Insira email valido" },
                          ]}
                          style={{
                            width: "100%",
                          }}
                        >
                          <Input placeholder="Insira o email" />
                        </Form.Item>
                        <MinusCircleOutlined
                          style={{
                            marginBottom: 24,
                          }}
                          onClick={() => remove(name)}
                        />
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Adicionar e-mail
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
            <Form.Item label="Telefone" style={{ width: "100%" }}>
              <Form.List
                name="phones"
              >
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          gap: 4,
                        }}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "phone"]}
                          style={{
                            width: "100%",
                          }}
                          normalize={formatters.phone}
                        >
                          <Input placeholder="Insira o telefone" />
                        </Form.Item>
                        <MinusCircleOutlined
                          style={{
                            marginBottom: 24,
                          }}
                          onClick={() => remove(name)}
                        />
                      </div>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        Adicionar telefone
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
            </Form.Item>
          </div>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ClientModal };
