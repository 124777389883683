import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import {
  Button,
  Form,
  Input,
  Typography,
  theme,
  Divider,
  Spin,
  App,
} from "antd";
import { UserOutlined } from "@ant-design/icons";

import { Content } from "../../../components/Content";
import { useAuth } from "../../../contexts/AuthContext";

import Image from "../../../assets/logo.svg";

const { Text } = Typography;

type FormProps = { username: string; password: string };

const ForgortPassword = () => {
  const { message } = App.useApp();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [form] = Form.useForm();
  const [state] = useState(location.state || {});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      navigate(state?.from || "/");
    }
  }, [isAuthenticated, navigate, state]);

  const {
    token: { colorPrimary, colorBgContainer, colorTextDescription },
  } = theme.useToken();

  const onFinish = async (values: FormProps) => {
    setIsLoading(true);
    await axios
      .post(process.env.REACT_APP_API + "/forgot-password", values)
      .then(() => message.success("Email enviado com sucesso!"))
      .then(() => navigate("/login"))
      .catch((err) => {
        if (err?.response?.data?.code === "E_ROW_NOT_FOUND") {
          message.error("Usuário não encontrado.");
        } else {
          message.error("Algo inesperado ocorreu.");
        }
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Content
      style={{
        minHeight: "100vh",
        background: colorBgContainer,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          padding: 8,
          minHeight: 500,
          minWidth: 400,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 16,
          }}
        >
          <img src={Image} alt="Logo Arcke" style={{ height: "68px" }} />
          <Text style={{ color: colorTextDescription, textAlign: "center" }}>
            Sistema de Abertura e Gerenciamento de Ordens de Serviço
          </Text>
        </div>
        <div>
          <Divider />
          <Spin spinning={isLoading}>
            <Form
              form={form}
              name="basic"
              layout="vertical"
              style={{ width: 350 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item
                label="Nome de Usuário"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Por favor insira seu Nome de Usuário!",
                  },
                  {
                    min: 6,
                    message:
                      "O Nome de Usuário deve ter no minimo 6 caracteres!",
                  },
                  {
                    max: 20,
                    message:
                      "O Nome de Usuário deve ter no máximo 20 caracteres!",
                  },
                ]}
              >
                <Input
                  onChange={() =>
                    form.setFieldsValue({
                      username: (
                        form.getFieldValue("username") || ""
                      ).toLowerCase(),
                    })
                  }
                  prefix={
                    <UserOutlined
                      style={{
                        color: colorPrimary,
                      }}
                    />
                  }
                  placeholder="Nome de Usuário"
                  size="large"
                />
              </Form.Item>
              <Form.Item>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "start",
                    gap: 8,
                  }}
                >
                  <Button type="primary" size="large" htmlType="submit">
                    Recuperar
                  </Button>
                  <Button
                    type="link"
                    htmlType="button"
                    onClick={() => navigate(-1)}
                    style={{ padding: 0 }}
                  >
                    Voltar
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    </Content>
  );
};

export { ForgortPassword };
