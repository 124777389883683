import { useState, useEffect, useCallback, useRef, FC } from "react";
import { useParams } from "react-router-dom";

import { App, Button, Image, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
} from "@ant-design/icons";

import { api } from "../../../services/api";
import { IProduct, IPagination } from "../../../types";

import { ActionColumn } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { formatters } from "../../../utils/formatters";
import { ProductModal } from "../components/ServiceProductModal";

import fallback from "../../../assets/fallaback-image.png";

const getServiceProducts = async (
  orderId: string,
  serviceId: string,
  page = 1,
  perPage = 1000
) => {
  return await api.get<IPagination<IProduct>>(
    `/service-orders/${orderId}/services/${serviceId}/products`,
    {
      params: {
        page,
        perPage,
      },
    }
  );
};

const OrderServiceProducts: FC = () => {
  const { modal, message } = App.useApp();

  const localsTable = useRef<any>();
  const { id, serviceId } = useParams();

  const [serviceProducts, setServiceProducts] = useState<IProduct[]>();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [serviceproduct, setServiceProduct] = useState<IProduct>();

  const handleChangePage = useCallback(async () => {
    setLoading(true);
    await getServiceProducts(id!, serviceId!)
      .then(({ data }) => {
        setServiceProducts(data.data);
        localsTable.current?.scrollIntoView({ behavior: "smooth" });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, serviceId]);

  useEffect(() => {
    handleChangePage();
  }, [handleChangePage, load]);

  const actions = (record: IProduct) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleShowModal(record),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => handleDeleteProduct(record),
      },
    ];
  };

  const columns: ColumnsType<IProduct> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 80,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (text, record) => (
        <Image
          src={process.env.REACT_APP_ENDPOINT + text}
          height={64}
          fallback={fallback}
          loading="lazy"
        />
      ),
      width: 120,
    },
    {
      title: "Unidade",
      dataIndex: "unit",
      key: "unit",
      ellipsis: true,
      width: 120,
    },
    {
      title: "Quantidade",
      dataIndex: "meta",
      key: "quantity",
      width: 120,
      render: ({ pivot_quantity }) => pivot_quantity,
    },
    {
      title: "Valor",
      dataIndex: "meta",
      key: "value",
      render: ({ pivot_value }) => formatters.currency(pivot_value),
      width: 120,
    },
    {
      title: "Custo",
      dataIndex: "meta",
      key: "cost",
      render: ({ pivot_cost }) => formatters.currency(pivot_cost),
      width: 120,
    },
    ActionColumn(actions),
  ];

  const handleShowModal = (record?: IProduct) => {
    if (record) {
      setServiceProduct(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = (refresh?: boolean) => {
    if (refresh) {
      setLoad(!load);
    }
    setIsModalOpen(false);
    setServiceProduct(undefined);
  };

  const handleDeleteProduct = (record: IProduct) => {
    modal.confirm({
      title: "Deseja excluir esse produto?",
      icon: <ExclamationCircleFilled />,
      content: "Atenção! essa ação é irreversivel.",
      okType: "danger",
      async onOk() {
        return await api
          .delete(
            `/service-orders/${id}/services/${serviceId}/products/${record.id}`
          )
          .then(() => {
            message.success("Produto deletado com sucesso!");
            setLoad(!load);
          })
          .catch(() => message.error("Algo inesperado ocorreu!"));
      },
    });
  };

  return (
    <>
      <ProductModal
        isModalOpen={isModalOpen}
        onClose={handleCloseModal}
        data={serviceproduct}
      />
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        backButton={false}
        title={"Produtos"}
        actions={[
          <Button
            key={"add_product"}
            type="primary"
            onClick={() => handleShowModal()}
            icon={<PlusOutlined />}
          >
            Adicionar Produto
          </Button>,
        ]}
      />
      <Table
        ref={localsTable}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
        }}
        size="small"
        loading={loading}
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={serviceProducts}
      />
    </>
  );
};

export { OrderServiceProducts };
