import { FC, useEffect, useState } from "react";
import { App, Button, Form, Modal, Select, Space, Spin } from "antd";
import { api } from "../../../services/api";

import { useContract } from "../../../contexts/ContractContext";
import { getClientLocals } from "../../clients/client/ClientLocals";

import { IContractLocal, ILocal, ModalProps } from "../../../types";
import { PlusOutlined } from "@ant-design/icons";
import { CreateLocalModal } from "./CreateLocalModal";
import { useModal } from "../../../hooks/useModal";

const ContractLocalModal: FC<ModalProps<IContractLocal>> = ({
  onClose,
  isModalOpen,
  data: contractLocal,
}) => {
  const { message } = App.useApp();
  const { contract } = useContract();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [load, setLoad] = useState(false);
  const [locals, setLocals] = useState<ILocal[]>([]);

  const handleCloseModal = (refresh = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return values;
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then(() => {
        message.success("Local adicionado com sucesso!");
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        }
      })
      .finally(() => setLoading(false));
  };

  const handleSubmitForm = async (values: IContractLocal) => {
    if (contractLocal) {
      return await api.put<IContractLocal>(
        `/contracts/${contract?.id}/locals/${contractLocal.id}`,
        values
      );
    }
    return await api.post<IContractLocal>(
      `/contracts/${contract?.id}/locals`,
      values
    );
  };

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getClientLocals(contract?.clientId, 1, 1000, {
        notInContractId: contract?.id,
      })
        .then(({ data }) => setLocals(data.data))
        .catch(() => console.log("err"))
        .finally(() => setLoading(false));
    };
    if (isModalOpen) {
      getData();
    }
  }, [load, contract?.clientId, contract?.id, isModalOpen]);

  const {
    isOpen,
    handleCloseModal: handleCloseLocalModal,
    handleOpenModal,
  } = useModal(() => setLoad(!load));

  return (
    <>
      <CreateLocalModal
        isModalOpen={isOpen}
        data={contract}
        onClose={handleCloseLocalModal}
      />
      <Modal
        title={`${contractLocal ? "Editar" : "Adicionar"} Local`}
        open={isModalOpen}
        centered
        onCancel={() => handleCloseModal()}
        footer={[
          <Button
            key="back"
            onClick={() => handleCloseModal()}
            danger
            disabled={loading}
          >
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onSubmit}
            loading={loading}
          >
            Salvar
          </Button>,
        ]}
      >
        <Spin spinning={loading}>
          <Form form={form} layout="vertical" name="contract_local_form_modal">
            <Space.Compact
              style={{ width: "100%", alignItems: "center", gap: "8px" }}
            >
              <Form.Item
                style={{ width: "90%" }}
                name="locals"
                label="Local"
                rules={[
                  {
                    required: true,
                    message: "Por favor, selecione um local.",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  disabled={!!contractLocal}
                  optionFilterProp="children"
                  showSearch
                  allowClear
                  options={locals.map((item) => {
                    return { value: item.id, label: item.name };
                  })}
                />
              </Form.Item>
              <Button
                style={{ marginTop: "0.6rem" }}
                icon={<PlusOutlined />}
                type="primary"
                onClick={handleOpenModal}
              />
            </Space.Compact>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

export { ContractLocalModal };
