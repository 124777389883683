import queryString from "query-string";
import { LoaderFunction } from "react-router-dom";

const loader: LoaderFunction = ({ request }) => {
  const url = new URL(request.url);
  const items = url.href?.split("?")[1];

  return items ? queryString.parse(items) : null;
};

export { loader };
