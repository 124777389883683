import { useState, useEffect, FC } from "react";
import { Outlet, useLoaderData } from "react-router-dom";

import { Descriptions, Spin, Typography } from "antd";
import {
  BankOutlined,
  FilePdfOutlined,
  OrderedListOutlined,
  ReconciliationOutlined,
} from "@ant-design/icons";

import { ContractStatusTag } from "../components/ContractStatusTag";

import { IContract } from "../../../types";
import { formatters } from "../../../utils/formatters";
import { MenuItem, PageHeader } from "../../../components/PageHeader";
import { useContract } from "../../../contexts/ContractContext";

const { Text, Paragraph } = Typography;

const { Item } = Descriptions;

const Contract: FC = () => {
  const data = useLoaderData() as IContract;

  const { handleSetContract } = useContract();

  const [contract] = useState<IContract>(data);
  const [loading] = useState(false);

  const items: MenuItem[] = [
    {
      label: "Aditivos",
      key: "additives",
      icon: <ReconciliationOutlined />,
    },
    {
      label: "Itens de serviços",
      key: "items",
      icon: <OrderedListOutlined />,
    },
    {
      label: "Produtos",
      key: "products",
      icon: <OrderedListOutlined />,
    },
    {
      label: "Locais",
      key: "locals",
      icon: <BankOutlined />,
    },
    {
      label: "Arquivos",
      key: "files",
      icon: <FilePdfOutlined />,
    },
  ];

  useEffect(() => {
    handleSetContract(contract);
  }, [contract, handleSetContract]);

  return (
    <Spin spinning={loading}>
      <PageHeader title={contract?.name} menuItems={items} crumb>
        <Descriptions size="small" column={4}>
          <Item label="Contrato">
            <Text strong>{contract?.name}</Text>
          </Item>
          <Item label="Total de Ordens De Serviços">
            <Text strong>{0}</Text>
          </Item>
          <Item label="Valor">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.currency(contract?.value)}
            </Text>
          </Item>
          <Item label="Inicio">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.simpleDate(contract?.startDate)}
            </Text>
          </Item>
          <Item label="Cliente">
            <Text strong>{contract?.client?.name}</Text>
          </Item>
          <Item label="Responsavel">
            <Text strong>{contract?.responsible?.name}</Text>
          </Item>
          <Item label="ID">
            <Text strong>{String(contract?.id || 0).padStart(4, "0")}</Text>
          </Item>
          <Item label="Fim">
            <Text strong style={{ color: "#1668dc" }}>
              {formatters.simpleDate(contract?.endDate)}
            </Text>
          </Item>
          <Item label="Status" span={4}>
            {contract ? <ContractStatusTag status={contract.status} /> : null}
          </Item>
          <Item label="Objeto" span={4}>
            <Paragraph
              strong
              ellipsis={{ rows: 2, expandable: true, symbol: "mais" }}
            >
              {contract?.object}
            </Paragraph>
          </Item>
        </Descriptions>
      </PageHeader>
      <div style={{ marginTop: "16px" }}>
        <Outlet />
      </div>
    </Spin>
  );
};

export { Contract };
