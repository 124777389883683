import { RouteObject } from "react-router-dom";
import { Permissions } from ".";

export const OrganizationRouter: RouteObject = {
  path: "/organization",
  children: [
    {
      index: true,
      element: <Permissions />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
