import { FC } from "react";

import { App, Button } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";

import { IServiceOrder } from "../../../types";
import { Content } from "../../../components/Content";
import { PageHeader } from "../../../components/PageHeader";
import { formatters } from "../../../utils/formatters";
import { OrderType } from "../../orders/components/OrderType";
import { ServiceOrderStatusTag } from "../../orders/components/ServiceOrderStatusTag";
import { FilterOrderList } from "./FilterOrderList";
import { reports } from "../../../services/reports";
import { getOrders } from "../../../services/repositories";
import { ActionTable } from "../../../components/ActionTable";
import { ActionFunction } from "../../../components/ActionColumn";
import { usePagination } from "../../../hooks";

const DetailedItem: FC = () => {
  const { message } = App.useApp();

  const {
    data: orders,
    isLoading,
    filter,
    setFilter,
    pagination,
  } = usePagination(getOrders);

  const columns: ColumnsType<IServiceOrder> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      width: 80,
      render: (value) => value?.toString().padStart(4, "0"),
    },
    {
      title: "Nº Referência",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      width: 120,
      render: (value) => value || "--",
    },
    {
      title: "Atendimento",
      dataIndex: "type",
      key: "type",
      render: (type) => <OrderType type={type} />,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: (text) => text?.name || "--",
      ellipsis: true,
    },
    {
      title: "Contrato",
      dataIndex: "contract",
      key: "contract",
      render: (text) => text?.name || "--",
      ellipsis: true,
    },
    {
      title: "Técnico",
      dataIndex: "users",
      key: "users",
      render: (users: IServiceOrder["users"]) => {
        return users.find((user) => user.profile === "TECHNICIAN")?.name;
      },
      ellipsis: true,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatters.simpleDate(text),
    },
    {
      title: "Prévisão de Atendimento",
      dataIndex: "startTo",
      key: "startTo",
      render: (text) => formatters.simpleDate(text),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (status) => <ServiceOrderStatusTag status={status} />,
    },
  ];

  const actions: ActionFunction<IServiceOrder> = (record) => {
    return [
      {
        key: "1",
        icon: <PrinterOutlined />,
        label: "Imprimir Registro",
        onClick: () => onPrint(record.id),
      },
    ];
  };

  const onPrint = (record?: string) => {
    if (record) {
      reports("detailed-items", { id: record });
      return;
    }
    if (filter && orders.length > 0) {
      reports("detailed-items", filter);
      return;
    }
    if (filter && orders.length === 0) {
      message.info("Não há registros para impressão.");
      return;
    }
    message.error("Você precisa filtar as informações!");
  };

  return (
    <>
      <PageHeader
        title="Relatórios de Itens de Serviços Detalhados"
        backButton={false}
        actions={[
          <Button
            onClick={() => onPrint()}
            key={"print_table"}
            icon={<PrinterOutlined />}
            type="primary"
          >
            Imprimir
          </Button>,
        ]}
        crumb
      />
      <Content margin>
        <FilterOrderList
          onSubmit={(values: any) => {
            setFilter(values);
          }}
          filter={filter}
        />
      </Content>
      <Content margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          size="small"
          loading={isLoading}
          rowKey={(record) => record.id}
          columns={columns}
          dataSource={orders}
        />
      </Content>
    </>
  );
};

export { DetailedItem };
