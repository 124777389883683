export function ChecklistSelectIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="2" fill="#F0F0F0" />
      <path
        d="M15.4867 18C15.3327 18 15.2044 17.9487 15.0761 17.846L9.17324 12.0458C8.94225 11.8148 8.94225 11.4555 9.17324 11.2246C9.40422 10.9936 9.76352 10.9936 9.9945 11.2246L15.4867 16.5885L20.9789 11.1732C21.2099 10.9423 21.5692 10.9423 21.8002 11.1732C22.0312 11.4042 22.0312 11.7635 21.8002 11.9945L15.8973 17.7947C15.769 17.923 15.6407 18 15.4867 18Z"
        fill="#434343"
      />
    </svg>
  );
}
