import { FC, useEffect, useState } from "react";

import {
  App,
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Spin,
} from "antd";

import { api } from "../../../services/api";
import {
  IBrand,
  IClient,
  IEquipment,
  IEquipmentType,
  ILocal,
  ISector,
  IUnit,
  ModalProps,
} from "../../../types";
import { getEquipmentTypes } from "../../equipmentTypes/list";
import { getClientLocals } from "../../clients/client/ClientLocals";
import { getLocalUnits } from "../../clients/client/ClientLocalUnits";
import { getUnitSectors } from "../../clients/client/ClientUnitSectors";
import { formatters } from "../../../utils";
import dayjs from "dayjs";
import { useLocation, useParams } from "react-router-dom";
import { getBrands, getClients } from "../../../services/repositories";

export const unitTypes = [
  { label: "Unidade", value: "UN" },
  { label: "Caixa", value: "CX" },
  { label: "Kit", value: "KIT" },
  { label: "Barra", value: "BR" },
  { label: "Metro", value: "M1" },
  { label: "Metro Quadrados", value: "M2" },
  { label: "Metro Cubicos", value: "M3" },
  { label: "Galão", value: "GL" },
  { label: "Hora", value: "HRS" },
  { label: "Kilo", value: "KG" },
  { label: "Litro", value: "LT" },
  { label: "Serviço", value: "SERV" },
  { label: "Tonelada", value: "TON" },
  { label: "Rolo", value: "ROLO" },
  { label: "Peça", value: "PC" },
];

const EquipmentModal: FC<ModalProps<IEquipment>> = ({
  onClose,
  isModalOpen,
  data: equipment,
}) => {
  const { pathname } = useLocation();
  const { id } = useParams();

  const CLIENT_PAGE = pathname.slice(0, 8) === "/clients";

  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [equipmentType, setEquipmentType] = useState<IEquipmentType>();

  const [equipmentTypes, setEquipmentTypes] = useState<IEquipmentType[]>([]);
  const [clients, setClients] = useState<IClient[]>([]);
  const [locals, setLocals] = useState<ILocal[]>([]);
  const [units, setUnits] = useState<IUnit[]>([]);
  const [sectors, setSectors] = useState<ISector[]>([]);
  const [brands, setBrands] = useState<IBrand[]>([]);

  useEffect(() => {
    const getData = async () => {
      await getEquipmentTypes(1, 1000).then(({ data }) => {
        setEquipmentTypes(data.data);
        if (equipment) {
          const i = data.data.find(
            (equipmentType) => equipmentType.id === equipment.equipmentTypeId
          );

          setEquipmentType(i);
        }
      });
      await getClientLocals(equipment ? equipment.clientId : id, 1, 1000).then(
        ({ data }) => {
          setLocals(data.data);
        }
      );
      if (equipment) {
        if (equipment.unitId) {
          await getLocalUnits(equipment.localId, 1, 1000).then(({ data }) => {
            setUnits(data.data);
          });
        }
        if (equipment.sectorId) {
          await getUnitSectors(equipment.unitId, 1, 1000).then(({ data }) => {
            setSectors(data.data);
          });
        }
      }
      await getClients(1, 1000).then(({ data }) => {
        setClients(data);
      });
      await getBrands({ page: 1, perPage: 1000 }).then(({ data }) => {
        setBrands(data);
      });

      if (pathname.slice(0, 8) === "/clients") {
        form.setFieldValue("clientId", pathname.slice(9, 45));
      }
    };
    if (isModalOpen) {
      getData().then(() => {
        if (equipment) {
          form.setFieldsValue({
            ...equipment,
            fabricate: dayjs(
              formatters.simpleDate(equipment.fabricate),
              "DD/MM/YYYY"
            ),
          });
        }
      });
    }
  }, [form, pathname, id, isModalOpen, equipment]);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }

    form.resetFields();
    setEquipmentType(undefined);
    setClients([]);
    setLocals([]);
    setUnits([]);
    setSectors([]);
    setBrands([]);
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then((data) => {
        message.success(
          `Equipamento ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: IEquipment) => {
    if (equipment) {
      return await api.put<IEquipment>(`/equipments/${equipment.id}`, values);
    }
    return await api.post<IEquipment>(`/equipments`, values);
  };

  const handleChangeClient = async (clientId: string) => {
    await getClientLocals(clientId, 1, 1000).then(({ data }) => {
      setLocals(data.data);
    });
  };

  const handleChangeLocal = async (localId: string) => {
    await getLocalUnits(localId, 1, 1000).then(({ data }) => {
      setUnits(data.data);
    });
  };

  const handleChangeUnit = async (unitId: string) => {
    await getUnitSectors(unitId, 1, 1000).then(({ data }) => {
      setSectors(data.data);
    });
  };

  const IS_EDIT_EQUIPMENT = !!equipment;

  return (
    <Modal
      title={`${equipment ? "Editar" : "Criar"} Equipamento`}
      open={isModalOpen}
      style={{ top: 20 }}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="equipment_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome do Equipamento!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Tipo de Equipamento"
            name="equipmentTypeId"
            rules={[
              {
                required: true,
                message: "Por favor selecione ao menos um tipo de Equipamento!",
              },
            ]}
          >
            <Select
              filterOption={true}
              showSearch
              allowClear
              options={equipmentTypes.map((equipmentType) => {
                return { value: equipmentType.id, label: equipmentType.name };
              })}
              onChange={(e) => {
                const i = equipmentTypes.find((item) => item.id === e);
                setEquipmentType(i);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Cliente"
            name="clientId"
            rules={[
              {
                required: true,
                message: "O cliente precisa ser selecionado",
              },
            ]}
          >
            <Select
              disabled={IS_EDIT_EQUIPMENT || CLIENT_PAGE}
              placeholder="Selecione..."
              showSearch
              allowClear
              onChange={(e) => handleChangeClient(e)}
              optionLabelProp="label"
              optionFilterProp="children"
              options={clients.map((client) => {
                return {
                  value: client.id,
                  label: client.name,
                };
              })}
            />
          </Form.Item>
          <Form.Item
            label="Local"
            name="localId"
            rules={[
              {
                required: true,
                message: "O local precisa ser selecionado",
              },
            ]}
          >
            <Select
              placeholder="Selecione..."
              showSearch
              allowClear
              optionLabelProp="label"
              optionFilterProp="label"
              onChange={(e) => handleChangeLocal(e)}
              options={locals.map((local) => {
                return {
                  value: local.id,
                  label: local.name,
                };
              })}
            />
          </Form.Item>
          {units.length > 0 ? (
            <Form.Item
              label="Unidade"
              name="unitId"
              rules={[
                {
                  required: true,
                  message: "A unidade precisa ser selecionada",
                },
              ]}
            >
              <Select
                placeholder="Selecione..."
                showSearch
                allowClear
                optionLabelProp="label"
                optionFilterProp="children"
                onChange={(e) => handleChangeUnit(e)}
                options={units.map((unit) => {
                  return {
                    value: unit.id,
                    label: unit.name,
                  };
                })}
              />
            </Form.Item>
          ) : null}
          {sectors.length > 0 ? (
            <Form.Item
              label="Setor"
              name="sectorId"
              rules={[
                {
                  required: true,
                  message: "O setor precisa ser selecionado",
                },
              ]}
            >
              <Select
                placeholder="Selecione..."
                showSearch
                allowClear
                optionLabelProp="label"
                optionFilterProp="children"
                options={sectors.map((sector) => {
                  return {
                    value: sector.id,
                    label: sector.name,
                  };
                })}
              />
            </Form.Item>
          ) : null}
          <Form.Item shouldUpdate noStyle>
            {() => {
              if (equipmentType) {
                return (
                  <>
                    {equipmentType.model && (
                      <Form.Item
                        label="Modelo"
                        name="model"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor insira o modelo do Equipamento!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                    {equipmentType.serial && (
                      <Form.Item
                        label="N.º Serial"
                        name="serial"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor insira o N.º Serial do Equipamento!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    )}
                    {equipmentType.brand && (
                      <Form.Item
                        label="Marca"
                        name="brandId"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor selecione a marca do Equipamento!",
                          },
                        ]}
                      >
                        <Select
                          optionFilterProp="children"
                          showSearch
                          allowClear
                          options={brands.map((brand) => {
                            return {
                              value: brand.id,
                              label: brand.name,
                            };
                          })}
                        />
                      </Form.Item>
                    )}
                    {equipmentType.btus && (
                      <Form.Item
                        label="BTUS"
                        name="btus"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor selecione o BTU do Equipamento!",
                          },
                        ]}
                      >
                        <Select
                          optionFilterProp="children"
                          showSearch
                          allowClear
                          options={[
                            7500, 9000, 10000, 12000, 13000, 18000, 22000,
                            24000, 30000, 32000, 36000, 48000, 58000, 60000,
                          ].map((btu) => {
                            return {
                              value: btu,
                              label: btu.toLocaleString(),
                            };
                          })}
                        />
                      </Form.Item>
                    )}
                    {equipmentType.capacity && (
                      <Form.Item
                        label="Capacidade"
                        name="capacity"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor selecione a capacidade do Equipamento!",
                          },
                        ]}
                      >
                        <Select
                          optionFilterProp="children"
                          showSearch
                          allowClear
                          options={[20, 25, 50, 100, 200, 350, 400].map(
                            (capacity) => {
                              return {
                                value: capacity,
                                label: `${capacity} Litros`,
                              };
                            }
                          )}
                        />
                      </Form.Item>
                    )}
                    {equipmentType.fabricate && (
                      <Form.Item
                        label="Fabricado"
                        name="fabricate"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor selecione a data de fabricação do Equipamento!",
                          },
                        ]}
                      >
                        <DatePicker
                          format={"DD/MM/YYYY"}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    )}
                    {equipmentType.gas && (
                      <Form.Item
                        name="gas"
                        label="Gás"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor selecione o tipo de gás do Equipamento!",
                          },
                        ]}
                      >
                        <Radio.Group>
                          <Radio value="R134">R134a</Radio>
                          <Radio value="R404">R404a</Radio>
                          <Radio value="R600">R600a</Radio>
                          <Radio value="R290">R290</Radio>
                          <Radio value="R141">R141b</Radio>
                          <Radio value="R410">R410a</Radio>
                          <Radio value="R22">R22</Radio>
                          <Radio value="R32">R32</Radio>
                          <Radio value="R407">R407c</Radio>
                          <Radio value="Agua">Água</Radio>
                        </Radio.Group>
                      </Form.Item>
                    )}
                    {equipmentType.voltage && (
                      <Form.Item
                        label="Voltagem"
                        name="voltage"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor selecione a voltagem do Equipamento!",
                          },
                        ]}
                      >
                        <Select
                          optionFilterProp="children"
                          showSearch
                          allowClear
                          options={["120v", "240v", "380v"].map((voltage) => {
                            return {
                              value: voltage.slice(0, 3),
                              label: voltage,
                            };
                          })}
                        />
                      </Form.Item>
                    )}
                    {equipmentType.ventilation && (
                      <Form.Item
                        label="Ventilação"
                        name="ventilation"
                        rules={[
                          {
                            required: true,
                            message:
                              "Por favor selecione a voltagem do Equipamento!",
                          },
                        ]}
                      >
                        <Select
                          optionFilterProp="children"
                          showSearch
                          allowClear
                          options={[
                            "Grelahs",
                            "Hi-Waal",
                            "Cassete",
                            "Piso Teto",
                          ].map((ventilation) => {
                            return {
                              value: ventilation,
                              label: ventilation,
                            };
                          })}
                        />
                      </Form.Item>
                    )}
                    {equipmentType.trs && (
                      <Form.Item
                        label="TRs"
                        name="trs"
                        rules={[
                          {
                            required: false,
                            message:
                              "Por favor, o TRs do equipamento deve ser informado",
                          },
                        ]}
                      >
                        <Select
                          optionFilterProp="children"
                          showSearch
                          allowClear
                          options={[5, 7.5, 10, 12.5, 15, 20].map((trs) => {
                            return {
                              value: trs,
                              label: trs,
                            };
                          })}
                        />
                      </Form.Item>
                    )}
                  </>
                );
              }
            }}
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { EquipmentModal };
