import { useEffect, useState } from "react";

import { App, Button, Form, Modal, Spin, Upload, UploadProps } from "antd";
import { InboxOutlined } from "@ant-design/icons";

import { TOKEN_API, api } from "../../../services/api";

import { useContract } from "../../../contexts/ContractContext";

import { IContractFile } from "../../../types";
import { normFile } from "../../../utils";

interface ContractFileModalProps {
  onClose: () => void;
  contractFile?: IContractFile;
  isModalOpen: boolean;
  onRefreshTable?: () => void;
}

const ContractFileModal = ({
  onClose,
  isModalOpen,
  contractFile,
  onRefreshTable,
}: ContractFileModalProps) => {
  const { message } = App.useApp();
  const { contract } = useContract();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<any>();

  useEffect(() => {
    const getData = async () => {};
    if (isModalOpen) {
      getData();
    }
  }, [contract?.clientId, contract?.id, form, isModalOpen, contractFile]);

  const handleCloseModal = () => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose();
  };

  const onSubmit = async () => {
    setLoading(true);

    if (!files) {
      return;
    }

    setLoading(true);
    handleSubmitForm({ files: files })
      .then((data) => {
        message.success(
          `Local ${
            data.config.method === "put" ? "atualizado" : "adicionado"
          } com sucesso!`
        );
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        }
      })
      .finally(() => {
        setLoading(false);
        if (onRefreshTable) {
          onRefreshTable();
        }
      });
  };

  const handleSubmitForm = async (values: any) => {
    if (contractFile) {
      return await api.put<IContractFile>(
        `/contracts/${contract?.id}/files/${contractFile.id}`,
        values
      );
    }
    return await api.post<IContractFile>(
      `/contracts/${contract?.id}/files`,
      values
    );
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    action: process.env.REACT_APP_API + `/upload`,
    data: {
      folder: "contract_files",
    },
    headers: {
      authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
    },
    onChange(info) {
      if (info.file.status === "done") {
        setFiles(info.fileList.map((file) => file.response));
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        if (info.file.response?.errors) {
          info.file.response.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error(`${info.file.name} Erro ao enviar o arquivo.`);
        }
      }
    },
    accept: "image/*,.pdf",
    listType: "picture",
    maxCount: 3,
  };

  return (
    <Modal
      title={`${contractFile ? "Editar" : "Adicionar"} Arquivo`}
      open={isModalOpen}
      centered
      onCancel={handleCloseModal}
      footer={[
        <Button key="back" onClick={handleCloseModal} danger disabled={loading}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="contract_file_form_modal">
          <Form.Item label="Arquivo">
            <Form.Item
              name="file"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Por favor, insira um arquivo.",
                },
              ]}
              noStyle
            >
              <Upload.Dragger name="file" {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click ou arraste o arquivo para essa area de upload
                </p>
                <p className="ant-upload-hint">
                  Suporte para upload único ou em massa.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ContractFileModal };
