import React, { useState, useEffect, useRef } from "react";
import { Outlet, useNavigate, useOutlet, useParams } from "react-router-dom";

import { App, Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import {
  PlusOutlined,
  BarChartOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { api } from "../../../services/api";
import { IPagination, IUnit } from "../../../types";
import { Content } from "../../../components/Content";
import { UnitModal } from "../components/UnitModal";
import { ActionColumn, ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";

export const getLocalUnits = async (
  localId?: string,
  page = 1,
  perPage = 10
) => {
  return api.get<IPagination<IUnit>>(`/units`, {
    params: {
      localId,
      page,
      perPage,
    },
  });
};

const Units: React.FC = () => {
  const { modal, message } = App.useApp();
  const unitsTableRef = useRef<any>(null);
  const { localId } = useParams();
  const navigate = useNavigate();
  const outlet = useOutlet();

  const [units, setUnits] = useState<IUnit[]>([]);
  const [loading, setLoading] = useState(false);
  const [meta, setMeta] = useState<any>({
    current_page: 1,
    per_page: 10,
  });
  const [unit, setUnit] = useState<IUnit>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      setLoading(true);
      await getLocalUnits(localId)
        .then(({ data }) => {
          setUnits(data?.data);
          setMeta(data?.meta);
        })
        .finally(() => {
          setLoading(false);
          unitsTableRef.current?.scrollIntoView({ behavior: "smooth" });
        });
    };
    getData();
  }, [localId, load]);

  const handleChangePage = async (page: number, pageSize: number) => {
    setLoading(true);
    await getLocalUnits(localId, page, pageSize)
      .then(({ data }) => {
        setUnits(data?.data);
        setMeta(data?.meta);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRefreshData = () => {
    setLoad(!load);
  };

  const handleShowModal = async (mode?: string, record?: IUnit) => {
    if (mode === "update" && record) {
      setUnit(record);
    }
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const onDeleteUnit = async (record: IUnit) => {
    return modal.confirm({
      title: "Deseja deletar a unidade?",
      content: <>A unidade será excluída permanentemente!</>,
      okText: "Deletar",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      cancelText: "Cancelar",
      cancelButtonProps: {
        type: "default",
        danger: true,
      },
      onOk: async () => {
        await api
          .delete(`/units/${record.id}`)
          .catch(() => message.error("Ops! Algo deu errado!"))
          .then(handleRefreshData);
      },
    });
  };

  const actions: ActionFunction<IUnit> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => {
          handleShowModal("update", record);
        },
      },
      {
        key: "2",
        label: "Setores",
        icon: <BarChartOutlined />,
        onClick: () => {
          navigate(`${record.id}/sectors`);
        },
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () => onDeleteUnit(record),
      },
    ];
  };

  const columns: ColumnsType<IUnit> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      ellipsis: true,
    },
    ActionColumn(actions),
  ];

  return outlet ? (
    <Outlet />
  ) : (
    <Content>
      <PageHeader
        style={{ boxShadow: "none", padding: 0 }}
        title="Unidades"
        backButton={false}
        actions={[
          <Button
            onClick={() => handleShowModal()}
            icon={<PlusOutlined />}
            type={"primary"}
          >
            Criar Unidade
          </Button>,
        ]}
      />
      <Table
        ref={unitsTableRef}
        pagination={{
          position: ["bottomCenter"],
          showSizeChanger: true,
          pageSizeOptions: [5, 10, 20, 30, 50],
          pageSize: meta.page_size,
          total: meta.total,
          onChange: handleChangePage,
        }}
        size="small"
        loading={loading}
        rowKey={(record: IUnit) => record.id}
        columns={columns}
        dataSource={units}
      />
      <UnitModal
        onRefreshPage={handleRefreshData}
        onClose={handleCloseModal}
        isModalOpen={isModalOpen}
        localId={localId}
        unit={unit}
      />
    </Content>
  );
};

export { Units };
