import { FC } from "react";

import { Card, Tooltip, Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Text, Title } = Typography;

interface OrderCardProps {
  color?: string;
  title: string;
  description?: string;
  total?: number;
}

const OrderCard: FC<OrderCardProps> = ({
  total,
  title,
  description,
  color,
}) => {
  return (
    <Card
      style={{
        width: "100%",
        borderBottom: `4px solid ${color}`,
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 8,
        }}
      >
        <Text>{title}</Text>
        <Tooltip title={description}>
          <InfoCircleOutlined style={{ fontSize: 20, cursor: "help" }} />
        </Tooltip>
      </div>
      <Title style={{ margin: 0 }}>{total || 0}</Title>
    </Card>
  );
};

export { OrderCard };
