/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Col, Form, Input } from "antd";
import { ExpandableFilter } from "../../../components/ExpandableFilter";

interface ServiceTypeListProps {
  onSubmit?: any;
}

interface ServiceTypeFormInstance {
  name?: string;
  units?: string[];
  isConsumible?: boolean;
}

const ServiceTypeList: FC<ServiceTypeListProps> = ({ onSubmit }) => {
  const [form] = Form.useForm<ServiceTypeFormInstance>();

  const fields = [
    <Col span={8} key={1}>
      <Form.Item name={"name"} label={"Nome"}>
        <Input allowClear placeholder="Insira o nome" />
      </Form.Item>
    </Col>,
  ];

  const onFinish = (values: ServiceTypeFormInstance) => {
    if (onSubmit) {
      onSubmit({ ...values, units: values.units?.toString() });
    }
  };

  const onClear = () => {
    onSubmit();
  };

  return (
    <ExpandableFilter
      form={form}
      fields={fields}
      onFinish={onFinish}
      onClear={onClear}
    />
  );
};

export { ServiceTypeList };
