import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { App, Button, Form, Input, Modal, Spin, Upload } from "antd";

import { TOKEN_API, api } from "../../../services/api";

import { IServicePhoto, ModalProps } from "../../../types";
import { useServiceOrder } from "../../../contexts/OrderContext";
import { InboxOutlined } from "@ant-design/icons";
import { normFile } from "../../../utils";

const PhotoModal: FC<ModalProps<IServicePhoto>> = ({
  onClose,
  isModalOpen,
  data: servicePhoto,
}) => {
  const { message } = App.useApp();
  const { serviceOrder } = useServiceOrder();
  const { serviceId, serviceItemId } = useParams();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {};
    if (isModalOpen) {
      setLoading(true);
      getData().finally(() => setLoading(false));
    }
  }, [form, serviceOrder, serviceId, isModalOpen, servicePhoto]);

  const handleCloseModal = (refresh = false) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(refresh);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => values)
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm({
      description: values.description,
      serviceId: serviceId!,
      serviceItemId: serviceItemId,
      photo: values.dragger[0].response.url,
      isUploaded: true,
    })
      .then((data) => {
        message.success(
          `Item ${
            data.config.method === "put" ? "atualizado" : "criado"
          } com sucesso!`
        );
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    if (servicePhoto) {
      return await api.put<IServicePhoto>(
        `/service-orders/${serviceOrder?.id}/services/${serviceId}/photos`,
        values
      );
    }
    return await api.post<IServicePhoto>(
      `/service-orders/${serviceOrder?.id}/services/${serviceId}/photos`,
      values
    );
  };

  return (
    <Modal
      title={`${servicePhoto ? "Editar" : "Adicionar"} Foto`}
      open={isModalOpen}
      centered
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Salvar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          layout="vertical"
          name="service_form_modal"
          initialValues={{
            quantity: 1,
          }}
        >
          <Form.Item label="Fotos">
            <Form.Item
              name="dragger"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger
                name="file"
                action={process.env.REACT_APP_API + `/upload`}
                headers={{
                  Authorization: `Bearer ${localStorage.getItem(TOKEN_API)}`,
                }}
                data={{
                  folder: "service_photos",
                }}
                accept={"image/*"}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click ou arraste o arquivo para essa area de upload
                </p>
                <p className="ant-upload-hint">
                  Suporte para upload único ou em massa.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <Form.Item
            label="Descrição"
            name="description"
            rules={[
              {
                required: true,
                message: "Por favor insira a descrição!",
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { PhotoModal };
