import { FC, useEffect, useState } from "react";
import "dayjs/locale/pt-br";

import { DatePicker, Form, App, Modal, Select, Spin, Input } from "antd";

import { api } from "../../../services/api";
import { IServiceOrder, IUser, ModalProps } from "../../../types";

import { getUsers } from "../../users/list";
import dayjs from "dayjs";

const { TextArea } = Input;

const ApproveBudgetModal: FC<ModalProps<IServiceOrder>> = ({
  isModalOpen,
  onClose,
  data: serviceOrder,
}) => {
  const { message } = App.useApp();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const [technical, setTechnical] = useState<IUser[]>([]);

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      await getUsers(1, 1000, { profile: "TECHNICIAN" }).then(({ data }) => {
        setTechnical(data.data);
      });
    };
    if (isModalOpen) {
      getData().finally(() => setLoading(false));
      form.setFieldsValue({
        description: serviceOrder?.description,
        instructions: serviceOrder?.instructions,
        startTo: dayjs(serviceOrder?.startTo || new Date()),
        endTo: dayjs(serviceOrder?.endTo || new Date()),
        technicianId: serviceOrder?.users.find(
          (user) => user.profile === "TECHNICIAN"
        )?.id,
      });
    }
  }, [form, isModalOpen, serviceOrder]);

  const handleCloseModal = () => {
    onClose();
    form.resetFields();
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          startTo: values.startTo?.format("YYYY-MM-DD"),
          endTo: values.endTo?.format("YYYY-MM-DD"),
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
      })
      .finally(() => setLoading(false));

    if (!values) {
      return;
    }

    setLoading(true);
    handleSubmitForm(values)
      .then(() => {
        message.success(`Ordem de Serviço criada com sucesso!`);
        handleCloseModal();
      })
      .catch((error) => {
        if (error.response.data.message) {
          message.error(error.response.data.message);
        } else if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    return await api.put(
      `/service-orders/${serviceOrder?.id}/approve-budget`,
      values
    );
  };

  return (
    <Form.Provider>
      <Modal
        title={`Aprovar Orçamento`}
        open={isModalOpen}
        onCancel={handleCloseModal}
        onOk={onSubmit}
      >
        <Spin spinning={loading}>
          <Form
            form={form}
            layout="vertical"
            name="service_order_form_modal"
            onFinish={onSubmit}
          >
            <Form.Item label="Descrição" name="description">
              <TextArea
                style={{ resize: "none" }}
                rows={5}
                showCount
                maxLength={255}
              />
            </Form.Item>
            <Form.Item label="Instruções" name="instructions">
              <TextArea
                style={{ resize: "none" }}
                rows={3}
                showCount
                maxLength={255}
              />
            </Form.Item>
            <Form.Item
              label="Técnico"
              name="technicianId"
              rules={[
                {
                  required: true,
                  message: "Por favor selecione um Técnico para o atendimento!",
                },
              ]}
            >
              <Select
                optionFilterProp="children"
                showSearch
                allowClear
                options={technical.map((technician) => {
                  return {
                    label: technician.name,
                    value: technician.id,
                  };
                })}
              />
            </Form.Item>
            <Form.Item
              label="Previsão de Atendimento"
              name="startTo"
              rules={[
                {
                  required: true,
                  message: "Por favor insira a previsão de atendimento!",
                },
              ]}
            >
              <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label="Previsão de Conclusão"
              name="endTo"
              rules={[
                {
                  required: true,
                  message: "Por favor insira a previsão de conlusão!",
                },
              ]}
            >
              <DatePicker
                picker="date"
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </Form.Provider>
  );
};

export { ApproveBudgetModal };
