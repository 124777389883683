export function ChecklistInputIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" rx="2" fill="#F0F0F0" />
      <path
        d="M8.53135 10.0627H17.934C18.2112 10.0627 18.4653 9.83168 18.4653 9.53135C18.4653 9.23102 18.2343 9 17.934 9H8.53135C8.25413 9 8 9.23102 8 9.53135C8 9.83168 8.25413 10.0627 8.53135 10.0627Z"
        fill="#434343"
      />
      <path
        d="M8.53135 13.969H19.7129C19.9901 13.969 20.2442 13.7379 20.2442 13.4376C20.2442 13.1373 20.0132 12.9062 19.7129 12.9062H8.53135C8.25413 12.9062 8 13.1373 8 13.4376C8 13.7379 8.25413 13.969 8.53135 13.969Z"
        fill="#434343"
      />
      <path
        d="M8.53135 17.8713H16.1782C16.4554 17.8713 16.7096 17.6403 16.7096 17.3399C16.7096 17.0396 16.4785 16.8086 16.1782 16.8086H8.53135C8.25413 16.8086 8 17.0396 8 17.3399C8 17.6403 8.25413 17.8713 8.53135 17.8713Z"
        fill="#434343"
      />
      <path
        d="M21.4686 20.7363H8.53135C8.25413 20.7363 8 20.9674 8 21.2677C8 21.568 8.23102 21.799 8.53135 21.799H21.4686C21.7459 21.799 22 21.568 22 21.2677C22 20.9674 21.7459 20.7363 21.4686 20.7363Z"
        fill="#434343"
      />
    </svg>
  );
}
