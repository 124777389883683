import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Tag } from "antd";
import { ColumnsType } from "antd/es/table";

import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";

import { getClients } from "../../../services/repositories";
import { IClient } from "../../../types";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { FilterClientList } from "../components/FilterClientList";
import { ClientModal } from "../components/CreateClientModal";
import { formatters } from "../../../utils/formatters";
import { usePagination } from "../../../hooks/usePaginations";
import { useModal } from "../../../hooks/useModal";
import { ActionTable } from "../../../components/ActionTable";

const Clients: FC = () => {
  const navigate = useNavigate();
  const {
    data: clients,
    isLoading,
    filter,
    setFilter,
    handleRefreshData,
    pagination,
  } = usePagination(getClients);

  const {
    data: client,
    isOpen,
    handleCloseModal,
    handleOpenModal,
  } = useModal<IClient>(handleRefreshData);

  const actions: ActionFunction<IClient> = (record) => {
    return [
      {
        key: "1",
        icon: <SearchOutlined />,
        label: "Detalhar",
        onClick: () => navigate(`${record.id}`),
      },
      {
        key: "2",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "3",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
      },
    ];
  };

  const columns: ColumnsType<IClient> = [
    {
      title: "ID",
      dataIndex: "sid",
      key: "sid",
      render: (value) => {
        return (
          <div style={{ color: "#3030DA" }}>
            {value?.toString().padStart(4, "0")}
          </div>
        );
      },
      width: 80,
    },
    {
      title: "Cliente",
      dataIndex: "name",
      key: "name",
      ellipsis: true,
    },
    {
      title: "CPF | CNPJ",
      dataIndex: "identifier",
      key: "identifier",
      render: (text: string) => formatters.identifier(text),
    },
    {
      title: "Tipo",
      dataIndex: "clientType",
      key: "clientType",
      render: (text) =>
        text === "LEGAL" ? (
          <Tag color={"volcano"}>JURÍDICA</Tag>
        ) : (
          <Tag color={"blue"}>FÍSICA</Tag>
        ),
    },
    {
      title: "Criado em:",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatters.simpleDate(text),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => text || "--",
    },
  ];

  return (
    <>
      <ClientModal
        isModalOpen={isOpen}
        onClose={handleCloseModal}
        data={client}
      />
      <PageHeader
        title="Clientes"
        backButton={false}
        actions={[
          <Button
            icon={<PlusOutlined />}
            key={"create_item"}
            onClick={() => handleOpenModal()}
            type="primary"
          >
            Cliente
          </Button>,
        ]}
        crumb
      />
      <Content
        width="calc(100% - 48px)"
        margin
        style={{ padding: "24px 40px 0" }}
      >
        <FilterClientList
          onSubmit={(values: any) => {
            setFilter(values);
          }}
          filter={filter}
        />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          loading={isLoading}
          columns={columns}
          dataSource={clients}
        />
      </Content>
    </>
  );
};

export { Clients };
