import { Tag } from "antd";
import { ReactNode } from "react";

interface StatusTagsProps {
  children: ReactNode;
  color: string;
  icon?: ReactNode;
  size?: "full" | undefined;
}

const StatusTag = ({ children, size, color, icon }: StatusTagsProps) => {
  return (
    <Tag
      color={color}
      icon={icon}
      style={{ width: size ? "100%" : "", textAlign: "center", margin: 0 }}
    >
      {children}
    </Tag>
  );
};

export { StatusTag };
