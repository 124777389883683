import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const ErrorPage: FC = () => {
  const navigate = useNavigate();
  const [counter, setCounter] = useState(5);

  useEffect(() => {
    if (counter <= 0) {
      navigate("/");
    }
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(Number(timer));
  }, [counter, navigate]);

  return (
    <div style={{ margin: "16px" }}>
      <h1>Route not found!</h1>
      <img
        src="https://media.tenor.com/usE42k08oFIAAAAd/anime-ops.gif"
        alt="Oops"
        title="OOPS!"
      />
      <p style={{ marginTop: 16, fontSize: 32 }}>
        Redirencionando em... {counter}!
      </p>
    </div>
  );
};

export { ErrorPage };
