import { createContext, useState, useContext, useCallback } from "react";
import { Outlet } from "react-router-dom";
import { IServiceOrder } from "../types";

type ServiceOrderContextData = {
  serviceOrder: IServiceOrder | undefined;
  handleSetServiceOrder: (data: IServiceOrder) => void;
};

const ServiceOrderContext = createContext({} as ServiceOrderContextData);

const ServiceOrderProvider = () => {
  const [serviceOrder, setServiceOrder] = useState<IServiceOrder>();

  const handleSetServiceOrder = useCallback((data: IServiceOrder) => {
    setServiceOrder(data);
  }, []);

  return (
    <ServiceOrderContext.Provider
      value={{ serviceOrder, handleSetServiceOrder }}
    >
      <Outlet />
    </ServiceOrderContext.Provider>
  );
};

const useServiceOrder = () => {
  return useContext(ServiceOrderContext);
};

export { ServiceOrderContext, ServiceOrderProvider, useServiceOrder };
