import { Divider, Form, Input } from "antd";

export const renderField = (type: String | null | undefined) => {
  let field: any;
  switch (type) {
    case "Title":
      field = (
        <Form.Item label="Título" name="name" required>
          <Input placeholder="Insira um Título" />
        </Form.Item>
      );
      break;

    case "Text":
      field = (
        <Form.Item label="Descrição" name="name" required>
          <Input.TextArea
            placeholder="Insira uma Descrição"
            maxLength={255}
            showCount
          />
        </Form.Item>
      );
      break;


    case "Select":
      field = (
        <Form.Item
          label="Menu de seleção"
          name="name"
          rules={[
            {
              required: true,
              message: "Insira o nome do campo.",
            },
          ]}
        >
          <Input placeholder="Insira o nome do campo" />
        </Form.Item>
      );
      break;

    case "Switch":
      field = (
        <Form.Item
          label="Switch"
          name="name"
          rules={[
            {
              required: true,
              message: "Insira o nome do campo.",
            },
          ]}
        >
          <Input placeholder="Insira o nome do campo" />
        </Form.Item>
      );
      break;

    case "Slider":
      field = (
        <Form.Item
          label="Ranger"
          name="name"
          rules={[
            {
              required: true,
              message: "Insira o nome do campo.",
            },
          ]}
        >
          <Input placeholder="Insira o nome do campo" />
        </Form.Item>
      );
      break;

    case "Checkbox":
      field = (
        <Form.Item
          label="Checkbox"
          name="name"
          rules={[
            {
              required: true,
              message: "Insira o nome do campo.",
            },
          ]}
        >
          <Input placeholder="Insira o nome do campo" />
        </Form.Item>
      );
      break;

    case "Divider":
      field = (
        <Form.Item label="Divisão" name="name">
          <Divider />
        </Form.Item>
      );
      break;


    default:
      field = (
        <Form.Item
          label="Nome do campo"
          name="name"
          rules={[
            {
              required: true,
              message: "Insira o nome do campo.",
            },
          ]}
        >
          <Input placeholder="Insira o nome do campo" />
        </Form.Item>
      );
      break;
  }

  return field;
};