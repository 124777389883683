import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

export function ServiceOrderIcon(props: Partial<CustomIconComponentProps>) {
  return (
    <Icon
      component={() => (
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 1536 1024"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1416.4 669.047C1416.4 298.773 1126.51 0 770.499 0C414.493 0 124.602 298.773 124.602 666.494V669.047C55.9438 674.155 0 730.334 0 801.835V891.212C0 965.267 61.0296 1024 132.231 1024H1403.68C1477.43 1024 1535.91 962.713 1535.91 891.212V801.835C1538.46 732.888 1485.05 674.155 1416.4 669.047ZM478.066 204.289V301.327C478.066 331.97 503.495 357.506 534.009 357.506C564.524 357.506 589.953 331.97 589.953 301.327V145.556C628.097 130.234 668.783 122.574 709.47 117.466V362.614C709.47 393.257 734.899 418.793 765.413 418.793C795.928 418.793 821.357 393.257 821.357 362.614V117.466C862.044 122.574 902.73 132.788 940.874 145.556V301.327C940.874 331.97 966.303 357.506 996.818 357.506C1027.33 357.506 1052.76 331.97 1052.76 301.327V204.289C1197.71 303.88 1291.79 472.419 1291.79 666.494C1291.79 666.494 1291.79 666.494 1291.79 669.047H239.033C239.033 669.047 239.033 669.047 239.033 666.494C239.033 474.973 335.663 303.88 478.066 204.289ZM1426.57 893.766C1426.57 903.98 1418.94 914.195 1406.22 914.195H134.774C124.602 914.195 114.431 906.534 114.431 893.766V804.389C114.431 794.175 122.059 783.96 134.774 783.96H1406.22C1416.4 783.96 1426.57 791.621 1426.57 804.389V893.766Z" />
        </svg>
      )}
      {...props}
    />
  );
}
