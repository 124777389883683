import { RouteObject } from "react-router-dom";
import { Dashboard } from "./list";

export const DashboardRouter: RouteObject = {
  path: "/dashboard",
  handle: { crumb: () => "Dashboard" },
  children: [
    {
      index: true,
      element: <Dashboard />,
    },
  ],
  errorElement: <h1>Route not found!</h1>,
};
