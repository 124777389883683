import { FC } from "react";

import { Button } from "antd";
import { ColumnsType } from "antd/es/table";

import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";

import { Content } from "../../../components/Content";
import { ActionFunction } from "../../../components/ActionColumn";
import { PageHeader } from "../../../components/PageHeader";
import { FilterItemList } from "../components/FilterItemList";
import { EquipmentModal } from "../components/EquipmentModal";

import { IEquipment } from "../../../types";
import { formatters } from "../../../utils";
import { ActionTable } from "../../../components/ActionTable";
import { useDelete, useModal, usePagination } from "../../../hooks";
import { getEquipments } from "../../../services/repositories";

const Equipments: FC = () => {
  const actions: ActionFunction<IEquipment> = (record) => {
    return [
      {
        key: "1",
        icon: <EditOutlined />,
        label: "Editar",
        onClick: () => handleOpenModal(record),
      },
      {
        key: "2",
        icon: <DeleteOutlined />,
        label: "Deletar",
        danger: true,
        onClick: () =>
          handleDeleteData(
            `/equipments/${record.id}`,
            "Deseja deletar a equipamento?"
          ),
      },
    ];
  };

  const {
    data: equipments,
    isLoading,
    setFilter,
    handleRefreshData,
    pagination,
  } = usePagination(getEquipments);

  const {
    data: equipment,
    isOpen,
    handleCloseModal,
    handleOpenModal,
  } = useModal<IEquipment>(handleRefreshData);

  const { handleDeleteData } = useDelete(handleRefreshData);

  const columns: ColumnsType<IEquipment> = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      width: 300,
    },
    {
      title: "Cliente",
      dataIndex: "client",
      key: "client",
      render: ({ name }) => name,
      ellipsis: true,
    },
    {
      title: "Local",
      dataIndex: "local",
      key: "local",
      render: (local) => local?.name,
    },
    {
      title: "Unidade",
      dataIndex: "unit",
      key: "unit",
      render: (unit) => unit?.name,
    },
    {
      title: "Setor",
      dataIndex: "sector",
      key: "sector",
      render: (sector) => sector?.name,
    },
    {
      title: "Tipo",
      dataIndex: "equipmentType",
      key: "equipmentType",
      render: (type) => type?.name,
    },
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
      width: 250,
    },
    {
      title: "Criado em",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => formatters.simpleDate(text),
    },
  ];

  return (
    <>
      <PageHeader
        title="Equipamentos"
        backButton={false}
        actions={[
          <Button
            key={"create_item"}
            onClick={() => handleOpenModal()}
            icon={<PlusOutlined />}
            type="primary"
          >
            Adicionar Equipamento
          </Button>,
        ]}
        crumb
      />
      <EquipmentModal
        onClose={handleCloseModal}
        isModalOpen={isOpen}
        data={equipment}
      />
      <Content width="calc(100% - 48px)" margin>
        <FilterItemList onSubmit={setFilter} />
      </Content>
      <Content width="calc(100% - 48px)" margin>
        <ActionTable
          actions={actions}
          pagination={pagination}
          loading={isLoading}
          columns={columns}
          dataSource={equipments}
        />
      </Content>
    </>
  );
};

export { Equipments };
