import { FC, useState } from "react";

import { App, Button, Form, Input, Modal, Spin } from "antd";
import { api } from "../../../services/api";
import { ModalProps } from "../../../types";
import { FormField } from "./SortableItem";
import { useParams } from "react-router-dom";

const ChecklistModal: FC<ModalProps<FormField[]>> = ({
  onClose,
  isModalOpen,
  data: checklist,
}) => {
  const { message } = App.useApp();
  const { id } = useParams();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleCloseModal = (refresh?: boolean) => {
    if (loading) {
      return;
    }
    form.resetFields();
    onClose(true);
  };

  const onSubmit = async () => {
    setLoading(true);
    const values = await form
      .validateFields()
      .then((values) => {
        return {
          ...values,
          name: values.name.toUpperCase(),
          form: {
            fields: checklist,
          },
        };
      })
      .catch((info) => {
        info.errorFields.forEach((error: any) =>
          error.errors.forEach((msg: string) => message.error(msg))
        );
        setLoading(false);
      });

    if (!values) {
      return;
    }

    handleSubmitForm(values)
      .then(() => {
        message.success(`Checklist adicionado com sucesso!`);
        handleCloseModal(true);
      })
      .catch((error) => {
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err: any) =>
            message.error(err.message)
          );
        } else {
          message.error("Algo inesperado ocorreu!");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmitForm = async (values: any) => {
    if (id) {
      return await api.put(`/checklists/${id}`, values);
    }
    return await api.post(`/checklists`, values);
  };

  return (
    <Modal
      title={`Adicionar Checklist`}
      open={isModalOpen}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button
          key="back"
          onClick={() => handleCloseModal()}
          danger
          disabled={loading}
        >
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onSubmit}
          loading={loading}
        >
          Adicionar
        </Button>,
      ]}
    >
      <Spin spinning={loading}>
        <Form form={form} layout="vertical" name="unit_form_modal">
          <Form.Item
            label="Nome"
            name="name"
            rules={[
              {
                required: true,
                message: "Por favor insira o nome da Marca!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

export { ChecklistModal };
